import styled from 'styled-components';
import { SessionCalendar } from '../ScheduleAdjustment/styles';
import { colors } from '../../../theme';

export const CalendarSticky = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  margin-top: 24px;
  background: ${colors.white};
`;

export const UserCalendar = styled(SessionCalendar)`
  color: ${colors.stormGrey};
  font-size: 13px;
  margin: 0;
  .react-calendar__tile {
    color: ${colors.stormGrey};
    position: relative;
  }
  .react-calendar__tile .icon {
    position: absolute;
    bottom: 9px;
    font-size: 6px;
    right: 20px;
    margin: 0;
    color: ${colors.bittersweet};
  }
`;
