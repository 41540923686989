import React from 'react';
import { Header, Tab } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import { AppLayout, SettingsLayout } from '../../../../components/layout';
import BasicInfo from './BasicInfo';
import {
  Avatar,
  CoachAvatar,
  CoachTitle,
  HomeStateText,
  PrimaryButton,
  StyledMyCoach,
} from '../../../../components/layout/SettingsLayout/styles';
import { useAppSelector } from '../../../../redux/store';
import { userSelector } from '../../../../redux/user/user.selectors';
import { coachSelector } from '../../../../redux/coaches/coaches.selectors';
import { SettingMenuItem } from '../../../../components/layout/SettingsLayout/SettingsMenu';
import { USER_TABS } from '../../../../constants/tabs';

const MyCoach: React.FC = () => {
  const history = useHistory();
  const user = useAppSelector(userSelector);
  const coach = useAppSelector(coachSelector);
  const { profile = {} as CoachProfile } = coach;
  const { name, photo, bio } = profile;

  const panes = [
    {
      menuItem: 'Basic Info',
      render: () => (
        <Tab.Pane>
          <BasicInfo profile={profile} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'About Me',
      render: () => <Tab.Pane>{bio}</Tab.Pane>,
    },
  ];

  const profileLocation = [profile.homeCity, profile.homeState]
    .filter(Boolean)
    .join(', ');

  const handleNavigationChange = (item: SettingMenuItem) => {
    history.push(item.name);
  };

  return (
    <AppLayout page="settings">
      <SettingsLayout
        items={USER_TABS}
        selectedItem={{ name: 'my-coach' }}
        onSelectItem={handleNavigationChange}>
        <StyledMyCoach>
          <CoachAvatar>
            <Avatar src={photo} circular size="small" />
            <CoachTitle>
              <h3>{name}</h3>
              <HomeStateText>{profileLocation}</HomeStateText>
            </CoachTitle>
          </CoachAvatar>
          <Tab panes={panes} />
          <Header as="h4">Need to change coaches?</Header>
          <PrimaryButton
            primary
            onClick={() => {
              window.open(
                `mailto:support@joinblush.com?subject=Request to change my coach - ${
                  user?.profile?.name || ''
                } | ${user.profile.email}`,
                '_blank'
              );
            }}>
            Contact us
          </PrimaryButton>
        </StyledMyCoach>
      </SettingsLayout>
    </AppLayout>
  );
};

export default MyCoach;
