import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { authSelector } from '../redux/auth/auth.selectors';
import { useAppSelector } from '../redux/store';

type AuthRouteProps = RouteProps & {
  component: React.FC;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentProps?: any;
};

const AuthRoute: React.FC<AuthRouteProps> = ({
  component: Component,
  componentProps,
  ...rest
}) => {
  const location = useLocation();
  const auth = useAppSelector(authSelector);

  if (auth?.status === 'pending')
    return (
      <Dimmer active inverted>
        <Loader active size="big" inline="centered">
          Loading
        </Loader>
      </Dimmer>
    );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          auth?.status === 'success' &&
          ['user', 'coach'].includes(auth.role!)
        ) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          );
        }

        return <Component {...{ ...props, ...componentProps }} />;
      }}
    />
  );
};

export default AuthRoute;
