export const COACH_TABS = [
  {
    name: 'profile',
    label: 'Profile',
  },
  {
    name: 'availability',
    label: 'Availability',
  },
  {
    name: 'notifications',
    label: 'Notifications',
  },
  {
    name: 'payment',
    label: 'Payment',
  },
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'security',
    label: 'Security',
  },
  {
    name: 'system-message',
    label: 'System Message',
  },
];

export const USER_TABS = [
  {
    name: 'profile',
    label: 'Profile',
  },
  {
    name: 'availability',
    label: 'Availability',
  },
  {
    name: 'billing',
    label: 'Billing',
  },
  {
    name: 'interests',
    label: 'Interests',
  },
  {
    name: 'my-coach',
    label: 'My Coach',
  },
  {
    name: 'notifications',
    label: 'Notifications',
  },
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'security',
    label: 'Security',
  },
  {
    name: 'subscription',
    label: 'Subscription',
  },
];
