import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export default styled(Modal)`
  && {
    width: 100%;
    max-width: 460px;
    padding: 20px 40px;

    && h3 {
      margin-bottom: 25px;
    }

    .button {
      border-radius: 0;
      margin-top: 20px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;
