export const GET_CHAT = 'GET_CHAT';

export const ADD_CHAT = 'ADD_CHAT';

export const UPLOAD_MEDIA = 'UPLOAD_MEDIA';

export const UPDATE_CHAT_READ_RECEIPT = 'UPDATE_CHAT_READ_RECEIPT';

export const GET_CLIENTS_CHAT = 'GET_CLIENTS_CHAT';

export const GET_USER_ARCHIVED_CHAT = 'GET_USER_ARCHIVED_CHAT';
