import { createSlice } from '@reduxjs/toolkit';
import { AvailabilityState } from '../store/types';
import { logout } from '../auth/auth.saga';
import { getUser } from '../user/user.saga';

const initialState = {
  status: 'pending',
  days: [],
  sessionLength: [],
} as AvailabilityState;

const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
      status: 'pending',
    }));
    builder.addCase(getUser.fulfilled, (state, action: any) => ({
      ...state,
      ...(action.payload.availability as UserAvailability),
      status: 'success',
    }));
  },
});

export default availabilitySlice.reducer;
