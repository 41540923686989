import React from 'react';
import { useHistory } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Pane, WizardLayout } from '../../../../components/layout';
import {
  WizContent,
  WizMessage,
  WizPane,
  WizSubtitle,
  WizTitle,
} from '../../../../components/layout/WizardLayout/styles';
import { PaymentForm } from '../../../../components/forms';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { userProfileSelector } from '../../../../redux/user/user.selectors';
import { stripeIdSelector } from '../../../../redux/subscription/subscription.selectors';
import { addCard } from '../../../../redux/subscription/subscription.saga';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

const Payment = () => {
  const history = useHistory();
  const userProfile = useAppSelector(userProfileSelector);
  const stripeId = useAppSelector(stripeIdSelector);
  const dispatch = useAppDispatch();
  const { name } = userProfile;

  return (
    <WizardLayout sideBanner="onboarding_img_6">
      <WizContent textAlign="left">
        <WizTitle>
          Almost there, <span>{name}</span>!
        </WizTitle>
        <WizSubtitle>Enter payment information</WizSubtitle>
        <WizPane>
          <Pane height="20px" />
          <WizMessage
            header="Your card will not be charged until you confirm your subscription on
            the next step."
            icon="warning circle"
            size="small"
            content="If you have a promo code, you can enter it on the next screen."
          />
          <Elements stripe={stripePromise}>
            <PaymentForm
              defaultValues={{ cardholderName: name || '' }}
              stripeId={stripeId}
              onSubmit={({ successPath, ...data }) =>
                dispatch(addCard.action({ ...data, meta: { successPath } }))
              }
              successPath="/onboarding/summary"
              onBack={() => history.goBack()}
            />
          </Elements>
        </WizPane>
      </WizContent>
    </WizardLayout>
  );
};

export default Payment;
