import React, { FC } from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

import { ButtonProps } from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import { colors } from '../../../theme';

interface Props extends ButtonProps {
  borderRadius?: string;
  backgroundColor?: string;
}

const StyledButton: FC<Props> = styled(
  ({ borderRadius, backgroundColor, ...rest }) => <Button {...rest} />
)`
  &&& {
    height: 52px;
    background-color: ${(props) => props.backgroundColor};
    color: ${colors.purpleBrown};
    font-size: 16px;
    border-radius: ${(props) =>
      props.borderRadius !== undefined ? props.borderRadius : '10px'};
    padding-top: 18px;
    padding-bottom: 18px;
  }
`;

export default StyledButton;
