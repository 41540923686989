import React, { FC } from 'react';
import { Header, Image } from 'semantic-ui-react';

import { List, ListItem } from './styles';
import {
  DASHBOARD_CONTENT,
  PLACEHOLDER_IMAGE,
} from '../../../config/constants';
import ErrorBoundaryContainer from '../ErrorBoundaryContainer';

interface Announcement {
  description: string;
  date: string;
  image: string;
}

interface Props {
  announcements: Announcement[];
}

const Announcements: FC<Props> = ({ announcements }) => (
  <ErrorBoundaryContainer>
    <List style={{ marginBottom: '24px' }}>
      <Header>Company-Wide Announcements</Header>
      {announcements?.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ListItem key={`ann-${index}`}>
          <Image src={item.image || PLACEHOLDER_IMAGE} circular size="tiny" />
          <span className="description">{item.description}</span>
          <span className="date">{item.date}</span>
        </ListItem>
      )) ??
        DASHBOARD_CONTENT.ANNOUNCEMENTS.map((item) => (
          <ListItem key={item.id}>
            <Image src={item.src || PLACEHOLDER_IMAGE} circular size="tiny" />
            <span className="description">{item.desc}</span>
            <span className="date">{item.date}</span>
          </ListItem>
        ))}
    </List>
  </ErrorBoundaryContainer>
);

export default Announcements;
