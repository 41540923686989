import firebase from 'firebase';

const converter = {
  toFirestore: (data: Coach) => data,
  fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) =>
    snap.data() as Coach,
};

const coachesCollection = firebase
  .firestore()
  .collection('coaches')
  .withConverter(converter);

/**
 * Get any coach by uid
 */
export const getCoach = (uid: string) => coachesCollection.doc(uid).get();

/**
 * Get all coaches from public profile
 */
export const getCoaches = async () => {
  const coaches = await coachesCollection.get();
  return coaches.docs.map((coach) => coach.data());
};

/**
 * Coach observer
 */
export const coachObserver = (
  emitter: (payload: Record<string, unknown> | Coach | Error) => void
) => {
  const { currentUser } = firebase.auth();

  return coachesCollection
    .doc(currentUser!.uid)

    .onSnapshot(
      (snapshot) => {
        if (!snapshot.exists) return emitter({});

        return emitter({
          ...currentUser!.toJSON(),
          ...snapshot.data(),
        });
      },
      (err) => {
        console.log(err);
        return emitter(new Error(err.message));
      }
    );
};
