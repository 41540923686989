import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';

import { StyledMessageIndicator } from '../ChatFeed/styles';
import { Message } from '../../../types/message';

interface Props {
  showReadReceipt: boolean;
  isHost: boolean;
  chat: Message;
}

const MessageIndicator: FC<Props> = ({
  showReadReceipt = false,
  isHost,
  chat,
}) => {
  const { pending, sent, received } = chat;
  if (!isHost) {
    return null;
  }
  return (
    <StyledMessageIndicator>
      {pending && <Icon name="clock" />}
      {sent && showReadReceipt && <Icon name="check" />}
      {received && showReadReceipt && <Icon name="check" />}
    </StyledMessageIndicator>
  );
};

export default MessageIndicator;
