import React, { FC } from 'react';

import { TipSticky } from './styles';

interface Props {
  tip: string;
}

const TipOfTheWeek: FC<Props> = ({ tip }) => (
  <TipSticky>
    <span className="caption">Tip of The Week</span>
    <span className="tip">{tip}</span>
  </TipSticky>
);

export default TipOfTheWeek;
