import firebase from '../../config/firebase';
import 'firebase/functions';
import { getDocRef } from './user';

export interface SignUpData {
  email: string;
  password: string;
  name: string;
  role: Role;
}

export interface LoginData {
  email: string;
  password: string;
}

/**
 * State change observer
 */
export const onAuthStateChanged = (successFn: any, failureFn: any) =>
  firebase
    .auth()
    .onAuthStateChanged((user) => (user ? successFn(user) : failureFn()));

/**
 * Verify role
 */
export const verifyRole = async () => {
  const { currentUser } = firebase.auth();

  if (currentUser) {
    const auth = await currentUser
      .getIdTokenResult(true)
      .then((idTokenResult) => {
        const { token, claims, signInProvider } = idTokenResult;

        return {
          token,
          signInProvider,
          role: claims.role,
          email: claims.email,
          uid: claims.user_id,
        };
      })
      .catch((err) => {
        console.log('verifyRole error', err);
        throw err;
      });

    return auth;
  }

  return Promise.reject(new Error('You are not signed in.'));
};

/**
 * Sign in to firebase with email and password credential
 */
export const loginWithEmailAndPassword = ({ email, password }: LoginData) =>
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((res) => JSON.stringify(res))
    .then((res) => JSON.parse(res))
    .then(verifyRole)
    .then(({ token, role, uid }) => ({
      token,
      role,
      uid,
    }))
    .catch((err) => {
      console.log('ERROR - sign in with email and password: ', err);
      switch (err.code) {
        case 'auth/invalid-email':
        case 'auth/user-disabled':
        case 'auth/user-not-found':
        case 'auth/wrong-password':
        default:
          throw err;
      }
    });

// /**
//  * Sign up with firebase with email and password credential
//  */
export const signUpWithEmailAndPassword = async (data: SignUpData) => {
  try {
    const { email, password, name, role = 'user' } = data;

    if (!name || !email || !password) {
      throw new Error('Missing required fields');
    }

    console.log('data services', data);

    await firebase.auth().createUserWithEmailAndPassword(email, password);

    const response = await firebase.functions().httpsCallable('createNewUser')({
      email,
      name,
      role,
    });

    return response;
  } catch (err: any) {
    console.log('sign up services error', err);
    throw err;
  }
};

/**
 * Forgot password
 */
export const forgotPassword = (data: { email: string }) =>
  firebase.auth().sendPasswordResetEmail(data.email);

/**
 * Add role
 */
export const addRole = async (data: { role: string }) => {
  console.log('calling addRole with this data', data);

  try {
    if (!data || !data.role) throw new Error('No role');

    const response = await firebase.functions().httpsCallable('addRole')(data);
    return response;
  } catch (err: any) {
    console.log('addRole err', err);
    throw err;
  }
};

/**
 * Reauthenticate user
 */
export const reauthenticateUser = async (password: string) => {
  const user = firebase.auth().currentUser;
  const credential = firebase.auth.EmailAuthProvider.credential(
    user!.email!,
    password
  );
  return user!.reauthenticateWithCredential(credential);
};

/**
 * Update User Email
 */
export const updateEmail = async ({
  newEmail,
  password,
}: {
  newEmail: string;
  password: string;
}) => {
  if (!newEmail) throw new Error('Missing email');

  await reauthenticateUser(password);

  const { currentUser } = firebase.auth();

  const response = await currentUser?.updateEmail(newEmail);

  const { role } = await verifyRole();
  const docRef = getDocRef(role, currentUser!.uid!);

  if (docRef) {
    await docRef.update({ 'profile.email': newEmail });
  }

  return response;
};

/**
 * Update User Password
 */
export const updatePassword = async (data: {
  password: string;
  newPassword: string;
}) => {
  await reauthenticateUser(data.password);
  const user = firebase.auth().currentUser;
  const response = await user!.updatePassword(data.newPassword);
  return response;
};

/**
 * Sign out
 */
export const logout = () => firebase.auth().signOut();
