import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Messages } from '../../types/message';
import { logout } from '../auth/auth.saga';
import { ChatState } from '../store/types';
import {
  addChat,
  getChat,
  getUserArchivedChat,
  updateChatReadReceipts,
  uploadMedia,
} from './chat.saga';
import { getClientsChat } from './coachChat.saga';

const sortMessages = (
  nextMessages: Messages = [],
  prevMessages: Messages = []
) =>
  _.uniqBy([...nextMessages, ...prevMessages], '_id').sort(
    (a, b) => Number(a.dateUTC) - Number(b.dateUTC)
  );

const initialState = {
  status: 'pending',
  messages: [],
  lastReceived: null,
  rooms: {},
  archivedRooms: {},
} as ChatState;

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
    }));
    builder.addCase(getChat.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getChat.fulfilled, (state, action: any) => {
      const messages = sortMessages(action.payload, state.messages);

      return {
        ...state,
        status: 'success',
        messages,
        lastReceived: messages.length
          ? messages[messages.length - 1].dateUTC || null
          : null,
      };
    });
    builder.addCase(uploadMedia.pending, (state) => {
      state.status = 'uploading';
    });
    builder.addCase(addChat.fulfilled, (state) => {
      state.status = 'success';
    });
    builder.addCase(getClientsChat.fulfilled, (state, action: any) => {
      const { messages: newMessages, recipientID } = action.payload;
      const prevMessages = state.rooms[recipientID]
        ? state.rooms[recipientID].messages
        : [];
      const messages = sortMessages(newMessages, prevMessages);

      return {
        ...state,
        status: 'success',
        rooms: {
          ...state.rooms,
          [recipientID]: {
            messages,
            lastReceived: messages.length
              ? messages[messages.length - 1].dateUTC
              : null,
          },
        },
      };
    });
    builder.addCase(getUserArchivedChat.fulfilled, (state, action) => {
      const { messages, recipientID } = action.payload;
      return {
        ...state,
        status: 'success',
        archivedRooms: {
          ...state.archivedRooms,
          [recipientID]: {
            messages,
          },
        },
      };
    });
    builder.addMatcher(
      isAnyOf(
        getChat.pending,
        getClientsChat.pending,
        getUserArchivedChat.pending
      ),
      (state) => {
        state.status = 'loading';
      }
    );
    builder.addMatcher(
      isAnyOf(
        getChat.rejected,
        addChat.rejected,
        uploadMedia.rejected,
        updateChatReadReceipts.rejected,
        getUserArchivedChat.rejected,
        getClientsChat.rejected
      ),
      (state) => {
        state.status = 'failed';
      }
    );
  },
});

export default chatSlice.reducer;
