import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { logout } from '../auth/auth.saga';
import { UserState } from '../store/types';
import { getUser, updateEmail, updatePassword, updateUser } from './user.saga';

const initialState = {
  status: 'pending',
  profile: {
    isProfileComplete: false,
  },
} as UserState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
    }));
    builder.addCase(getUser.fulfilled, (state, action: any) => {
      const {
        profile,
        availability,
        interests,
        subscription,
        coach,
        clients,
        connect,
        takingNewClients,
        welcomeMessage,
      } = action.payload;
      return {
        ...state,
        profile: {
          ...state.profile,
          ...profile,
        },
        ...(availability && { availability }),
        ...(interests && { interests }),
        ...(subscription && { subscription }),
        ...(coach && { coach }),
        ...(clients && { clients }),
        ...(connect && { connect }),
        ...(profile?.role === 'coach' && {
          takingNewClients: !!takingNewClients,
        }),
        ...(welcomeMessage && { welcomeMessage }),
        status: 'success',
      };
    });
    builder.addMatcher(
      isAnyOf(
        // getUser.pending,
        updateUser.pending,
        updateEmail.pending,
        updatePassword.pending
      ),
      (state) => {
        state.status = 'loading';
      }
    );
    builder.addMatcher(
      isAnyOf(updateEmail.fulfilled, updatePassword.fulfilled),
      (state) => {
        state.status = 'success';
      }
    );
    builder.addMatcher(
      isAnyOf(
        getUser.rejected,
        updateUser.rejected,
        updateEmail.rejected,
        updatePassword.rejected
      ),
      (state) => {
        state.status = 'failed';
      }
    );
  },
});

export default userSlice.reducer;
