import { createSlice } from '@reduxjs/toolkit';
import { logout } from '../auth/auth.saga';
import { ClientsState } from '../store/types';
import { getClients } from './clients.saga';

const initialState = {
  status: 'pending',
  clients: [],
} as ClientsState;

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
    }));
    builder.addCase(getClients.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getClients.fulfilled, (state, action: any) => {
      state.status = 'success';
      state.clients = action.payload;
    });
    builder.addCase(getClients.rejected, (state) => {
      state.status = 'failed';
    });
  },
});

export default clientsSlice.reducer;
