import React, { FC } from 'react';
import moment from 'moment';
import { Button, Card, Icon } from 'semantic-ui-react';

import { ButtonProps } from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import { DayView, ExtendedCard, PlayButton, SessionInfo } from './styles';
import ErrorBoundaryContainer from '../ErrorBoundaryContainer';

interface Props {
  session: Session;
  profile: UserProfile | { name: string };
  onOpenModal: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLDivElement>,
    data?: ButtonProps
  ) => void;
  onJoinSession: () => void;
}

const SessionCard: FC<Props> = ({
  session,
  profile,
  onOpenModal,
  onJoinSession,
}) => {
  const timeUtc = moment(parseInt(session.block.dateUTC, 10));
  const isToday = timeUtc.isSame(moment(), 'day');
  const dayTitle = isToday
    ? `${timeUtc.diff(moment(), 'hour')}h`
    : timeUtc.date();
  const daySubText = isToday ? 'Today' : timeUtc.format('MMMM');

  return (
    <ErrorBoundaryContainer>
      <ExtendedCard>
        <Card.Content className="session">
          {session.status === 'STARTED' ? (
            <PlayButton onClick={onJoinSession}>
              <Icon name="play" />
              Start
            </PlayButton>
          ) : (
            <DayView
              onClick={
                session.openTok
                  ? (e) => {
                      onOpenModal(e);
                    }
                  : undefined
              }>
              <span className="title">{dayTitle}</span>
              <span className="subtext">{daySubText}</span>
            </DayView>
          )}
          <SessionInfo>
            <span className="username">{profile.name}</span>
            <div className="session-time">
              {moment(session.block.start.toDate()).format('h:mm a')}
              <span>{`${session.duration}min`}</span>
            </div>
          </SessionInfo>
        </Card.Content>
        <Card.Content extra>
          {session.openTok ? (
            <Button onClick={onOpenModal}>
              View details
              <Icon name="angle right" />
            </Button>
          ) : (
            <Button disabled>Loading</Button>
          )}
        </Card.Content>
      </ExtendedCard>
    </ErrorBoundaryContainer>
  );
};

export default SessionCard;
