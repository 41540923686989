import firebase from 'firebase';

const converter = {
  toFirestore: (data: Feedback) => data,
  fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) =>
    snap.data() as Feedback,
};

const sessionFeedbackCollection = firebase
  .firestore()
  .collection('feedback')
  .withConverter(converter);

export const getSessionFeedback = async ({
  sessionId,
}: {
  sessionId: string;
}) => {
  const docSnapshot = await sessionFeedbackCollection.doc(sessionId).get();
  return docSnapshot.data();
};

export const createSessionFeedback = ({
  sessionId,
  ...data
}: Feedback & { sessionId: string }) =>
  sessionFeedbackCollection.doc(sessionId).set({
    ...data,
    created_at: firebase.firestore.Timestamp.now(),
    updated_at: firebase.firestore.Timestamp.now(),
  });
