import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import useWindowSize from '../../../hooks/useWindowSize';
import { Pane } from '../../layout/Pane';
import Button from '../Button';

interface Props {
  message: string;
  onClick?: () => void;
}

const Index: FC<Props> = ({ message, onClick, ...rest }) => {
  const history = useHistory();
  const { isMobile } = useWindowSize();

  return (
    <Pane
      marginBottom="5vh"
      alignItems="center"
      flexDirection={isMobile ? 'column' : 'row'}
      background="redTint"
      padding={16}
      display="flex"
      {...rest}>
      <Pane flex={1}>
        <Header as="h4">{message}</Header>
      </Pane>
      {isMobile && <Pane height="0.8rem" />}
      <Button
        primary
        borderRadius="0px"
        onClick={() => history.push('/user/settings/billing')}>
        Update Billing
      </Button>
      <Button
        primary
        borderRadius="0px"
        onClick={() => history.push('/user/settings/subscription')}>
        Subscribe
      </Button>
    </Pane>
  );
};

export default Index;
