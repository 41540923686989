import moment from 'moment';
import plans from '../../constants/plans';

interface Card {
  last4: string;
  id: string;
  exp_month: number;
  exp_year: number;
}

/* eslint-disable camelcase */
export const getPlanDetails = (productId: string) => {
  const matchedPlan = plans.find(
    ({ iapProductID }) => iapProductID === productId
  );
  return matchedPlan || null;
};

export const findCurrentActiveSubscription = (
  subs: { status: string; created: number; cancel_at_period_end: boolean }[]
) => {
  const activeSubscriptions = subs.filter(({ status }) => status === 'active');

  if (!activeSubscriptions.length) return null;

  if (activeSubscriptions.length > 1) {
    const currentActiveSub = activeSubscriptions
      .filter(({ cancel_at_period_end }) => cancel_at_period_end === true)
      .sort((a, b) => a.created - b.created);

    return currentActiveSub[0];
  }
  return activeSubscriptions[0];
};

export const findCard = (card: Card, sources: Card[]) => {
  if (card && sources) return sources.filter(({ id }) => id === card.id)[0];
  return null;
};

export const findCardNo = (card: { last4: string }) => card.last4;

export const findExpiryDate = ({ exp_month, exp_year }: Card) =>
  `${exp_month} / ${exp_year}`;

export const parseCard = (defCard: Card, sources: Card[]) => {
  const card = findCard(defCard, sources);

  return {
    last4: (card && findCardNo(card)) || null,
    expiryDate: (card && findExpiryDate(card)) || null,
  };
};

export const findActiveSub = (sub: Subscription) => {
  if (sub?.ios?.isSubscribed && sub?.ios?.productId) {
    return {
      plan: getPlanDetails(sub?.ios?.productId),
      current_period_end: moment(
        (sub?.ios?.expires_at?.seconds || 0) * 1000
      ).format('X'),
      ...sub.ios,
    };
  }

  if (sub?.stripe?.subscriptions?.activeSubscription) {
    return sub.stripe.subscriptions.activeSubscription;
  }

  return null;
};
