import React, { FC } from 'react';
import { Image } from 'semantic-ui-react';

import { CoachAvatar, CoachTitle, MyCoachSticky } from './styles';
import ErrorBoundaryContainer from '../ErrorBoundaryContainer';

interface Props {
  coach: CoachPublic;
}

const MyCoach: FC<Props> = ({ coach }) => {
  const { profile = {} as any } = coach;
  const { name, photo, bio } = profile;

  return (
    <ErrorBoundaryContainer>
      <MyCoachSticky>
        <span className="caption">My Coach</span>
        <CoachAvatar>
          <Image src={photo} circular size="small" />
          <CoachTitle>
            <h3>{name}</h3>
            <span>{bio}</span>
          </CoachTitle>
        </CoachAvatar>
      </MyCoachSticky>
    </ErrorBoundaryContainer>
  );
};

export default MyCoach;
