export const GET_SESSIONS = 'GET_SESSIONS';

export const GET_SESSION = 'GET_SESSION';

export const GET_COACH_AVAILABILITY_BLOCKS = 'GET_COACH_AVAILABILITY_BLOCKS';

export const CREATE_SESSION = 'CREATE_SESSION';

export const CANCEL_SESSION = 'CANCEL_SESSION';

export const UPDATE_SESSION_STATUS = 'UPDATE_SESSION_STATUS';
