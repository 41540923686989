import { createGlobalStyle } from 'styled-components';

import colors from './colors';

const globalStyle = createGlobalStyle`
  .ui.primary.button {
    background-color: ${colors.bittersweet};
    color: ${colors.white};
  }

  body {
    font-family: 'Source Sans Pro', sans-serif;
  }

  .ui.vertical.menu .item > i.icon.left {
    float: none;
    margin: 0em 0.35714286em 0em 0em;
  }

  
  .OTPublisherContainer {
    width: 100% !important;
    z-index: 99999;
    height: 100% !important;
  }

  .OTSubscriberContainer {
    width: 100% !important;
    height: 100% !important;
  }

  .collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

export default globalStyle;
