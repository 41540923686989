import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';

import { CalendarSticky, UserCalendar } from './styles';
import ErrorBoundaryContainer from '../ErrorBoundaryContainer';

interface Props {
  calendarSessions: any[];
}

const Calendar: FC<Props> = ({ calendarSessions }) => (
  <ErrorBoundaryContainer>
    <CalendarSticky>
      <UserCalendar
        calendarType="US"
        showNeighboringMonth={false}
        tileContent={({ date }) =>
          calendarSessions.some((d) => moment(d).isSame(date, 'day')) ? (
            <Icon className="circle" />
          ) : null
        }
      />
    </CalendarSticky>
  </ErrorBoundaryContainer>
);
export default Calendar;
