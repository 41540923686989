import { createAsyncSaga } from 'create-async-saga';
import { call, SagaReturnType, takeLatest } from 'redux-saga/effects';
import * as Firebase from '../../services/firebase';
import Swal from '../../utils/Swal';
import * as types from './sessionFeedback.actionTypes';

export const createSessionFeedback = createAsyncSaga<
  SagaReturnType<typeof Firebase.createSessionFeedback>,
  Feedback & { sessionId: string }
>(types.CREATE_SESSION_FEEDBACK, function* (data) {
  try {
    const response = yield call(Firebase.createSessionFeedback, data);
    yield Swal.fire({
      icon: 'success',
      title: 'Your feedback has been submitted.',
    });
    return response;
  } catch (err: any) {
    yield Swal.fire({
      icon: 'error',
      title: err.message,
    });
    throw err;
  }
});

export default function* sessionFeedbackSaga() {
  yield takeLatest(
    createSessionFeedback.actionType,
    createSessionFeedback.asyncSaga
  );
}
