import styled from 'styled-components';
import { colors } from '../../../theme';

export const MyCoachSticky = styled.div`
  background: ${colors.beautyBush};
  margin-top: 24px;
  .caption {
    display: block;
    text-align: center;
    background: ${colors.geraldine};
    color: ${colors.white};
    font-size: 15px;
    padding: 4px 20px;
    width: max-content;
  }
`;

export const CoachAvatar = styled.div`
  && {
    display: flex;
    align-items: center;
    padding: 32px;
    .image {
      width: 92px;
      height: 92px;
      object-fit: cover;
    }
  }
`;

export const CoachTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 28px;
  flex: 1 0 64%;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
`;
