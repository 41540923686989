import React, { useCallback } from 'react';
import Collapse from '@kunukn/react-collapse';
import { useHistory } from 'react-router-dom';
import { Card, Icon } from 'semantic-ui-react';
import { Pane, WizardLayout } from '../../../../components/layout';
import {
  Button,
  WizBtnGroup,
  WizCard,
  WizContent,
  WizSubtitle,
  WizTitle,
} from '../../../../components/layout/WizardLayout/styles';
import { PLANS } from '../../../../config/constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { selectedSubscriptionSelector } from '../../../../redux/subscription/subscription.selectors';
import { selectSubscription } from '../../../../redux/subscription/subscription.slice';
import { userSelector } from '../../../../redux/user/user.selectors';
import { logout } from '../../../../redux/auth/auth.saga';

const SessionPlan = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const selectedSubscription = useAppSelector(selectedSubscriptionSelector);

  const pushNextStep = () =>
    selectedSubscription === 'Unlimited Chat'
      ? history.push('/onboarding/option-choose-coach')
      : history.push('/onboarding/session-plan-level');

  const planDescription = useCallback(
    (plan) => {
      if (selectedSubscription === plan.type) {
        return <Card.Description>{plan.description}</Card.Description>;
      }
      return <></>;
    },
    [selectedSubscription]
  );

  return (
    <WizardLayout sideBanner="onboarding_img_2">
      <WizContent textAlign="left">
        <WizTitle>
          Welcome to Blush, <span>{user?.profile?.name}!</span>
        </WizTitle>
        <WizSubtitle>
          How would you like to communicate with your coach?
        </WizSubtitle>
        <Pane height="40px" />
        <Pane maxWidth="30rem">
          <Card.Group stackable itemsPerRow={1}>
            {PLANS.map((plan) => (
              <WizCard
                key={plan.type}
                selected={selectedSubscription === plan.type}
                onClick={() => dispatch(selectSubscription(plan.type))}>
                {selectedSubscription === plan.type && (
                  <Icon name="check circle" size="big" color="green" />
                )}
                <Card.Content textAlign="left">
                  <Card.Header>{plan.type}</Card.Header>
                  <Collapse isOpen={selectedSubscription === plan.type}>
                    {planDescription(plan)}
                  </Collapse>
                </Card.Content>
              </WizCard>
            ))}
          </Card.Group>
        </Pane>
      </WizContent>
      <WizBtnGroup>
        <Button
          icon
          labelPosition="right"
          size="big"
          color="black"
          disabled={!selectedSubscription}
          style={{ marginRight: 12, minWidth: '8rem' }}
          onClick={pushNextStep}>
          {/* onClick={() => history.push('/onboarding/session-plan-level')}> */}
          Continue
          <Icon name="chevron right" />
        </Button>
        <Button size="big" onClick={() => dispatch(logout.action())}>
          Sign out
        </Button>
      </WizBtnGroup>
    </WizardLayout>
  );
};

export default SessionPlan;
