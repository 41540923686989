import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Icon, Item, Label, Loader } from 'semantic-ui-react';
import { Pane, WizardLayout } from '../../../../components/layout';
import {
  Button,
  WizBtnGroup,
  WizContent,
  WizItem,
  WizTitle,
} from '../../../../components/layout/WizardLayout/styles';
import useWindowSize from '../../../../hooks/useWindowSize';
import { matchCoach } from '../../../../redux/coaches/coaches.saga';
import { coachSelector } from '../../../../redux/coaches/coaches.selectors';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

const ChooseCoach: React.FC = () => {
  const history = useHistory();
  const coach = useAppSelector(coachSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!coach.uid) {
      // call match coach here
      console.log('matching coach...');
      dispatch(matchCoach.action());
    }
  }, [coach, dispatch]);

  const { isMobile } = useWindowSize();

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const meetYourCoach = () => (
    <WizardLayout imageBanner={coach.profile.photo}>
      <WizContent textAlign="left" paddingBottom="16rem">
        <WizTitle>Meet your coach!</WizTitle>
        <Grid stackable columns={2}>
          <Grid.Column width={16}>
            <Item.Group>
              <WizItem>
                {isMobile && (
                  <Item.Image size="tiny" src={coach?.profile?.photo} />
                )}
                <Item.Content style={{ textAlign: 'left' }}>
                  <Item.Header as="a" className="h1">
                    {coach.profile.name}
                  </Item.Header>
                  <Item.Description>{coach?.profile?.miniBio}</Item.Description>
                  <Pane height="18px" />
                  <Item.Header as="a">SPECIALTIES -</Item.Header>
                  <Item.Extra>
                    {coach?.interests?.map((item) => (
                      <Label key={item}>{item}</Label>
                    ))}
                  </Item.Extra>
                </Item.Content>
              </WizItem>
            </Item.Group>
          </Grid.Column>
        </Grid>
      </WizContent>
      <WizBtnGroup>
        <Button
          basic
          color="black"
          size="big"
          style={{
            marginRight: 12,
          }}
          onClick={goBack}>
          Back
        </Button>
        <Button
          icon
          labelPosition="right"
          size="big"
          color="black"
          onClick={() => history.push('/onboarding/payment')}>
          Continue
          <Icon name="chevron right" />
        </Button>
        <Button
          className="geraldine"
          size="big"
          content="View alternate coaches"
          style={{
            marginLeft: 'auto',
          }}
          onClick={() => {
            history.push('/onboarding/choose-different-coach');
          }}
        />
      </WizBtnGroup>
    </WizardLayout>
  );

  return coach.profile ? (
    meetYourCoach()
  ) : (
    <WizardLayout>
      <WizContent textAlign="center">
        <Pane height="48px" />
        <WizTitle>
          Matching you to one <br /> of our amazing coaches!
        </WizTitle>
        <Pane height="56px" />
        <Loader active size="big" inline="centered" />
      </WizContent>
    </WizardLayout>
  );
};

export default ChooseCoach;
