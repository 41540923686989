import React, { FC } from 'react';
import { Button, Card, Icon, Image } from 'semantic-ui-react';
import moment from 'moment';

import { StyledClientCard } from './styles';
import { Client } from '../../../types/client';
import ErrorBoundaryContainer from '../ErrorBoundaryContainer';

interface Props {
  client: Client;
  sessions: Sessions;
  onOpenChat: (uid: string) => void;
}

const ClientCard: FC<Props> = ({ client, sessions, onOpenChat }) => {
  const {
    profile,
    created_at: { _seconds: seconds },
  } = client;

  const onClick = () => {
    onOpenChat(client.profile.uid);
  };

  return (
    <ErrorBoundaryContainer>
      <StyledClientCard fluid>
        <Image src={profile.photo} wrapped ui={false} />
        <Card.Content>
          <Card.Header>{client.profile.name}</Card.Header>
          <Card.Meta>
            <span className="date">
              joined {moment.unix(seconds).fromNow()}
            </span>
          </Card.Meta>
          <Card.Description>{client.profile.bio}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Icon name="user md" />
          {`${sessions.length} session${
            sessions.length === 1 ? '' : 's'
          } so far`}
        </Card.Content>
        <Card.Content extra>
          <Button primary fluid onClick={onClick}>
            Send Message
          </Button>
        </Card.Content>
      </StyledClientCard>
    </ErrorBoundaryContainer>
  );
};

export default ClientCard;
