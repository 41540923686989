import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PromoState } from '../store/types';

const initialState = {
  activeReferralCode: null,
} as PromoState;

const promoSlice = createSlice({
  name: 'promo',
  initialState,
  reducers: {
    setActiveReferralCode: (state, action: PayloadAction<string>) => {
      state.activeReferralCode = action.payload;
    },
    removeActiveReferralCode: (state) => {
      state.activeReferralCode = null;
    },
  },
});

export const { removeActiveReferralCode, setActiveReferralCode } =
  promoSlice.actions;

export default promoSlice.reducer;
