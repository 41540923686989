/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAsyncSaga } from 'create-async-saga';
import { eventChannel } from 'redux-saga';
import {
  all,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import * as Firebase from '../../services/firebase';
import * as authTypes from '../auth/auth.actionTypes';
import { clientsSelector } from '../clients/clients.selectors';
import * as types from './chat.actionTypes';
import { roomsSelector } from './chat.selectors';

function* observeChat(data: {
  recipientID: string;
  role: Role;
  lastReceived: string;
}): any {
  const channel = eventChannel((emitter) => {
    const observer = Firebase.chatObserver(emitter, data);
    return () => observer();
  });

  while (true) {
    try {
      const messages = yield take(channel);
      yield put({
        type: getClientsChat.fulfilled.type,
        payload: {
          messages,
          recipientID: data?.recipientID,
        },
      });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

export const getClientsChat = createAsyncSaga(
  types.GET_CLIENTS_CHAT,
  function* () {
    const rooms = yield select(roomsSelector);
    const clients: ReturnType<typeof clientsSelector> = yield select(
      clientsSelector
    );

    const observers = yield all(
      clients.map((client) => {
        // Get the lastReceived of previously fetched messages of each clients
        const lastReceived = rooms[client.uid]
          ? rooms[client.uid].lastReceived
          : null;

        const observer = fork(observeChat, {
          recipientID: client.uid,
          role: 'coach',
          lastReceived,
        });

        return observer;
      })
    );
    yield take(authTypes.LOGOUT);
    yield cancel(observers);
  }
);

export default function* coachChatSaga() {
  yield takeLatest(getClientsChat.actionType, getClientsChat.asyncSaga);
}
