import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import { Calendar } from 'react-big-calendar';

import { colors } from '../../../theme';

export const LeftSideHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
`;

export const PrimaryButton = styled(Button)`
  && {
    border-radius: 0;
    background: ${colors.geraldine};
    color: ${colors.white};
    font-size: 15px;
    padding: 16px;
    margin: 0;
  }
`;

export const BigCalendar = styled<any>(Calendar)`
  .rbc-toolbar-label {
    margin: 20px auto;
  }
`;
