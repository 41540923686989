import firebase from 'firebase/app';
import 'firebase/auth';
// import 'firebase/analytics';
import 'firebase/firestore';

export function getUrlPrefix() {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'us-central1-blush-production';
    case 'development':
    case 'staging':
    default:
      return 'us-central1-blush-dev-b8ca2';
  }
}

const config = Object.freeze({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY || '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || '',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '',
});

console.log('*** Environment ***', process.env.REACT_APP_ENV);
console.log('*** Firebase Config ***', config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
  // firebase.analytics();
}

export default firebase;
