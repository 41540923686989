import styled from 'styled-components';
import { Header } from 'semantic-ui-react';

// eslint-disable-next-line import/prefer-default-export
export const Title = styled(Header)`
  && {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
  }
`;
