/* eslint-disable no-underscore-dangle */
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Comment, Divider, Image, Message } from 'semantic-ui-react';
import moment from 'moment';

import _groupBy from 'lodash/groupBy';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import { Message as MessageT, MessageUser } from '../../../types/message';
import ChatForm from '../ChatForm';
import { PLACEHOLDER_IMAGE } from '../../../config/constants';
import { CommentAvatar, Feed } from './styles';
import MessageIndicator from '../MessageIndicator';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSelector } from '../../../redux/user/user.selectors';
import { roleSelector } from '../../../redux/auth/auth.selectors';
import { chatStatusSelector } from '../../../redux/chat/chat.selectors';
import {
  addChat,
  updateChatReadReceipts,
  uploadMedia,
} from '../../../redux/chat/chat.saga';

interface Props {
  chats: MessageT[];
  recipient?: UserProfile;
  disabled?: boolean;
  archived?: boolean;
  showReadReceipt?: boolean;
}

const ChatFeed: FC<Props> = ({
  chats,
  recipient,
  disabled,
  archived,
  showReadReceipt = false,
}) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(roleSelector);
  const user = useAppSelector(userSelector);
  const status = useAppSelector(chatStatusSelector);

  const addChatAction = useCallback(
    (params: { recipientID: string; role: Role; message: MessageT }) => {
      dispatch(addChat.action(params));
    },
    [dispatch]
  );

  const uploadMediaAction = useCallback(
    (params: {
      text: string;
      recipientID: string;
      role: Role;
      user: MessageUser;
      media: Blob | Uint8Array | ArrayBuffer;
    }) => {
      dispatch(uploadMedia.action(params));
    },
    [dispatch]
  );

  const updateChatReadReceiptAction = useCallback(
    (params: { recipientID: string; role: Role }) => {
      dispatch(updateChatReadReceipts.action(params));
    },
    [dispatch]
  );

  const anchorRef = useRef(null);
  const currentUser: MessageUser = useMemo(
    () => ({
      _id: user.profile.uid,
      name: user.profile.name,
      avatar: user.profile.photo,
    }),
    [user]
  );

  useEffect(() => {
    if (anchorRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      anchorRef?.current?.scrollIntoView();
    }
  }, [chats]);

  useEffect(() => {
    if (recipient && recipient.uid)
      updateChatReadReceiptAction({
        role,
        recipientID: recipient.uid,
      });
  }, [chats, recipient, role, updateChatReadReceiptAction]);

  const onSubmit = useCallback(
    ({ text = '', recipientID, file }) => {
      if (file) {
        return uploadMediaAction({
          user: currentUser,
          role,
          recipientID,
          text,
          media: file,
        });
      }

      return addChatAction({
        role,
        recipientID,
        message: {
          text,
          user: currentUser,
        },
      });
    },
    [addChatAction, currentUser, role, uploadMediaAction]
  );

  const groups = useMemo(
    () =>
      _groupBy(chats, (chat: MessageT) => moment(chat.createdAt).format('LL')),
    [chats]
  );

  const getClassName = (chat: any) => {
    if (chat.user._id === user.profile.uid) return 'host';
    if (chat.system) return 'system';

    return '';
  };

  return (
    <Feed>
      <Comment.Group>
        {_isEmpty(groups) ? (
          <Message>Nothing to show.</Message>
        ) : (
          <>
            {_map(groups, (groupChats, title) => (
              <React.Fragment key={title}>
                <Divider horizontal>{title}</Divider>
                {groupChats
                  .filter(
                    (c) => !c.system || c.recipientID === user.profile.uid
                  )
                  .map((chat) => {
                    const isHost = chat.user._id === user.profile.uid;
                    return (
                      <Comment key={chat._id} className={getClassName(chat)}>
                        {!chat.system && (
                          <CommentAvatar
                            src={
                              isHost
                                ? user.profile.photo
                                : chat.user.avatar || PLACEHOLDER_IMAGE
                            }
                          />
                        )}
                        <Comment.Content>
                          <Comment.Author as="a">
                            {isHost ? user.profile.name : chat.user.name}
                          </Comment.Author>
                          <Comment.Metadata>
                            <div>{moment(chat.created_at).format('LT')}</div>
                          </Comment.Metadata>
                          <Image rounded size="medium" src={chat.image} />
                          {chat.text && (
                            <Comment.Text
                              className={chat.system ? 'system' : ''}>
                              {chat.text}
                            </Comment.Text>
                          )}
                          <MessageIndicator
                            showReadReceipt={showReadReceipt}
                            isHost={isHost}
                            chat={chat}
                          />
                        </Comment.Content>
                      </Comment>
                    );
                  })}
              </React.Fragment>
            ))}
            <div ref={anchorRef} style={{ height: 1 }} />
          </>
        )}
      </Comment.Group>
      {!archived && (
        <ChatForm
          disabled={disabled}
          status={status}
          recipientID={recipient?.uid}
          onSubmit={onSubmit}
        />
      )}
    </Feed>
  );
};

export default ChatFeed;
