import firebase from 'firebase';

const pushTokensCollection = firebase.firestore().collection('pushTokens');

const OneSignal = window.OneSignal || {};

// eslint-disable-next-line import/prefer-default-export
export const updateWebPushToken = (userId: string) =>
  OneSignal.push(async () => {
    const isEnabled = await OneSignal.isPushNotificationsEnabled();
    const webPushToken = isEnabled ? await OneSignal.getUserId() : null;
    return pushTokensCollection.doc(userId).set(
      {
        webPushToken,
        updated_at: firebase.firestore.Timestamp.now(),
      },
      {
        merge: true,
      }
    );
  });
