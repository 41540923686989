import _pickBy from 'lodash/pickBy';
import React, { FC, useState } from 'react';
import { Form, Header, Rating, TextArea } from 'semantic-ui-react';
import { Button } from '..';
import ExtendedModal, { Row } from './styles';
import { ApplicationState, useAppSelector } from '../../../redux/store';

interface Props {
  onCloseModal?: () => void;
  showModal: any;
  createSessionFeedback?: any;
}

const SessionFeedbackModal: FC<Props> = ({
  createSessionFeedback,
  showModal,
  onCloseModal,
}) => {
  const { session, status } = useAppSelector(
    (state: ApplicationState) => state.sessionFeedback
  );

  const [sessionRating, setSessionRating] = useState<any>(0);
  const [audioRating, setAudioRating] = useState<any>(0);
  const [videoRating, setVideoRating] = useState<any>(0);
  const [note, setNote] = useState<any>('');

  const onSubmit = () => {
    // Omit zero-value rating
    const ratings = _pickBy({ sessionRating, audioRating, videoRating });
    if (session) {
      const { coachUid, userUid, sessionFirebaseUid: sessionId } = session;

      const feedback = {
        coachUid,
        userUid,
        sessionId,
        note,
        ...ratings,
      };

      createSessionFeedback(feedback);
    }
  };

  return (
    <ExtendedModal open={showModal} onClose={onCloseModal}>
      <Header as="h3">How was your session with your coach?</Header>
      <Row>
        <Header as="h4">Overall</Header>
        <Rating
          rating={sessionRating}
          maxRating={5}
          size="huge"
          icon="star"
          onRate={(e, { rating }) => setSessionRating(rating)}
        />
      </Row>
      <Row>
        <Header as="h4">Audio quality</Header>
        <Rating
          rating={audioRating}
          maxRating={5}
          size="huge"
          icon="star"
          onRate={(e, { rating }) => setAudioRating(rating)}
        />
      </Row>
      <Row>
        <Header as="h4">Video quality</Header>
        <Rating
          rating={videoRating}
          maxRating={5}
          size="huge"
          icon="star"
          onRate={(e, { rating }) => setVideoRating(rating)}
        />
      </Row>
      <Form>
        <TextArea
          style={{ minHeight: 100 }}
          placeholder="Tell us more"
          value={note}
          onChange={(e, { value }) => setNote(value)}
        />
      </Form>
      <Button
        primary
        fluid
        disabled={status === 'loading'}
        loading={status === 'loading'}
        onClick={onSubmit}>
        Submit
      </Button>
    </ExtendedModal>
  );
};

export default SessionFeedbackModal;
