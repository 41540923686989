import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { logout } from '../auth/auth.saga';
import { CoachesState } from '../store/types';
import { getUser } from '../user/user.saga';
import {
  getCoaches,
  getPreviousCoachProfile,
  getUserCoach,
  matchCoach,
} from './coaches.saga';

const findBestCoach = (coaches: { score: number }[]) => {
  const sortedCoaches = coaches.sort((a, b) => b.score - a.score);
  return sortedCoaches[0];
};

const initialState = {
  status: 'pending',
} as CoachesState;

const coachesSlice = createSlice({
  name: 'coaches',
  initialState,
  reducers: {
    updateMatchedCoach: (state, action) => {
      state.coach = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action: any) => ({
      ...state,
      coach: state.coach || action.payload.coach,
      previousCoaches: action.payload.coach?.previousCoaches,
      status: action.payload.coach ? 'success' : 'pending',
    }));
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
    }));
    builder.addCase(getUserCoach.fulfilled, (state, action: any) => {
      state.coach = {
        ...state.coach,
        ...(action.payload as Coach),
      };
      state.status = 'success';
    });
    builder.addCase(getCoaches.fulfilled, (state, action) => {
      state.coaches = action.payload;
      state.status = 'success';
    });
    builder.addCase(getPreviousCoachProfile.fulfilled, (state, action) => {
      state.previousCoaches = action.payload;
      state.status = 'success';
    });
    builder.addCase(matchCoach.fulfilled, (state, action) => {
      const { rankedCoaches } = action.payload;
      state.coach = {
        ...(rankedCoaches && { matchResults: rankedCoaches }),
        ...(rankedCoaches && findBestCoach(rankedCoaches)),
      };
      state.status = 'success';
    });
    builder.addMatcher(
      isAnyOf(
        getCoaches.pending,
        getUserCoach.pending,
        getPreviousCoachProfile.pending,
        matchCoach.pending
      ),
      (state) => {
        state.status = 'loading';
      }
    );
    builder.addMatcher(
      isAnyOf(
        getCoaches.rejected,
        getUserCoach.rejected,
        getPreviousCoachProfile.rejected,
        matchCoach.rejected
      ),
      (state) => {
        state.status = 'failed';
      }
    );
  },
});

export const { updateMatchedCoach } = coachesSlice.actions;

export default coachesSlice.reducer;
