import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Header } from 'semantic-ui-react';
import moment from 'moment';
import { momentLocalizer } from 'react-big-calendar';

import { SessionDetailsModal } from '../../../components/ui';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Pane } from '../../../components/layout/Pane';
import SessionCard from '../../../components/ui/SessionCard';
import { BigCalendar, LeftSideHeader } from './styles';
import AppLayout from '../../../components/layout/AppLayout';
import DashboardLayout from '../../../components/layout/DashboardLayout';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { roleSelector } from '../../../redux/auth/auth.selectors';

import {
  allUpcomingSessionsSelector,
  sessionsSelector,
  videoStatusSelector,
} from '../../../redux/video/video.selectors';
import {
  clientsSelector,
  clientsStatusSelector,
} from '../../../redux/clients/clients.selectors';
import { getClients as getClientsAction } from '../../../redux/clients/clients.saga';
import {
  cancelSession as cancelSessionAction,
  getSessions as getSessionsAction,
} from '../../../redux/video/video.saga';
import { coachSelector } from '../../../redux/coaches/coaches.selectors';

const localizer = momentLocalizer(moment);

const Dashboard: FC = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(roleSelector);
  const allUpcomingSessions = useAppSelector(allUpcomingSessionsSelector);
  const videoStatus = useAppSelector(videoStatusSelector);
  const sessions = useAppSelector(sessionsSelector);
  const clients = useAppSelector(clientsSelector);
  const clientStatus = useAppSelector(clientsStatusSelector);

  const [sessionUid, setSessionUid] = useState<string | undefined | null>(null);

  const getClients = useCallback(() => {
    dispatch(getClientsAction.action());
  }, [dispatch]);

  const getSessions = useCallback(() => {
    dispatch(getSessionsAction.action());
  }, [dispatch]);

  const cancelSession = useCallback(
    (sessionId: string | null | undefined) => {
      dispatch(cancelSessionAction.action(sessionId ?? ''));
    },
    [dispatch]
  );

  useEffect(() => {
    if (videoStatus === 'pending') getSessions();
  }, [getSessions, role, videoStatus]);

  useEffect(() => {
    if (clientStatus === 'pending') getClients();
  }, [clientStatus, getClients]);

  const displaySessions = useMemo(
    () => allUpcomingSessions.slice(0, 4),
    [allUpcomingSessions]
  );

  const onJoinSession = (session: any) => {
    window.open(
      `/coach/session/${session.uid}`,
      'Session',
      'width=600,height=800'
    );
  };

  const renderSessionCard = useMemo(
    () =>
      displaySessions.map((session) => (
        <SessionCard
          key={session.uid}
          profile={session.profile}
          session={session}
          onJoinSession={() => onJoinSession(session)}
          onOpenModal={() => setSessionUid(session.uid)}
        />
      )),
    [displaySessions]
  );

  const selectedSession = useMemo(
    () => allUpcomingSessions.find((session) => session.uid === sessionUid),
    [allUpcomingSessions, sessionUid]
  );

  const events = useMemo(
    () =>
      sessions.map((session) => {
        const client = clients.find((v) => v.profile.uid === session.userUid);
        return {
          title: client ? client.profile.name : '...',
          start: session.block.start.toDate(),
          end: moment(session.block.start.toDate())
            .add(session.duration, 'minutes')
            .toDate(),
          resource: {
            ...session,
            client,
          },
        };
      }),
    [clients, sessions]
  );

  const coach = useAppSelector(coachSelector);

  return (
    <AppLayout page="dashboard">
      <DashboardLayout
        coach={coach}
        role={role}
        upcomingSessions={allUpcomingSessions}
        sessions={sessions}
        onSetSessionId={setSessionUid}>
        <LeftSideHeader>
          <div>
            <Header as="h1">Upcoming sessions</Header>
          </div>
        </LeftSideHeader>
        <Card.Group itemsPerRow={2} stackable>
          {renderSessionCard}
        </Card.Group>
        <Pane height={50} />
        <BigCalendar
          localizer={localizer}
          events={events}
          style={{ height: 500 }}
          scrollToTime={new Date()}
        />
      </DashboardLayout>
      {selectedSession && (
        <SessionDetailsModal
          userRole={role}
          session={selectedSession}
          name={selectedSession.profile.name}
          photo={selectedSession.profile.photo}
          showModal={!!sessionUid}
          confirmModalLoader={videoStatus === 'loading'}
          onCloseModal={() => setSessionUid(null)}
          onJoinSession={() => onJoinSession(selectedSession)}
          onCancelSession={() => cancelSession(sessionUid)}
        />
      )}
    </AppLayout>
  );
};

export default Dashboard;
