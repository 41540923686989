import React from 'react';
import { Button } from 'semantic-ui-react';
import { FooterContainer } from './styles';

const Footer: React.FC<{
  properties: {
    publishAudio: boolean;
    publishVideo: boolean;
  };
  onToggleAudio: () => void;
  onEndCall: () => void;
  onToggleVideo: () => void;
}> = ({ properties, onToggleAudio, onEndCall, onToggleVideo }) => {
  const { publishAudio, publishVideo } = properties;

  return (
    <FooterContainer>
      <Button
        onClick={onToggleAudio}
        size="massive"
        circular
        color={publishAudio ? undefined : 'google plus'}
        icon={publishAudio ? 'microphone' : 'microphone slash'}
      />
      <Button
        onClick={onEndCall}
        size="massive"
        circular
        color="google plus"
        icon="close"
      />
      <Button
        onClick={onToggleVideo}
        size="massive"
        circular
        color={publishVideo ? undefined : 'google plus'}
        icon={publishVideo ? 'video' : 'eye slash'}
      />
    </FooterContainer>
  );
};

export default Footer;
