import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { authSelector, roleSelector } from '../redux/auth/auth.selectors';
import { useAppSelector } from '../redux/store';
import {
  isProfileCompleteSelector,
  userSelector,
} from '../redux/user/user.selectors';

type AppRouteProps = RouteProps & {
  component: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentProps?: any;
};

const AppRoute: React.FC<AppRouteProps> = ({
  component: Component,
  componentProps,
  ...rest
}) => {
  const location = useLocation();
  const auth = useAppSelector(authSelector);
  const user = useAppSelector(userSelector);
  const role = useAppSelector(roleSelector);
  const isProfileComplete = useAppSelector(isProfileCompleteSelector);

  if (
    auth?.status === 'pending' ||
    (auth.status === 'success' && user?.status === 'pending')
  ) {
    return (
      <Dimmer active inverted>
        <Loader active size="big" inline="centered">
          Loading
        </Loader>
      </Dimmer>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth?.status === 'success' && ['user', 'coach'].includes(role)) {
          const req = location.pathname.split('/');

          // check path to make sure the user role is allowed there
          if (req[1] !== role) {
            return (
              <Redirect
                to={{
                  pathname: `/${role}/dashboard`,
                  state: { from: location },
                }}
              />
            );
          }

          if (role === 'user' && !isProfileComplete) {
            return (
              <Redirect
                to={{
                  pathname: `/onboarding/session-plan`,
                  state: { from: location },
                }}
              />
            );
          }

          return <Component {...{ ...props, ...componentProps }} />;
        }

        return (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        );
      }}
    />
  );
};

export default AppRoute;
