import { yupResolver } from '@hookform/resolvers/yup';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Token } from '@stripe/stripe-js';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form, Icon } from 'semantic-ui-react';
import * as yup from 'yup';
import { VALIDATION_MESSAGES } from '../../../config/constants';
import useWindowSize from '../../../hooks/useWindowSize';
import { useAppSelector } from '../../../redux/store';
import { subscriptionSelector } from '../../../redux/subscription/subscription.selectors';
import { colors } from '../../../theme';
import { Pane } from '../../layout';
import { Ahref, Button, WizForm } from '../../layout/WizardLayout/styles';

interface FormData {
  cardholderName: string;
}

const cardElementStyle = {
  base: {
    '::placeholder': {
      color: colors.silver,
    },
  },
};

const schema = yup.object().shape({
  cardholderName: yup.string().required(VALIDATION_MESSAGES.required),
});

const PaymentForm: React.FC<{
  defaultValues: { cardholderName: string };
  stripeId: string;
  successPath: string;
  onBack: () => void;
  onSubmit: (data: {
    token: Token;
    customerId: string;
    successPath: string;
  }) => void;
}> = ({ defaultValues, stripeId, successPath, onSubmit, onBack }) => {
  const stripe = useStripe();
  const [submitting, setSubmitting] = useState(false);
  const elements = useElements();
  const { isMobile } = useWindowSize();
  const subscription = useAppSelector(subscriptionSelector);
  const { status } = subscription;

  const { handleSubmit, control, formState } = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const onFormSubmit = async (formData: FormData) => {
    try {
      setSubmitting(true);
      const cardElement = elements!.getElement('card');
      const { token } = await stripe!.createToken(cardElement!, {
        name: formData.cardholderName,
      });
      onSubmit({ token: token!, customerId: stripeId, successPath });
    } catch (err: any) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <WizForm size="big" onSubmit={handleSubmit(onFormSubmit)}>
      <Controller
        control={control}
        name="cardholderName"
        render={({ field: { ref, ...field } }) => (
          <Form.Input
            placeholder="Name"
            label="Name of card"
            error={errors?.cardholderName?.message}
            {...field}
          />
        )}
      />
      <Form.Field
        label="Credit card"
        name="creditCard"
        control={CardElement}
        style={cardElementStyle}
      />
      <Pane marginTop="4px">
        <Button
          basic
          color="black"
          size="big"
          style={{ marginRight: 12 }}
          onClick={onBack}>
          Back
        </Button>
        {isMobile && <Pane height={14} />}
        <Button
          type="submit"
          icon
          labelPosition="right"
          size="big"
          color="black"
          loading={submitting || status === 'loading'}
          disabled={submitting || status === 'loading'}>
          Continue
          <Icon name="chevron right" />
        </Button>
      </Pane>
      <Form.Field style={{ marginTop: 24, fontSize: 14 }}>
        We process all credit cards using the secure Stripe payment method.
        Information can be found&nbsp;
        <Ahref
          target="_blank"
          rel="noopener noreferrer"
          href="https://stripe.com/">
          here
        </Ahref>
        .
      </Form.Field>
    </WizForm>
  );
};

export default PaymentForm;
