/* eslint-disable @typescript-eslint/no-use-before-define */
import { Token } from '@stripe/stripe-js';
import { push } from 'connected-react-router';
import { createAsyncSaga } from 'create-async-saga';
import TagManager from 'react-gtm-module';
import { eventChannel } from 'redux-saga';
import {
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import * as Firebase from '../../services/firebase';
import * as Stripe from '../../services/stripe';
import Swal from '../../utils/Swal';
import * as authTypes from '../auth/auth.actionTypes';
import { coachSelector } from '../coaches/coaches.selectors';
import { updateUser } from '../user/user.saga';
import * as types from './subscription.actionTypes';

function* observeSubscription(): any {
  const channel = eventChannel((emitter) => {
    const observer = Firebase.subscriptionObserver(emitter);
    return () => observer();
  });

  while (true) {
    try {
      const response = yield take(channel);
      yield put({
        type: getSubscription.fulfilled.type,
        payload: response,
      });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

export const getSubscription = createAsyncSaga(
  types.GET_SUBSCRIPTION,
  function* () {
    const observer = yield fork(observeSubscription);
    yield take(authTypes.LOGOUT);
    yield cancel(observer);
  }
);

export const getStripeCustomer = createAsyncSaga(
  types.GET_STRIPE_CUSTOMER,
  function* (stripeId: string) {
    const response = yield Stripe.getCustomer(stripeId);

    if (response?.user && window?.barepay?.load) {
      window.barepay.params.customer_oid = response.user.id;
      window.barepay.load();
    }

    return response.user;
  }
);

export const addCard = createAsyncSaga(
  types.ADD_CARD,
  function* (data: {
    token: Token;
    customerId: string;
    meta?: { successAlertMsg?: string; successPath?: string };
  }) {
    const { token, customerId, meta } = data;

    try {
      const response = yield Stripe.addCard({ token, customerId });

      yield put(getStripeCustomer.action(data?.customerId));

      if (meta) {
        if (meta.successAlertMsg) {
          yield Swal.fire('Success', meta.successAlertMsg, 'success');
        }

        if (meta.successPath) {
          yield put(push(meta.successPath));
        }
      }

      return response;
    } catch (err: any) {
      Swal.fire(
        'Error',
        `${
          err.messsage || 'Unable to add card.'
        } Please try again or contact support.`,
        'error'
      );
      throw err;
    }
  }
);

export const validateCouponCode = createAsyncSaga(
  types.VALIDATE_COUPON_CODE,
  function* (data: { couponCode: string | null } | null) {
    try {
      let response = null;

      if (data?.couponCode) {
        response = yield Stripe.validateCouponCode(data);
        if (
          // // eslint-disable-next-line camelcase
          // response?.redeem_by &&
          // // eslint-disable-next-line camelcase
          // new Date(response?.redeem_by * 1000) >= new Date()
          response?.valid
        ) {
          yield Swal.fire(
            'Success',
            'Promo code is applied successfully.',
            'success'
          );
          return response;
        }
        throw new Error('Coupon expired.');
      }

      return null;
    } catch (err: any) {
      yield Swal.fire(
        'Error',
        `Unable to apply promo code. ${
          (err.message !== 'INTERNAL' && err.message) ||
          'Promotional codes are case-sensitive.'
        } Please try again or contact support.`,
        'error'
      );
      throw err;
    }
  }
);

export const purchaseSubscription = createAsyncSaga(
  types.PURCHASE_SUBSCRIPTION,
  function* (data: {
    plan: { uid: string; name?: string; price?: number };
    couponCode?: string;
    stripeId: string;
    meta?: {
      onboarding: boolean;
    };
  }) {
    const showAlertError = () =>
      Swal.fire(
        'Error',
        'Unable to purchase subscription. Please try again or contact support.',
        'error'
      );

    const showAlertSuccess = () =>
      Swal.fire('Success', 'Subscription purchase successful!', 'success');

    try {
      const coach = yield select(coachSelector);

      const { plan, couponCode, stripeId, meta } = data;

      const response = yield Stripe.purchaseSubscription({
        plan: plan.uid,
        couponCode,
      });

      if (
        response?.data?.statusCode === 402 &&
        response?.data?.type === 'StripeCardError'
      ) {
        throw new Error('Error purchasing subscription');
      }

      const dataLayer = {
        transactionId:
          response?.data?.items?.data?.[0].id || `${stripeId}_${plan.uid}`,
        transactionTotal: plan.price,
        transactionProducts: [
          {
            sku: plan.uid,
            name: plan.name,
            price: plan.price,
            quantity: 1,
          },
        ],
      };

      yield TagManager.dataLayer({ dataLayer });

      yield put(getStripeCustomer.action(stripeId));

      if (meta?.onboarding) {
        yield put(
          updateUser.action({
            data: {
              'profile.isProfileComplete': true,
              coach,
            },
            meta: {
              onboarding: true,
            },
          })
        );
        yield put(push('/onboarding/success'));
      } else {
        yield showAlertSuccess();
      }

      return response;
    } catch (err: any) {
      console.log(err);
      showAlertError();
      throw err;
    }
  }
);

export default function* subscriptionSaga() {
  yield takeLatest(getSubscription.actionType, getSubscription.asyncSaga);
  yield takeLatest(getStripeCustomer.actionType, getStripeCustomer.asyncSaga);
  yield takeLatest(addCard.actionType, addCard.asyncSaga);
  yield takeLatest(validateCouponCode.actionType, validateCouponCode.asyncSaga);
  yield takeLatest(
    purchaseSubscription.actionType,
    purchaseSubscription.asyncSaga
  );
}
