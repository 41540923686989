import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '../store/types';
import { getContent } from './app.saga';

const initialState = {
  isInitialized: false,
  loading: false,
  dashboardContent: null,
} as AppState;

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setInitialized: (state, action) => {
      state.isInitialized = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContent.fulfilled, (state, action) => {
      state.dashboardContent = action.payload;
    });
  },
});

export const { setInitialized } = appSlice.actions;

export default appSlice.reducer;
