import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Form, Header } from 'semantic-ui-react';
import * as yup from 'yup';
import { AppLayout, Pane, SettingsLayout } from '../../../../components/layout';
import { SettingMenuItem } from '../../../../components/layout/SettingsLayout/SettingsMenu';
import {
  Avatar,
  PrimaryButton,
} from '../../../../components/layout/SettingsLayout/styles';
import {
  GENDERS,
  PLACEHOLDER_IMAGE,
  VALIDATION_MESSAGES,
} from '../../../../config/constants';
import { USER_TABS } from '../../../../constants/tabs';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  updateProfilePhoto,
  updateUser,
} from '../../../../redux/user/user.saga';
import {
  userProfileSelector,
  userSelector,
} from '../../../../redux/user/user.selectors';

type FormData = {
  name: string;
  dob: string;
  email: string;
  bio: string;
  gender: string;
  photo: string;
};

const schema = yup.object().shape({
  name: yup.string().required(VALIDATION_MESSAGES.required),
  dob: yup.string().required(VALIDATION_MESSAGES.required),
  email: yup
    .string()
    .email(VALIDATION_MESSAGES.email)
    .required(VALIDATION_MESSAGES.required),
  bio: yup.string().required(VALIDATION_MESSAGES.required),
  gender: yup.string().required(VALIDATION_MESSAGES.required),
});

interface Photo extends File {
  preview: string;
}

const Profile: React.FC = () => {
  const history = useHistory();
  const user = useAppSelector(userSelector);
  const { status } = user;
  const userProfile = useAppSelector(userProfileSelector);
  const dispatch = useAppDispatch();

  const [files, setFiles] = useState<Photo[]>([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles: File[]) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const { handleSubmit, control, formState } = useForm<FormData>({
    defaultValues: {
      ...userProfile,
      dob: moment(
        `${userProfile.yearOfBirth}-${userProfile.monthOfBirth}-${userProfile.dayOfBirth}`,
        'YYYY-M-DD'
      ).format('YYYY-MM-DD'),
    },
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const getPhotoImage = () => {
    if (files.length > 0) {
      return files[0].preview;
    }
    return userProfile.photo || PLACEHOLDER_IMAGE;
  };

  const onFormSubmit = (data: FormData) => {
    const dob = moment(data.dob);
    dispatch(
      updateUser.action({
        data: {
          'profile.name': data.name,
          'profile.email': data.email,
          'profile.gender': data.gender,
          'profile.bio': data.bio,
          'profile.monthOfBirth': dob.format('M'),
          'profile.dayOfBirth': dob.format('D'),
          'profile.yearOfBirth': dob.format('YYYY'),
        },
      })
    );

    if (files.length > 0) {
      dispatch(updateProfilePhoto.action(files[0]));
    }
  };

  const handleNavigationChange = (item: SettingMenuItem) => {
    history.push(item.name);
  };

  return (
    <AppLayout page="settings">
      <SettingsLayout
        items={USER_TABS}
        selectedItem={{ name: 'profile' }}
        onSelectItem={handleNavigationChange}>
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <Pane
            display="flex"
            justifyContent="center"
            {...getRootProps({ refKey: 'ref' })}>
            <input {...getInputProps()} />
            <Avatar src={getPhotoImage()} size="small" circular />
          </Pane>
          <Pane height={25} />
          <Form.Group widths="equal">
            <Controller
              control={control}
              name="name"
              render={({ field: { ref, ...field } }) => (
                <Form.Input
                  label="Name"
                  error={errors?.name?.message}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="dob"
              render={({ field: { ref, ...field } }) => (
                <Form.Input
                  fluid
                  label="Birthday"
                  type="date"
                  error={errors?.dob?.message}
                  {...field}
                />
              )}
            />
          </Form.Group>
          <Controller
            control={control}
            name="bio"
            render={({ field: { ref, ...field } }) => (
              <Form.TextArea
                label="Bio"
                placeholder="Tell us more about you..."
                error={errors?.bio?.message}
                {...field}
              />
            )}
          />
          <Header as="h3">Private Information</Header>
          <Form.Group widths="equal">
            <Controller
              control={control}
              name="email"
              render={({ field: { ref, ...field } }) => (
                <Form.Input
                  label="Email Address"
                  error={errors?.email?.message}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="gender"
              render={({ field }) => (
                <Form.Select
                  label="Gender"
                  fluid
                  error={errors?.gender?.message}
                  options={GENDERS.map((gender) => ({
                    value: gender,
                    text: gender,
                  }))}
                  {...field}
                  onChange={(e, { value }) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </Form.Group>
          <Pane height={32} />
          <PrimaryButton
            loading={status === 'loading'}
            disabled={status === 'loading'}
            primary
            type="submit">
            Save
          </PrimaryButton>
        </Form>
      </SettingsLayout>
    </AppLayout>
  );
};

export default Profile;
