import React, { FC, useState } from 'react';
import { Button, Header, Image } from 'semantic-ui-react';
import moment from 'moment';

import Swal from 'sweetalert2';
import { PLACEHOLDER_IMAGE } from '../../../config/constants';
import ExtendedModal from './styles';
import { Pane } from '../../layout/Pane';
import AddToCalendar from '../AddToCalendar';
import ConfirmModal from '../ConfirmModal';

interface Props {
  userRole: string;
  session: Session;
  name: string | undefined;
  photo: string | undefined;
  showModal: boolean;
  confirmModalLoader: boolean;
  onCloseModal: () => void;
  onJoinSession: () => void;
  onCancelSession: () => void;
}

const SessionDetailsModal: FC<Props> = ({
  userRole,
  session,
  name,
  photo,
  showModal,
  confirmModalLoader,
  onCloseModal,
  onJoinSession,
  onCancelSession,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onClickCancelSession = async () => {
    const sessionMoment = moment(parseInt(session.block.dateUTC, 10));

    if (userRole === 'user' && sessionMoment.diff(moment(), 'hour') < 24) {
      const { value } = await Swal.fire({
        icon: 'error',
        title: 'Late Cancellation',
        text: 'Cancellations are not allowed less than 24 hours before your session is scheduled. If you cannot make your session, please email support.',
        confirmButtonText: 'Email Support',
        cancelButtonText: 'Back',
        showCancelButton: true,
      });

      if (value) {
        window.open('mailto:support@joinblush.com', '_blank');
      }
    } else {
      setShowConfirmModal(true);
    }
  };

  return (
    <ExtendedModal open={showModal} onClose={onCloseModal}>
      <div className="session-basic">
        <Image src={photo || PLACEHOLDER_IMAGE} />
        <div className="session-info">
          <span className="date">
            {moment(session.block.start.toDate()).format('LLLL')}
          </span>
          <span className="name">{name}</span>
        </div>
      </div>
      {session.status !== 'CANCELED' ? (
        <Pane width="100%">
          <Button
            icon="users"
            basic
            fluid
            color="black"
            content="Join Session"
            onClick={onJoinSession}
          />
          <Pane height="8px" />
          <AddToCalendar
            event={{
              name: `Blush Session with ${name}`,
              location: '',
              details: '',
              startsAt: moment(session.block.start.toDate()).toISOString(),
              endsAt: moment(session.block.start.toDate())
                .add(session.duration || 30, 'm')
                .toISOString(),
            }}
            filename={`Blush Session with ${name}`}
          />
          <Pane height="8px" />
          <Button
            icon="remove circle"
            basic
            fluid
            color="black"
            content="Cancel Session"
            onClick={onClickCancelSession}
          />
          <ConfirmModal
            showConfirmModal={showConfirmModal}
            title="Do you want to cancel this session?"
            loading={confirmModalLoader}
            onClose={() => setShowConfirmModal(false)}
            onClick={onCancelSession}
          />
        </Pane>
      ) : (
        <Header as="h3" style={{ marginTop: 0 }}>
          Session is canceled
        </Header>
      )}
    </ExtendedModal>
  );
};

export default SessionDetailsModal;
