import React, { FC, ReactNode } from 'react';
import { Image } from 'semantic-ui-react';
import { AuthContainer, LeftSide, RightSide, Title } from './styles';

interface Props {
  children: ReactNode;
}

const AuthLayout: FC<Props> = ({ children }) => (
  <AuthContainer stretched>
    <LeftSide width={9}>
      <Image src="/bg-cover.jpg" style={{ objectFit: 'cover' }} />
      <Title>Online life coaching</Title>
    </LeftSide>
    <RightSide width={7}>{children}</RightSide>
  </AuthContainer>
);

export default AuthLayout;
