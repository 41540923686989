import React, { FC, useMemo, useState } from 'react';
import { Header, Icon, Pagination } from 'semantic-ui-react';
import moment from 'moment';

import { SessionItem } from './styles';
import { List } from '../Announcements/styles';

const PER_PAGE = 8;

interface Props {
  sessions: Sessions;
  onSetSessionId: (id: string | undefined) => void;
}

const UpcomingSessions: FC<Props> = ({ sessions, onSetSessionId }) => {
  const [currentPage, setCurrentPage] = useState<any>(1);
  const displaySessions = useMemo(
    () =>
      sessions.slice(
        (currentPage - 1) * PER_PAGE,
        (currentPage - 1) * PER_PAGE + PER_PAGE
      ),
    [currentPage, sessions]
  );
  const totalPages = useMemo(
    () => Math.ceil(sessions.length / PER_PAGE),
    [sessions.length]
  );

  if (!sessions || sessions.length === 0) {
    return null;
  }

  return (
    <List>
      <Header>
        Upcoming Sessions
        {totalPages > 1 && (
          <Pagination
            boundaryRange={0}
            defaultActivePage={currentPage}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={0}
            totalPages={totalPages}
            onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
          />
        )}
      </Header>
      {displaySessions.map((session) => (
        <SessionItem
          key={session.uid}
          onClick={() => onSetSessionId(session.uid)}>
          <span className="session-date">
            <span className="day">
              {moment(parseInt(session.block.dateUTC, 10)).format('DD')}
            </span>
            {moment(parseInt(session.block.dateUTC, 10)).format('MMMM')}
          </span>
          <span>{session?.profile?.name}</span>
          <span>{moment(session.block.start.toDate()).format('h:mm a')}</span>
          <span>{`${session.duration || 0}min`}</span>
          <span>
            <Icon name="info circle" />
          </span>
        </SessionItem>
      ))}
    </List>
  );
};

export default UpcomingSessions;
