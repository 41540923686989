import React, { FC } from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import { ButtonProps } from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import { ModalProps } from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import ErrorBoundaryContainer from '../ErrorBoundaryContainer';

interface Props {
  showConfirmModal: boolean;
  title: string;
  loading?: boolean;
  onClose?: (event: React.MouseEvent<HTMLElement>, data: ModalProps) => void;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    data: ButtonProps
  ) => void;
}

const ConfirmModal: FC<Props> = ({
  showConfirmModal,
  title,
  loading,
  onClose,
  onClick,
}) => (
  <ErrorBoundaryContainer>
    <Modal size="tiny" open={showConfirmModal} onClose={onClose}>
      <Modal.Content>
        <Header>{title}</Header>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={(event) => {
            if (onClose) onClose(event, {});
          }}
          negative>
          No
        </Button>
        <Button
          onClick={onClick}
          positive
          labelPosition="right"
          icon="checkmark"
          content="Yes"
          loading={loading}
          disabled={loading}
        />
      </Modal.Actions>
    </Modal>
  </ErrorBoundaryContainer>
);

export default ConfirmModal;
