import * as Sentry from '@sentry/browser';
import { OTSession, OTStreams } from 'opentok-react';
import React, { useEffect, useState } from 'react';
import { Rnd } from 'react-rnd';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { Pane } from '../../../components/layout';
import { ConfirmModal } from '../../../components/ui';
import { roleSelector } from '../../../redux/auth/auth.selectors';
import { setFeedbackSession } from '../../../redux/sessionFeedback/sessionFeedback.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  getSession,
  updateSessionStatus,
} from '../../../redux/video/video.saga';
import {
  activeSessionSelector,
  videoStatusSelector,
} from '../../../redux/video/video.selectors';
import { colors } from '../../../theme';
import Footer from './Footer';
import { Publisher, SessionContainer, Subscriber } from './styles';

const { REACT_APP_OPENTOK_API_KEY } = process.env;

const Session: React.FC<RouteComponentProps<{ sessionId: string }>> = ({
  match,
}) => {
  const role = useAppSelector(roleSelector);
  const status = useAppSelector(videoStatusSelector);
  const session = useAppSelector(activeSessionSelector);
  const dispatch = useAppDispatch();
  const isCoach = role === 'coach';
  const { sessionId } = match.params;
  const loading = status === 'loading';
  const [overlayMessage, setOverlayMessage] = useState<string | null>(
    isCoach
      ? 'Ready to start the session?'
      : 'Waiting for your coach to start the session.'
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [properties, setProperties] = useState({
    showControls: false,
    publishAudio: true,
    publishVideo: true,
  });

  const captureError = (data: any) => {
    Sentry.captureEvent({
      message: 'OpenTok Error',
      extra: data,
    });
  };

  const eventHandlers = {
    error: (data: any) => captureError(data),
    otrnError: (data: any) => captureError(data),
  };

  const subscriberEventHandlers = {
    connected: () => setOverlayMessage(null),
    destroyed: () => setOverlayMessage('Disconnected'),
    disconnected: () => setOverlayMessage('Disconnected'),
  };

  useEffect(() => {
    dispatch(getSession.action(sessionId));
  }, [dispatch, sessionId]);

  useEffect(() => {
    if (session?.status === 'STARTED') {
      setOverlayMessage(
        `Waiting for your ${isCoach ? 'client' : 'coach'} to join the session`
      );
    } else if (session?.status === 'ENDED') {
      setShowConfirmModal(false);
      setOverlayMessage('Your session has ended');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCoach, session?.status]);

  useEffect(() => {
    const onUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      const returnValue = 'Are you sure you want to leave?';
      event.returnValue = returnValue;
      return returnValue;
    };

    window.addEventListener('beforeunload', onUnload);

    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);

  const onStartSession = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(updateSessionStatus.action({ sessionId, status: 'STARTED' }));
  };

  const onEndSession = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(updateSessionStatus.action({ sessionId, status: 'ENDED' }));
  };

  const onToggle = (flag: 'showControls' | 'publishAudio' | 'publishVideo') => {
    setProperties({
      ...properties,
      [flag]: !properties[flag],
    });
  };

  const onEndCall = () => {
    if (isCoach) {
      setShowConfirmModal(true);
    } else {
      if (session?.status !== 'IDLE') dispatch(setFeedbackSession(session));
      window.close();
    }
  };

  return (
    <SessionContainer
      display="flex"
      flexDirection="column"
      height="100vh"
      width="100vw">
      <Pane display="flex" flexDirection="column">
        {session && overlayMessage && (
          <Pane
            margin={20}
            alignItems="center"
            backgroundColor={colors.lightWhiteSmoke}
            padding={16}
            display="flex"
            borderRadius={5}>
            <Pane flex={1}>{overlayMessage}</Pane>
            {isCoach && session?.status !== 'STARTED' && (
              <Button
                primary
                onClick={onStartSession}
                loading={loading}
                disabled={loading}>
                Start Session
              </Button>
            )}
          </Pane>
        )}
        {session?.openTok && (
          <OTSession
            eventHandlers={eventHandlers}
            apiKey={REACT_APP_OPENTOK_API_KEY!}
            sessionId={session?.openTok.sessionId}
            token={session?.openTok.coachToken}>
            <Rnd
              bounds="window"
              style={{ zIndex: 999 }}
              default={{
                width: 120,
                height: 160,
                x: 450,
                y: 20,
              }}>
              <Publisher properties={properties} />
            </Rnd>
            {session?.status === 'STARTED' && (
              <OTStreams>
                <Subscriber eventHandlers={subscriberEventHandlers} />
              </OTStreams>
            )}
          </OTSession>
        )}
      </Pane>
      <Footer
        properties={properties}
        onEndCall={onEndCall}
        onToggleAudio={() => onToggle('publishAudio')}
        onToggleVideo={() => onToggle('publishVideo')}
      />
      <ConfirmModal
        showConfirmModal={showConfirmModal}
        title={`Do you want to ${isCoach ? 'end' : 'leave'} this session?`}
        loading={loading}
        onClose={() => setShowConfirmModal(false)}
        onClick={onEndSession}
      />
    </SessionContainer>
  );
};

export default Session;
