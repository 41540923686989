import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '../../../theme';

// eslint-disable-next-line import/prefer-default-export
export const CustomLink = styled(Link)`
  color: ${colors.bittersweet};
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
