import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { logout } from '../auth/auth.saga';
import { NotificationsState } from '../store/types';
import {
  getNotifications,
  getNotificationSettings,
  readNotification,
  updateNotificationSettings,
} from './notifications.saga';

const initialState = {
  status: 'pending',
  notifications: [],
  settings: {
    SESSION_SCHEDULED: true,
    SESSION_STARTED: true,
    SESSION_CANCELLED: true,
    SESSION_REMINDER: true,
    CHAT_RECEIVED: true,
  },
} as NotificationsState;

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
    }));
    builder.addCase(getNotifications.fulfilled, (state, action: any) => {
      state.status = 'success';
      state.notifications = action.payload;
    });
    builder.addCase(getNotificationSettings.fulfilled, (state, action) => {
      state.status = 'success';
      state.settings = action.payload;
    });
    builder.addMatcher(
      isAnyOf(
        getNotifications.pending,
        getNotificationSettings.pending,
        updateNotificationSettings.pending,
        readNotification.pending
      ),
      (state) => {
        state.status = 'loading';
      }
    );
    builder.addMatcher(
      isAnyOf(updateNotificationSettings.fulfilled, readNotification.fulfilled),
      (state) => {
        state.status = 'success';
      }
    );
    builder.addMatcher(
      isAnyOf(
        getNotifications.rejected,
        getNotificationSettings.rejected,
        updateNotificationSettings.rejected,
        readNotification.rejected
      ),
      (state) => {
        state.status = 'failed';
      }
    );
  },
});

export default notificationsSlice.reducer;
