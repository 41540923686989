import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { authSelector } from '../redux/auth/auth.selectors';
import { useAppSelector } from '../redux/store';
import {
  isProfileCompleteSelector,
  userSelector,
} from '../redux/user/user.selectors';

type OnboardingRouteProps = RouteProps & {
  component: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentProps?: any;
};

const OnboardingRoute: React.FC<OnboardingRouteProps> = ({
  component: Component,
  componentProps,
  ...rest
}) => {
  const location = useLocation();
  const auth = useAppSelector(authSelector);
  const user = useAppSelector(userSelector);
  const isProfileComplete = useAppSelector(isProfileCompleteSelector);

  if (
    auth?.status === 'pending' ||
    (auth.status === 'success' && user?.status === 'pending')
  )
    return (
      <Dimmer active inverted>
        <Loader active size="big" inline="centered">
          Loading
        </Loader>
      </Dimmer>
    );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth?.status === 'success' && !isProfileComplete) {
          return <Component {...{ ...props, ...componentProps }} />;
        }

        return <Redirect to={{ pathname: '/', state: { from: location } }} />;
      }}
    />
  );
};

export default OnboardingRoute;
