import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import Forgot from '../pages/auth/Forgot';
import Login from '../pages/auth/Login';
import SignUp from '../pages/auth/SignUp';
import NotFound from '../pages/shared/NotFound';
import { logout, verifyRole } from '../redux/auth/auth.saga';
import { authSelector, roleSelector } from '../redux/auth/auth.selectors';
import { getUserCoach } from '../redux/coaches/coaches.saga';
import { coachSelector } from '../redux/coaches/coaches.selectors';
import { useAppDispatch, useAppSelector } from '../redux/store';
import {
  getStripeCustomer,
  getSubscription,
} from '../redux/subscription/subscription.saga';
import { stripeIdSelector } from '../redux/subscription/subscription.selectors';
import { userSelector } from '../redux/user/user.selectors';
import { onAuthStateChanged } from '../services/firebase';
import AppRoute from './AppRoute';
import AuthRoute from './AuthRoute';
import CoachRoutes from './CoachRoutes';
import OnboardingRoutes from './OnboardingRoutes';
import SharedRoutes from './SharedRoutes';
import UserRoutes from './UserRoutes';

const Routes: React.FC = () => {
  const [ready, setReady] = React.useState(false);
  const auth = useAppSelector(authSelector);
  const user = useAppSelector(userSelector);
  const role = useAppSelector(roleSelector);
  const coach = useAppSelector(coachSelector);
  const stripeId = useAppSelector(stripeIdSelector);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (auth.status === 'pending' && !ready) {
      onAuthStateChanged(
        () => dispatch(verifyRole.action()),
        () => dispatch(logout.action())
      );
    }
  }, [auth, ready, dispatch]);

  React.useEffect(() => {
    if (!ready) {
      if (auth.status === 'failed') {
        setReady(true);
      } else if (stripeId) {
        dispatch(getStripeCustomer.action(stripeId));
        setReady(true);
      }
    }
  }, [auth.status, dispatch, ready, stripeId, user.status]);

  React.useEffect(() => {
    if (coach.uid && role === 'user') {
      dispatch(getUserCoach.action(coach.uid));
    }
  }, [coach.uid, dispatch, role]);

  React.useEffect(() => {
    if (auth.uid && role === 'user') {
      dispatch(getSubscription.action());
    }
  }, [auth.uid, coach.uid, dispatch, role]);

  if (auth.status === 'pending') {
    return (
      <Dimmer active inverted>
        <Loader active size="big" inline="centered">
          Loading
        </Loader>
      </Dimmer>
    );
  }

  return (
    <Switch>
      <AuthRoute exact path="/login" component={Login} />
      <AuthRoute exact path="/signup" component={SignUp} />
      <AuthRoute exact path="/forgot" component={Forgot} />
      <AppRoute
        exact
        path="/"
        componentProps={{ to: `/${role}/dashboard` }}
        component={Redirect}
      />
      <Route path="/onboarding" component={OnboardingRoutes} />
      <Route path="/user" component={UserRoutes} />
      <Route path="/coach" component={CoachRoutes} />
      <Route path="/info" component={SharedRoutes} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
