import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { AuthState } from '../store/types';
import {
  loginWithEmail,
  logout,
  resetPassword,
  signUp,
  verifyRole,
} from './auth.saga';

const initialState = {
  status: 'pending',
  error: null,
  token: null,
  uid: null,
  role: null,
  email: null,
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
      status: 'failed',
    }));
    builder.addCase(verifyRole.fulfilled, (state, action) => {
      state.status = 'success';
      state.token = action.payload.token;
      state.uid = action.payload.uid;
      state.email = action.payload.email;
      state.role = state.role || action.payload.role;
    });
    builder.addCase(loginWithEmail.fulfilled, (state, action) => {
      state.status = 'success';
      state.token = action.payload.token;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.status = 'success';
    });
    builder.addCase(signUp.action, (state, action) => {
      state.status = 'loading';
      state.tempRole = action.payload.role;
    });
    builder.addCase(signUp.fulfilled, (state) => {
      state.status = 'success';
    });
    builder.addMatcher(
      isAnyOf(loginWithEmail.pending, resetPassword.pending),
      (state) => {
        state.status = 'loading';
      }
    );
    builder.addMatcher(
      isAnyOf(
        signUp.rejected,
        loginWithEmail.rejected,
        verifyRole.rejected,
        resetPassword.rejected
      ),
      (state) => {
        state.status = 'failed';
      }
    );
  },
});

export default authSlice.reducer;
