/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { logout } from '../auth/auth.saga';
import { SubscriptionState } from '../store/types';
import { getUser } from '../user/user.saga';
import {
  addCard,
  getStripeCustomer,
  getSubscription,
  purchaseSubscription,
  validateCouponCode,
} from './subscription.saga';
import { findActiveSub, parseCard } from './subscription.utils';

const initialState = {
  status: 'pending',
  coupon: null,
  error: null,
  selectedSubscription: null,
  selectedSubscriptionLevel: null,
  activeSubscription: null,
  credits: 0,
  chat: false,
  video: false,
  duration: 0,
  defaultCard: null,
  last4: null,
  expiryDate: null,
  purchaseSubscriptionStatus: 'pending',
} as SubscriptionState;

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    selectSubscription: (state, action: PayloadAction<string>) => {
      state.selectedSubscription = action.payload;
    },
    selectSubscriptionLevel: (state, action: PayloadAction<string>) => {
      state.selectedSubscriptionLevel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action: any) => {
      state.stripeId = action.payload.stripeId;
    });
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
    }));
    builder.addCase(getSubscription.fulfilled, (state, action: any) => {
      const { credits, ...subscription } = action.payload;
      const activeSubscription =
        findActiveSub(subscription) || state.activeSubscription;
      const pendingSubscription =
        action.payload?.stripe?.subscriptions?.pendingSubscription ?? null;

      return {
        ...state,
        ...subscription,
        credits,
        activeSubscription,
        pendingSubscription,
        status: 'success',
      };
    });
    builder.addCase(getStripeCustomer.fulfilled, (state, action) => {
      const { id, default_source, sources, subscriptions } = action.payload;

      // const activeSubscription =
      //   (subscriptions && subscriptions.data && subscriptions.data[0]) || null;

      const { last4, expiryDate } = parseCard(default_source, sources.data);

      return {
        ...state,
        status: 'success',
        stripeId: id,
        subscriptions: subscriptions.data,
        defaultCard: default_source,
        last4: last4 || null,
        expiryDate: expiryDate || null,
        sources: sources.data,
      };
    });
    builder.addCase(purchaseSubscription.pending, (state) => {
      state.purchaseSubscriptionStatus = 'loading';
    });
    builder.addCase(purchaseSubscription.rejected, (state) => {
      state.purchaseSubscriptionStatus = 'failed';
    });
    builder.addCase(purchaseSubscription.fulfilled, (state) => {
      state.purchaseSubscriptionStatus = 'success';
    });
    builder.addCase(validateCouponCode.fulfilled, (state, action) => {
      state.status = 'success';
      state.coupon = action.payload;
    });
    builder.addMatcher(
      isAnyOf(
        getSubscription.pending,
        getStripeCustomer.pending,
        addCard.pending,
        validateCouponCode.pending
      ),
      (state) => {
        state.status = 'loading';
      }
    );
    builder.addMatcher(isAnyOf(addCard.fulfilled), (state) => {
      state.status = 'success';
    });
    builder.addMatcher(
      isAnyOf(
        getSubscription.rejected,
        getStripeCustomer.rejected,
        addCard.rejected,
        validateCouponCode.rejected
      ),
      (state) => {
        state.status = 'failed';
      }
    );
  },
});

export const { selectSubscription, selectSubscriptionLevel } =
  subscriptionSlice.actions;

export default subscriptionSlice.reducer;
