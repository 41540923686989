import styled from 'styled-components';
import { colors } from '../../../../theme';

// eslint-disable-next-line import/prefer-default-export
export const CancelLink = styled.a`
  color: ${colors.oldLavender};
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    ${'' /* text-decoration: underline; */}
  }
`;
