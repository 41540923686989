import React, { FC, useCallback, useEffect, useState } from 'react';
import { Checkbox, Header, Message } from 'semantic-ui-react';
import moment from 'moment';
import Swal from 'sweetalert2';

import {
  Adjustment,
  DayDivider,
  SessionCalendar,
  SessionTime,
  SubmitButton,
} from './styles';

import {
  filterBlocksByDate,
  getBlocksWithDisplay,
  getRemainingDays,
  getUnavailableDates,
} from '../../../utils/formatting/schedules';
import ErrorBoundaryContainer from '../ErrorBoundaryContainer';

interface Props {
  status: 'pending' | 'loading' | 'success' | 'failed';
  sessions: Sessions;
  blocks?: FormattedBlock[];
  onSave: (data: any) => void;
}

const ScheduleAdjustment: FC<Props> = ({
  status,
  sessions,
  blocks,
  onSave,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState<
    string | number | undefined | null
  >();

  const sessionCalendarData = sessions.map(({ block }) => block.start.toDate());
  const remainingDays = getRemainingDays();
  const unavailableDates = getUnavailableDates(blocks, remainingDays);
  const filteredBlocks = filterBlocksByDate(blocks, selectedDate);
  const timeBlocks: SessionBlock[] = getBlocksWithDisplay(filteredBlocks);

  const handleClickDay = useCallback((day) => {
    console.log(day);
    setSelectedDate(day);
    setSelectedTime(null);
  }, []);

  useEffect(() => {
    if (status !== 'loading' && selectedDate && !timeBlocks.length) {
      handleClickDay(null);
      if (selectedTime) {
        Swal.fire({
          icon: 'error',
          title: 'Sorry, the selected date is no longer available',
        });
      }
    }
  }, [handleClickDay, selectedDate, selectedTime, status, timeBlocks.length]);

  useEffect(() => {
    if (selectedTime) {
      const selectedBlock = timeBlocks.find(
        (block) => block.startDisplayTime === selectedTime
      );

      if (status !== 'loading' && !selectedBlock) {
        setSelectedTime(null);
        Swal.fire({
          icon: 'error',
          title: 'Sorry, the selected time is no longer available',
        });
      }
    }
  }, [selectedTime, status, timeBlocks]);

  const handleClick = useCallback(() => {
    if (selectedTime) {
      const selectedBlock = timeBlocks.find(
        (block) => block.startDisplayTime === selectedTime
      );
      const data = {
        block: selectedBlock,
        date: moment(selectedDate),
        time: selectedTime,
      };
      onSave(data);
    }
  }, [timeBlocks, selectedDate, selectedTime, onSave]);

  return (
    <ErrorBoundaryContainer>
      <Adjustment>
        <div>
          <Header as="h1">Schedule a session</Header>
          <Message>
            <p>*All times are local.</p>
          </Message>
          <div className="calendar-panel">
            <SessionCalendar
              calendarType="US"
              tileClassName={({ date }) =>
                sessionCalendarData.some((d) => moment(d).isSame(date, 'day'))
                  ? 'session-day'
                  : null
              }
              tileDisabled={({ date }) =>
                moment(date).diff(moment(), 'days') >= 30 ||
                unavailableDates.includes(moment(date).format('YYYY-MM-DD'))
              }
              tileContent={({ date }) =>
                unavailableDates.includes(moment(date).format('YYYY-MM-DD')) ? (
                  <DayDivider />
                ) : null
              }
              formatShortWeekday={(_, date) => moment(date).format('dd')}
              onClickDay={(value) => handleClickDay(value)}
              minDate={new Date()}
            />
          </div>
          {selectedTime && (
            <SubmitButton loading={status === 'loading'} onClick={handleClick}>
              Confirm
            </SubmitButton>
          )}
        </div>
        {selectedDate && (
          <SessionTime>
            <Header as="h3">Select Time</Header>
            <span className="selected-date">
              Selected date: {moment(selectedDate).format('DD MMMM')}
            </span>
            <div className="time-group">
              {timeBlocks.map((timeBlock) => (
                <div className="time" key={timeBlock.startDisplayTime}>
                  <label htmlFor={timeBlock.startDisplayTime}>
                    {timeBlock.startDisplayTime}
                  </label>
                  <Checkbox
                    id={timeBlock.startDisplayTime}
                    checked={selectedTime === timeBlock.startDisplayTime}
                    onChange={(e, { id, checked }) =>
                      checked ? setSelectedTime(id) : setSelectedTime(null)
                    }
                  />
                </div>
              ))}
            </div>
          </SessionTime>
        )}
      </Adjustment>
    </ErrorBoundaryContainer>
  );
};

export default ScheduleAdjustment;
