import styled from 'styled-components';

import { colors } from '../../../theme';

import { MyCoachSticky } from '../MyCoach/styles';

export const TipSticky = styled(MyCoachSticky)`
  background: ${colors.charcoal};
  .tip {
    display: block;
    text-align: center;
    padding: 60px;
    font-size: 20px;
    font-weight: 600;
    color: ${colors.white};
  }
`;
