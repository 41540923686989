import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import ClientCard from '../ClientCard';
import { Pane } from '../../layout';
import { Client } from '../../../types/client';
import ErrorBoundaryContainer from '../ErrorBoundaryContainer';

interface Props {
  client: Client;
  sessions: Sessions;
  notes?: { userUid: string; id: string; message: string }[];
}

const ClientDetails: FC<Props> = ({ client, sessions, notes = [] }) => {
  const history = useHistory();
  const onOpenChat = (uid: string) => {
    history.push({ pathname: '/coach/chat', search: `?uid=${uid}` });
  };
  const selectedSessions = client
    ? sessions.filter((session) => session.userUid === client.profile.uid)
    : [];
  const selectedNotes = client
    ? (notes || []).filter((note) => note.userUid === client.profile.uid)
    : [];

  return (
    <ErrorBoundaryContainer>
      <Pane width="75%" paddingTop={50}>
        <ClientCard
          client={client}
          sessions={selectedSessions}
          onOpenChat={onOpenChat}
        />
        {client.interests && client.interests.length > 0 && (
          <Table singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Interests</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {client.interests.map((interest) => (
                <Table.Row key={interest}>
                  <Table.Cell>{interest}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Upcoming Sessions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {selectedSessions.length > 0 &&
              selectedSessions.map((session) => (
                <Table.Row key={session.uid}>
                  <Table.Cell>
                    {`${moment(session.block.start.toDate()).format(
                      'L @ h:mm a'
                    )}`}
                  </Table.Cell>
                </Table.Row>
              ))}
            {selectedSessions.length === 0 && (
              <Table.Row>
                <Table.Cell>No sessions</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Session Notes</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {selectedNotes.length > 0 &&
              selectedNotes.map((note) => (
                <Table.Row key={note.id}>
                  <Table.Cell>{note.message}</Table.Cell>
                </Table.Row>
              ))}
            {selectedNotes.length === 0 && (
              <Table.Row>
                <Table.Cell>No notes</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Pane>
    </ErrorBoundaryContainer>
  );
};

export default ClientDetails;
