import React, { useCallback, useEffect, useMemo } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { AppLayout, DashboardLayout } from '../../../components/layout';
import ScheduleAdjustment from '../../../components/ui/ScheduleAdjustment';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { roleSelector } from '../../../redux/auth/auth.selectors';
import { coachSelector } from '../../../redux/coaches/coaches.selectors';
import { subscriptionSelector } from '../../../redux/subscription/subscription.selectors';
import {
  allUpcomingSessionsSelector,
  sessionsSelector,
  videoSelector,
} from '../../../redux/video/video.selectors';
import {
  createSession as createSessionAction,
  getCoachAvailabilityBlocks as getCoachAvailabilityBlocksAction,
  getSessions as getSessionsAction,
} from '../../../redux/video/video.saga';

const ScheduleSession = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(roleSelector);
  const coach = useAppSelector(coachSelector);
  const sessions = useAppSelector(sessionsSelector);
  const allUpcomingSessions = useAppSelector(allUpcomingSessionsSelector);
  const video = useAppSelector(videoSelector);
  const subscription = useAppSelector(subscriptionSelector);

  const sessionDuration = useMemo(() => subscription?.duration, [subscription]);
  const availableBlocks = useMemo(() => video?.blocks, [video]);
  const sessionStatus = useMemo(() => video?.bookingStatus, [video]);

  const getSessions = useCallback(() => {
    dispatch(getSessionsAction.action());
  }, [dispatch]);

  const getCoachAvailabilityBlocks = useCallback(
    (args: { uid: string; duration: number }) => {
      dispatch(getCoachAvailabilityBlocksAction.action(args));
    },
    [dispatch]
  );

  const createSession = useCallback(
    (args: {
      date: string;
      time: string;
      coach: string;
      block: any;
      duration: SessionDuration;
    }) => {
      dispatch(createSessionAction.action(args));
    },
    [dispatch]
  );

  useEffect(() => {
    getSessions();
  }, [role, getSessions]);

  useEffect(() => {
    getCoachAvailabilityBlocks({
      uid: coach.profile.uid,
      duration: sessionDuration,
    });
  }, [coach, getCoachAvailabilityBlocks, sessionDuration]);

  const activeSessions = useMemo(
    () =>
      [...sessions]
        .sort(
          (a, b) =>
            parseInt(a.block.dateUTC, 10) - parseInt(b.block.dateUTC, 10)
        )
        .map((session) => ({ ...session, profile: coach.profile })),
    [coach.profile, sessions]
  );

  const onSave = useCallback(
    (data) => {
      createSession({
        coach: coach.profile.uid,
        duration: sessionDuration,
        ...data,
      });
    },
    [coach.profile.uid, createSession, sessionDuration]
  );

  return (
    <AppLayout page="dashboard">
      <DashboardLayout
        role={role}
        coach={coach}
        sessions={activeSessions}
        upcomingSessions={allUpcomingSessions}
        onSetSessionId={() => null}>
        {availableBlocks && sessions && (
          <ScheduleAdjustment
            status={sessionStatus}
            sessions={sessions}
            blocks={availableBlocks}
            onSave={onSave}
          />
        )}
        <Dimmer active={sessionStatus === 'loading'} inverted>
          <Loader />
        </Dimmer>
      </DashboardLayout>
    </AppLayout>
  );
};

export default ScheduleSession;
