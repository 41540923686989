import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '../store';

// eslint-disable-next-line import/prefer-default-export
export const availabilitySelector = (state: ApplicationState) =>
  state.availability;

export const availabilityRangesSelector = createSelector(
  availabilitySelector,
  (availability) => availability.ranges
);
