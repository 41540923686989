import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Header } from 'semantic-ui-react';
import { AppLayout, Pane, SettingsLayout } from '../../../../components/layout';
import { SettingMenuItem } from '../../../../components/layout/SettingsLayout/SettingsMenu';
import { getUrlPrefix } from '../../../../config/firebase';
import { COACH_TABS } from '../../../../constants/tabs';
import { useAppSelector } from '../../../../redux/store';
import { userSelector } from '../../../../redux/user/user.selectors';

const Payment = () => {
  const history = useHistory();
  const user = useAppSelector(userSelector);

  const handleNavigationChange = (item: SettingMenuItem) => {
    history.push(item.name);
  };

  return (
    <AppLayout page="settings">
      <SettingsLayout
        items={COACH_TABS}
        selectedItem={{ name: 'payment' }}
        onSelectItem={handleNavigationChange}>
        <Pane>
          <Header>Update Payment</Header>
          <Pane height={25} />
          {user.connect?.stripe_user_id ? (
            <Button
              primary
              fluid
              href={`https://${getUrlPrefix()}.cloudfunctions.net/connect/dashboard/${
                user.connect.stripe_user_id
              }`}
              target="_blank">
              Open Stripe Dashboard
            </Button>
          ) : (
            <Button
              primary
              fluid
              href={`https://${getUrlPrefix()}.cloudfunctions.net/connect/redirect/${
                user.profile.uid
              }`}
              target="_blank">
              Connect with Stripe
            </Button>
          )}
        </Pane>
      </SettingsLayout>
    </AppLayout>
  );
};

export default Payment;
