import React, { FC } from 'react';
import { BoxOwnProps } from 'ui-box';
import { CustomBox } from './styles';

interface Props extends BoxOwnProps {
  className?: string;
  onClick?: () => void;
}

export const Pane: FC<Props> = (props) => <CustomBox {...props} />;

export default Pane;
