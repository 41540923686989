/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useRef, useState } from 'react';
import { Button, Form, Icon, Image } from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { ChatSender, SendButton } from '../ChatFeed/styles';

interface Props {
  status: string;
  recipientID?: string;
  disabled?: boolean;
  onSubmit: (params: {
    text: string;
    recipientID?: string;
    file: Blob | Uint8Array | ArrayBuffer;
  }) => void;
}

const ChatForm: FC<Props> = ({ status, recipientID, disabled, onSubmit }) => {
  const formRef = useRef<any>();
  const [file, setFile] = useState<any>(null);
  const { register, handleSubmit, setFocus, reset } = useForm<any>();

  const { getInputProps, getRootProps, open, isDragActive } = useDropzone({
    accept: 'image/*',
    noClick: true,
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      if (acceptedFiles.length) {
        setFile(
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
          })
        );
      }
    },
  });

  const onFormSubmit = async ({ text = '' }, e: any) => {
    if (e) {
      e.preventDefault();
    }

    reset();

    setFocus('text');
    setFile(null);

    if (!text.trim() && !file) return;

    onSubmit({ file, recipientID, text });
  };

  const onEnterPress = (e: any) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(onFormSubmit)();
    }
  };

  return (
    <ChatSender {...getRootProps()}>
      <form
        ref={formRef}
        className="ui reply form"
        onSubmit={handleSubmit(onFormSubmit)}>
        <div className="upload-attachment">
          <Button
            disabled={disabled}
            loading={status === 'uploading'}
            icon
            onClick={(e) => {
              e.preventDefault();
              open();
            }}>
            <Icon name="file image" />
          </Button>
        </div>
        <Form.Field>
          <input {...getInputProps()} />
          {file && (
            <div
              className="ui image"
              style={{ maxWidth: 100, marginBottom: 5 }}>
              <Image rounded size="small" src={file?.preview} />
              <Icon
                link
                onClick={() => setFile(null)}
                style={{ position: 'absolute', top: -5, right: -10 }}
                size="small"
                color="red"
                inverted
                circular
                name="close"
              />
            </div>
          )}
          <textarea
            rows={1}
            disabled={disabled}
            onKeyDown={onEnterPress}
            placeholder={
              isDragActive ? 'Drop to attach file' : `Type a message here`
            }
            {...register('text')}
          />
        </Form.Field>
        <SendButton disabled={disabled} type="submit" icon="send" />
      </form>
    </ChatSender>
  );
};

export default ChatForm;
