import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Dimmer, Loader, Menu, Modal, Tab } from 'semantic-ui-react';
import { ChatFeed } from '../../../../components/ui';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { roleSelector } from '../../../../redux/auth/auth.selectors';
import { chatSelector } from '../../../../redux/chat/chat.selectors';
import { previousCoachesSelector } from '../../../../redux/coaches/coaches.selectors';
import { getUserArchivedChat } from '../../../../redux/chat/chat.saga';

const ArchiveModal: FC = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(roleSelector);
  const chat = useAppSelector(chatSelector);
  const previousCoaches = useAppSelector(previousCoachesSelector);

  const getUserArchivedChatAction = useCallback(
    (params: { recipientID: string; role: Role }) => {
      dispatch(getUserArchivedChat.action(params));
    },
    [dispatch]
  );

  const [visible, setVisible] = useState(false);
  const [coachIndex, setCoachIndex] = useState(0);
  const { status, archivedRooms = {} } = chat;

  useEffect(() => {
    if (visible) {
      getUserArchivedChatAction({
        recipientID: previousCoaches[coachIndex].uid,
        role,
      });
    }
  }, [
    coachIndex,
    getUserArchivedChatAction,
    previousCoaches,
    previousCoaches.length,
    role,
    visible,
  ]);

  const onTabChange = useCallback((e, { activeIndex }) => {
    setCoachIndex(activeIndex);
  }, []);

  const panes = useMemo(
    () =>
      previousCoaches.map((coach) => {
        const room = archivedRooms[coach.uid] || {};
        return {
          menuItem: (
            <Menu.Item key={coach.uid}>
              {coach && coach.profile ? coach.profile.name : ''}
            </Menu.Item>
          ),
          render: () => (
            <Dimmer.Dimmable blurring dimmed={status === 'loading'}>
              <Dimmer inverted active={status === 'loading'}>
                <Loader />
              </Dimmer>
              <ChatFeed chats={room.messages} archived />
            </Dimmer.Dimmable>
          ),
        };
      }),
    [previousCoaches, archivedRooms, status]
  );

  return (
    <Modal
      size="large"
      open={visible}
      onClose={() => setVisible(false)}
      trigger={<Button icon="archive" onClick={() => setVisible(true)} />}
      closeIcon>
      <Modal.Header>Archived Chats</Modal.Header>
      <Modal.Content>
        <Tab
          menu={{ fluid: true, vertical: true, tabular: 'right' }}
          activeIndex={coachIndex}
          onTabChange={onTabChange}
          panes={panes}
        />
      </Modal.Content>
    </Modal>
  );
};

export default ArchiveModal;
