import React from 'react';
import { Form } from 'semantic-ui-react';
import { Pane, WizardLayout } from '../../../components/layout';
import {
  WizContent,
  WizSubtitle,
  WizTitle,
} from '../../../components/layout/WizardLayout/styles';
import { Link } from '../../../components/ui';
import { signUp } from '../../../redux/auth/auth.saga';
import { authSelector } from '../../../redux/auth/auth.selectors';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { SignUpData } from '../../../services/firebase';
import SignUpForm from './SignUpForm';

const SignUp: React.FC = () => {
  const auth = useAppSelector(authSelector);
  const dispatch = useAppDispatch();

  const onSubmit = (values: Omit<SignUpData, 'role'>) => {
    const data = {
      ...values,
      role: 'user',
    } as SignUpData;
    dispatch(signUp.action(data));
  };
  return (
    <WizardLayout sideBanner="onboarding_img_1">
      <WizContent textAlign="left">
        <WizTitle>Welcome to Blush!</WizTitle>
        <WizSubtitle>
          Thousands of people just like you use Blush to help them thrive
        </WizSubtitle>
        <Pane maxWidth="40rem">
          <SignUpForm
            loading={auth.status === 'loading' || !!auth.token}
            onSubmit={onSubmit}
          />
        </Pane>
        <Form.Field style={{ marginTop: 16, textAlign: 'right', fontSize: 14 }}>
          Already have an account?&nbsp;
          <Link to="/login">Log in</Link>
        </Form.Field>
      </WizContent>
    </WizardLayout>
  );
};

export default SignUp;
