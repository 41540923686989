import React, { FC, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from 'semantic-ui-react';

interface Props {
  children: JSX.Element;
  onReset?: (...args: Array<unknown>) => void;
}

const ErrorBoundaryContainer: FC<Props> = ({ children, onReset }) => {
  const errorMessage = useCallback(
    ({ error, resetErrorBoundary }) => (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <Button onClick={resetErrorBoundary}>Try again</Button>
      </div>
    ),
    []
  );

  return (
    <ErrorBoundary FallbackComponent={errorMessage} onReset={onReset}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryContainer;
