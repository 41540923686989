import styled, { keyframes } from 'styled-components';
import Calendar from 'react-calendar';
import { Button } from 'semantic-ui-react';
import 'react-calendar/dist/Calendar.css';

import { colors, metrics } from '../../../theme';

export const SessionCalendar = styled(Calendar)`
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 20px;
  align-self: flex-end;
  border: none;
  font-size: 16px;
  .react-calendar__tile {
    display: block;
    width: 44px;
    height: 44px;
    border-radius: 50%;
  }
  .react-calendar__tile--active {
    background: ${colors.roseWhite};
    color: ${colors.black};
    &:focus {
      background: ${colors.roseWhite};
    }
  }
  .react-calendar__tile:disabled {
    background-color: unset;
  }
  .react-calendar__month-view__weekdays__weekday {
    font-size: 16px;
    abbr {
      text-decoration: none;
    }
  }
  .react-calendar__navigation button[disabled] {
    background-color: unset;
  }
  .react-calendar__month-view__days__day--weekend[disabled] {
    color: ${colors.silver};
  }
  .react-calendar__tile--now {
    color: ${colors.bittersweet} !important;
    background-color: ${colors.white};
  }
  .react-calendar__month-view__days__day--weekend {
    color: inherit;
  }
  .session-day:not(:disabled) {
    background-color: ${colors.roseWhite};
  }
`;

const zoomIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const SessionTime = styled.div`
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 28px;
  animation: ${zoomIn} 800ms;
  @media (min-width: ${metrics.device_size.tablet}) {
    margin-left: 28px;
    margin-right: 28px;
    margin-top: 0;
  }
  @media (min-width: ${metrics.device_size.mobile_s}) and (max-width: ${metrics
      .device_size.tablet}) {
    margin-right: 0;
  }

  .header {
    margin-bottom: 4px;
  }

  .selected-date {
    font-size: 14px;
    color: ${colors.bittersweet};
  }

  .time-group {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    max-height: 394px;
    overflow-x: auto;
  }

  .time-group .time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${colors.lavender};
    background: ${colors.white};
    padding: 8px 16px;
    margin-bottom: 8px;
    margin-right: 12px;
    border-radius: 4px;
  }

  .time-group .time label {
    flex: 1;
    cursor: pointer;
  }

  .time-group .checkbox input:checked ~ label:after {
    color: ${colors.bittersweet};
  }

  .time-group .checkbox input:checked ~ label:before {
    color: ${colors.bittersweet};
  }
`;

export const SubmitButton = styled(Button)`
  && {
    display: flex;
    align-self: flex-end;
    border-radius: 0;
    background: ${colors.geraldine};
    color: ${colors.white};
    font-size: 15px;
    margin: 12px 0 12px auto;
    animation: ${zoomIn} 800ms;
  }
`;

export const DayDivider = styled.div`
  width: 52%;
  height: 1px;
  background-color: ${colors.silver};
  margin: 0 auto;
  position: relative;
  bottom: 9px;
`;

export const Adjustment = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 12px 24px 4px;
  @media (min-width: ${metrics.device_size.tablet}) {
    flex-direction: row;
  }
  @media (min-width: ${metrics.device_size.mobile_s}) and (max-width: ${metrics
      .device_size.tablet}) {
    width: 100%;
    padding: 24px 0 24px 4px;
  }
  .calendar-panel {
    background: ${colors.white};
    padding: 4px 20px 12px 20px;
  }
`;
