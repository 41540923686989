/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Form, Icon, Item } from 'semantic-ui-react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Pane, WizardLayout } from '../../../../components/layout';
import {
  Button,
  Label,
  WizBtnGroup,
  WizContent,
  WizForm,
  WizFormItemGroup,
  WizPane,
  WizSubtitle,
  WizTitle,
} from '../../../../components/layout/WizardLayout/styles';
import { PLANS, VALIDATION_MESSAGES } from '../../../../config/constants';
import { coachSelector } from '../../../../redux/coaches/coaches.selectors';
import { promoSelector } from '../../../../redux/promo/promo.selectors';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  selectedSubscriptionLevelSelector,
  selectedSubscriptionSelector,
  subscriptionSelector,
} from '../../../../redux/subscription/subscription.selectors';
import { Text } from './styles';
import {
  purchaseSubscription,
  validateCouponCode,
} from '../../../../redux/subscription/subscription.saga';
import { removeActiveReferralCode } from '../../../../redux/promo/promo.slice';

type FormData = {
  couponCode: string | null;
};

const schema = yup.object().shape({
  couponCode: yup.string().required(VALIDATION_MESSAGES.required),
});

const Summary: React.FC = () => {
  const selectedSubscription = useAppSelector(selectedSubscriptionSelector);
  const selectedSubscriptionLevel = useAppSelector(
    selectedSubscriptionLevelSelector
  );
  const coach = useAppSelector(coachSelector);
  const promo = useAppSelector(promoSelector);
  const subscription = useAppSelector(subscriptionSelector);
  const dispatch = useAppDispatch();

  const { reset, control, getValues, formState } = useForm<FormData>({
    defaultValues: {
      couponCode: promo?.activeReferralCode || '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const { isValid } = formState;

  const history = useHistory();
  const selectedPlan = PLANS.find((p) => p.type === selectedSubscription);

  const subscriptionLevel = (selectedPlan?.levels || []).find(
    (l) => l.uid === selectedSubscriptionLevel
  );

  const subscriptionDetails = subscriptionLevel
    ? subscriptionLevel.description
    : 'Unlimited chatting with your coach on weekdays. No scheduling required. (Coach responds minimum once daily.)';

  const onSubmit = () => {
    const couponCode = subscription?.coupon?.id || '';

    if (selectedPlan?.type === 'Unlimited Chat') {
      dispatch(
        purchaseSubscription.action({
          stripeId: subscription.stripeId!,
          plan: { uid: selectedPlan.uid || '', ...selectedPlan },
          couponCode,
          meta: { onboarding: true },
        })
      );
    } else {
      dispatch(
        purchaseSubscription.action({
          stripeId: subscription.stripeId!,
          plan: subscriptionLevel!,
          couponCode,
          meta: { onboarding: true },
        })
      );
    }
  };

  const onApplyPromoCode = useCallback(() => {
    const { coupon } = subscription;
    const values = getValues();

    // cancel promo code
    if (coupon) {
      reset({ couponCode: '' });
      dispatch(validateCouponCode.action(null));
    } else {
      dispatch(validateCouponCode.action(values));
    }
  }, [dispatch, getValues, reset, subscription]);

  const getPrice = () => {
    let basePrice = selectedPlan?.price || subscriptionLevel?.price || 0;
    let discountedPrice = basePrice || 0;
    let description = 'per month';

    if (subscription.coupon) {
      const { duration, amount_off, percent_off } = subscription.coupon;

      if (amount_off) {
        discountedPrice -= amount_off * 0.01;
      }

      if (percent_off) {
        discountedPrice -= (percent_off / 100) * basePrice;
      }

      if (duration === 'once') {
        description = `${description} ($${discountedPrice} for the first month)`;
      } else {
        basePrice = discountedPrice;
      }
    }

    return `${basePrice} ${description}`;
  };

  const getDiscountedPriceLabel = () => {
    const { coupon } = subscription;

    console.log('coupon info', coupon);

    if (!coupon) {
      return '';
    }
    const { id } = coupon;

    return `${id} applied! Yay!`;
  };

  console.log('coupon', subscription.coupon);

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (promo?.activeReferralCode) onApplyPromoCode();
  }, [onApplyPromoCode, promo.activeReferralCode]);

  useEffect(() => {
    const { coupon } = subscription;
    if (coupon) dispatch(removeActiveReferralCode());
  }, [subscription, dispatch]);

  return (
    <WizardLayout sideBanner="onboarding_img_2">
      <WizContent textAlign="left">
        <WizTitle>Summary</WizTitle>
        <WizSubtitle>Review your subscription</WizSubtitle>
        <WizPane
          display="flex"
          flexDirection="column"
          justifyContent="center"
          borderRadius={10}>
          <WizForm>
            <WizFormItemGroup>
              <Item>
                <Item.Content>
                  <Text bold>Your subscription</Text>
                  <Item.Meta>
                    <Label>{selectedPlan?.type}</Label>
                  </Item.Meta>
                </Item.Content>
              </Item>
              <Item>
                <Item.Content>
                  <Text bold>Your subscription details</Text>
                  <Item.Meta>
                    <Label>{subscriptionDetails}</Label>
                  </Item.Meta>
                </Item.Content>
              </Item>
              {subscriptionLevel && (
                <>
                  <Item>
                    <Item.Content>
                      <Text bold>Number of sessions per month</Text>
                      <Item.Meta>
                        <Label>{subscriptionLevel.numberOfSessions}</Label>
                      </Item.Meta>
                    </Item.Content>
                  </Item>
                  <Item>
                    <Item.Content>
                      <Text bold>Session duration</Text>
                      <Item.Meta>
                        <Label>{subscriptionLevel.duration} minutes</Label>
                      </Item.Meta>
                    </Item.Content>
                  </Item>
                </>
              )}
              <Item>
                <Item.Content>
                  <Text bold>Price</Text>
                  <Item.Meta>
                    <Label>
                      ${getPrice()}
                      {/* ${selectedPlan?.price || subscriptionLevel.price} per month */}
                    </Label>
                    {!!subscription.coupon && (
                      <Label primary>{getDiscountedPriceLabel()}</Label>
                    )}
                  </Item.Meta>
                </Item.Content>
              </Item>
              <Item>
                <Item.Content>
                  <Text bold>Your coach</Text>
                  <Item.Meta>
                    <Label>{coach?.profile?.name || ''}</Label>
                  </Item.Meta>
                </Item.Content>
              </Item>
              {subscription.last4 && (
                <Item>
                  <Item.Content>
                    <Text bold>Credit card number</Text>
                    <Item.Meta>
                      <Label>{`**** **** **** ${subscription.last4}`}</Label>
                    </Item.Meta>
                  </Item.Content>
                </Item>
              )}
              {subscription.expiryDate && (
                <Item>
                  <Item.Content>
                    <Text bold>Expiration date</Text>
                    <Item.Meta>
                      <Label>{subscription.expiryDate}</Label>
                    </Item.Meta>
                  </Item.Content>
                </Item>
              )}
              {subscription.cardholderName && (
                <Item>
                  <Item.Content>
                    <Text bold>Name of card</Text>
                    <Item.Meta>
                      <Label>Cardholder Name</Label>
                      {/* <Label>{user.cardholderName}</Label> */}
                    </Item.Meta>
                  </Item.Content>
                </Item>
              )}
              <Pane height="20px" />
              <Item className="flex">
                <Item.Content>
                  <Text bold>Promo Code</Text>
                  <Form.Group>
                    <Controller
                      control={control}
                      name="couponCode"
                      render={({ field: { ref, ...field } }) => (
                        <Form.Input
                          disabled={Boolean(subscription.coupon)}
                          flex
                          {...field}
                        />
                      )}
                    />
                    <Form.Button
                      loading={subscription.status === 'loading'}
                      disabled={!isValid || subscription.status === 'loading'}
                      content={subscription.coupon ? 'Cancel' : 'Apply'}
                      onClick={onApplyPromoCode}
                    />
                  </Form.Group>
                </Item.Content>
              </Item>
            </WizFormItemGroup>
          </WizForm>
        </WizPane>
      </WizContent>
      <WizBtnGroup>
        <Button
          basic
          color="black"
          size="big"
          style={{ marginRight: 12 }}
          onClick={goBack}>
          Back
        </Button>
        <Button
          icon
          labelPosition="right"
          size="big"
          color="black"
          disabled={
            !selectedSubscription ||
            !selectedSubscriptionLevel ||
            !coach ||
            subscription.purchaseSubscriptionStatus === 'loading'
          }
          loading={subscription.purchaseSubscriptionStatus === 'loading'}
          onClick={() => onSubmit()}>
          Purchase
          <Icon name="chevron right" />
        </Button>
      </WizBtnGroup>
    </WizardLayout>
  );
};

export default Summary;
