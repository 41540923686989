/* eslint-disable camelcase */
import { Token } from '@stripe/stripe-js';
import firebase from 'firebase';
import 'firebase/functions';

async function purchaseSubscription(subscription: {
  plan: string;
  couponCode?: string;
}) {
  try {
    if (!subscription || !subscription.plan) throw new Error('No plan found');

    console.log('Data sent to firebase', subscription);

    const response = await firebase
      .functions()
      .httpsCallable('purchaseSubscription')(subscription);
    console.log(response);

    return response;
  } catch (err: any) {
    console.log('stripe purchase subscription error');
    throw err;
  }
}

async function createCustomer(email: string) {
  console.log('create customer', email);
  try {
    if (!email) throw new Error('No email');

    const response = await firebase.functions().httpsCallable('createUser')({
      user: {
        email,
      },
    });
    console.log(response);

    return response.data;
  } catch (err: any) {
    console.log('stripe create customer error');
    throw err;
  }
}

async function getCustomer(stripeId: string) {
  try {
    if (!stripeId) throw new Error('No stripeId');

    const response = await firebase.functions().httpsCallable('getUser')({
      id: stripeId,
    });
    // console.log(response);

    return response.data;
  } catch (err: any) {
    console.log('stripe get customer error');
    throw err;
  }
}

async function addCard(data: { token: Token; customerId: string } | null) {
  console.log('ADD CARD DATA CALLED TO API', data);
  try {
    if (!data || !data.token || !data.customerId)
      throw new Error('No customer id or card token provided...');

    const response = await firebase
      .functions()
      .httpsCallable('createSourceWeb')(data);

    if (response?.data?.rawType === 'card_error') {
      throw new Error(response?.data?.raw?.message);
    } else if (response?.data?.source?.cvc_check === 'fail') {
      throw new Error('Invalid CVC.');
    } else if (response?.data?.source?.address_zip_check === 'fail') {
      throw new Error('Invalid ZIP code.');
    }

    console.log(response);

    return { ...response.data, stripeCustomerId: data.customerId };
  } catch (err: any) {
    console.log('stripe add card error', err);
    throw err;
  }
}

async function validateCouponCode(data: { couponCode: string | null } | null) {
  try {
    if (!data || !data.couponCode) throw new Error('No couponCode');

    console.log('calling firebase validate coupon', data);

    const { data: coupon } = await firebase
      .functions()
      .httpsCallable('validateCouponCode')({
      couponCode: data.couponCode,
    });

    console.log('coupon code response data', coupon);

    if (coupon.id !== data.couponCode) {
      throw new Error('Invalid coupon code.');
    }

    return { ...coupon };
  } catch (err: any) {
    console.log('stripe validate coupon code error', err);
    throw err;
  }
}

export {
  createCustomer,
  getCustomer,
  addCard,
  purchaseSubscription,
  validateCouponCode,
};
