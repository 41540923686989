import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

import { colors, metrics } from '../../../theme';

export const LeftSideHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
  flex-direction: column;
  .ui.button {
    margin-top: 16px;
  }
  @media (min-width: ${metrics.device_size.tablet}) {
    flex-direction: row;
    .ui.button {
      margin-top: 0;
    }
  }
`;

export const PrimaryButton = styled(Button)`
  && {
    border-radius: 0;
    background: ${colors.geraldine};
    color: ${colors.white};
    font-size: 15px;
    padding: 16px;
    margin: 0;
  }
`;
