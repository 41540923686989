import styled from 'styled-components';
import { OTPublisher, OTSubscriber } from 'opentok-react';

import { FooterContainer } from './Footer/styles';
import { Pane } from '../../../components/layout';

export const Publisher = styled(OTPublisher)`
  width: 100% !important;
  height: 100% !important;
`;

export const Subscriber = styled(OTSubscriber)`
  width: 100% !important;
  height: 100vh !important;
`;

export const SessionContainer = styled(Pane)`
  background-color: black;

  &:hover ${FooterContainer} {
    opacity: 1;
  }
`;
