import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import { Pane } from '../../../../../components/layout';
import {
  StyledBillingInfo,
  SwitchButton,
} from '../../../../../components/layout/SettingsLayout/styles';
import { useAppSelector } from '../../../../../redux/store';
import { subscriptionSelector } from '../../../../../redux/subscription/subscription.selectors';
import { userSelector } from '../../../../../redux/user/user.selectors';

const PaymentInfo: React.FC<{
  goAhead: () => void;
}> = ({ goAhead }) => {
  const user = useAppSelector(userSelector);
  const subscription = useAppSelector(subscriptionSelector);
  const { last4, expiryDate } = subscription;

  const creditCardNo = `**** **** **** ${last4 || '****'}`;

  return (
    <StyledBillingInfo>
      <Header as="h4">Payment information</Header>
      <div className="payment-info">
        <Icon size="huge" name="credit card" />
        <div className="card-info">
          <h3>{creditCardNo}</h3>
          <span>{`${expiryDate || 'MM / YYYY'} ${user?.profile?.name}`}</span>
        </div>
      </div>
      <Pane marginTop="8%">
        <SwitchButton icon labelPosition="right" onClick={goAhead}>
          Update Payment Information
          <Icon name="chevron right" />
        </SwitchButton>
      </Pane>
    </StyledBillingInfo>
  );
};

export default PaymentInfo;
