import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Messages } from '../../types/message';
import { roleSelector, userIdSelector } from '../auth/auth.selectors';
import { ApplicationState } from '../store';

export const chatSelector = (state: ApplicationState) => state.chat;

export const roomsSelector = createSelector(chatSelector, (chat) => chat.rooms);
export const chatStatusSelector = createSelector(
  chatSelector,
  (chat) => chat.status
);

const isLastMessageUnread = (
  messages: Messages,
  userUid: string | null
): boolean => {
  const lastMessage = _.last(messages);
  return Boolean(
    lastMessage && !lastMessage.received && lastMessage.recipientID === userUid
  );
};

// eslint-disable-next-line import/prefer-default-export
export const getUnread = createSelector(
  [roleSelector, chatSelector, userIdSelector],
  (role, chat, userUid) =>
    role === 'user'
      ? isLastMessageUnread(chat.messages, userUid)
      : Object.values(chat.rooms).some((room) =>
          isLastMessageUnread(room.messages, userUid)
        )
);
