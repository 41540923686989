import React from 'react';
import classnames from 'classnames';
import { Menu } from 'semantic-ui-react';

export interface SettingMenuItem {
  name: string;
  label?: string;
}

const SettingsMenu: React.FC<{
  items: SettingMenuItem[];
  selectedItem: SettingMenuItem;
  onSelectItem: (item: SettingMenuItem) => void;
}> = ({ items, selectedItem, onSelectItem }) => {
  const handleItemClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: SettingMenuItem
  ) => {
    e.preventDefault();
    onSelectItem(item);
  };

  return (
    <Menu tabular attached>
      {items.map((item) => (
        <Menu.Item
          key={item.name}
          onClick={(e) => handleItemClick(e, item)}
          className={classnames('item', {
            active: item.name === selectedItem.name,
          })}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default SettingsMenu;
