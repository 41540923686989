import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider, ReactReduxContext } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'semantic-ui-css/semantic.min.css';
import store, { history, persistor } from './redux/store';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { GlobalStyle } from './theme';
import Routes from './routes';

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store} context={ReactReduxContext}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history} context={ReactReduxContext}>
          <GlobalStyle />
          <App>
            <Routes />
          </App>
        </ConnectedRouter>
      </PersistGate>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
