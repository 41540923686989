import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { clientsSelector } from '../clients/clients.selectors';
import { roleSelector } from '../auth/auth.selectors';
import { coachSelector } from '../coaches/coaches.selectors';
import { ApplicationState } from '../store';

export const videoSelector = (state: ApplicationState) => state.video;

export const videoStatusSelector = createSelector(
  videoSelector,
  (video) => video.status
);

export const sessionsSelector = createSelector(
  videoSelector,
  (video) => video.sessions
);

export const activeSessionSelector = createSelector(
  videoSelector,
  (video) => video.activeSession
);

export const allUpcomingSessionsSelector = createSelector(
  [roleSelector, coachSelector, clientsSelector, sessionsSelector],
  (role, coach, clients, sessions) => {
    if (role === 'user' && !coach) return [];

    return [...sessions]
      .sort(
        (a, b) => parseInt(a.block.dateUTC, 10) - parseInt(b.block.dateUTC, 10)
      )
      .filter((session) => {
        if (['CANCELED', 'ENDED'].includes(session.status!)) return false;

        const currentDateTime = moment().subtract(1, 'hours');
        const sessionStartTime = moment(parseInt(session.block.dateUTC, 10));
        return currentDateTime.isBefore(sessionStartTime);
      })
      .map((session) => {
        if (role === 'user') {
          return { ...session, profile: coach.profile };
        }

        const client = clients.find((v) => v.profile.uid === session.userUid);
        const profile = client ? client.profile : { name: '...', photo: '' };
        return { ...session, profile };
      });
  }
);
