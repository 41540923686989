import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import { AppLayout, Pane, SettingsLayout } from '../../../../components/layout';
import { SettingMenuItem } from '../../../../components/layout/SettingsLayout/SettingsMenu';
import { USER_TABS } from '../../../../constants/tabs';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { addCard } from '../../../../redux/subscription/subscription.saga';
import {
  iosSubscriptionSelector,
  stripeIdSelector,
  subscriptionSelector,
} from '../../../../redux/subscription/subscription.selectors';
import { userSelector } from '../../../../redux/user/user.selectors';
import PaymentForm from './PaymentForm';
import PaymentInfo from './PaymentInfo';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

const Billing: React.FC = () => {
  const history = useHistory();
  const { isMobile } = useWindowSize();
  const user = useAppSelector(userSelector);
  const subscription = useAppSelector(subscriptionSelector);
  const { status } = subscription;
  const ios = useAppSelector(iosSubscriptionSelector);
  const stripeId = useAppSelector(stripeIdSelector);
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(0);

  const handleNavigationChange = (item: SettingMenuItem) => {
    history.push(item.name);
  };

  if (ios?.isSubscribed && ios?.autoRenew) {
    return (
      <AppLayout page="settings">
        <SettingsLayout
          items={USER_TABS}
          selectedItem={{ name: 'billing' }}
          onSelectItem={handleNavigationChange}>
          <Pane
            marginBottom="2vh"
            alignItems="center"
            flexDirection={isMobile ? 'column' : 'row'}
            display="flex">
            <Pane flex={1}>
              <Message negative>
                <Message.Header>Sorry</Message.Header>
                Please manage your billing from an iOS device.
              </Message>
            </Pane>
          </Pane>
        </SettingsLayout>
      </AppLayout>
    );
  }

  return (
    <AppLayout page="settings">
      <SettingsLayout
        items={USER_TABS}
        selectedItem={{ name: 'billing' }}
        onSelectItem={handleNavigationChange}>
        {page === 0 ? (
          <PaymentInfo goAhead={() => setPage(1)} />
        ) : (
          <Elements stripe={stripePromise}>
            <PaymentForm
              stripeId={stripeId}
              defaultValues={{ cardholderName: user?.profile?.name || '' }}
              onSubmit={({ successAlertMsg, ...data }) =>
                dispatch(addCard.action({ ...data, meta: { successAlertMsg } }))
              }
              loading={status === 'loading'}
              goBack={() => setPage(0)}
            />
          </Elements>
        )}
      </SettingsLayout>
    </AppLayout>
  );
};

export default Billing;
