import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Pane, WizardLayout } from '../../../../components/layout';
import {
  Button,
  WizBtnGroup,
  WizContent,
  WizTitle,
} from '../../../../components/layout/WizardLayout/styles';

const OptionChooseCoach: React.FC = () => {
  const history = useHistory();

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <WizardLayout sideBanner="onboarding_img_7">
      <WizContent textAlign="left">
        <WizTitle>
          Do you know <br />
          who you already <br />
          want to work with?
        </WizTitle>
        <Pane height="56px" />
        <Pane maxWidth="36rem">
          <Button
            className="geraldine standalone"
            fluid
            labelPosition="right"
            size="big"
            onClick={() => history.push('/onboarding/interests')}>
            Help me choose the right coach for me
          </Button>
          <Pane height="36px" />
          <Button
            className="dark standalone"
            fluid
            labelPosition="right"
            size="big"
            onClick={() => history.push('/onboarding/choose=from-all-coaches')}>
            I know which coach I want to work with
          </Button>
        </Pane>
      </WizContent>
      <WizBtnGroup>
        <Button
          basic
          color="black"
          size="big"
          style={{
            marginRight: 12,
          }}
          onClick={goBack}>
          Back
        </Button>
      </WizBtnGroup>
    </WizardLayout>
  );
};

export default OptionChooseCoach;
