import { replace } from 'connected-react-router';
import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { logout, verifyRole } from './redux/auth/auth.saga';
import { authSelector, roleSelector } from './redux/auth/auth.selectors';
import { getUserCoach } from './redux/coaches/coaches.saga';
import { coachSelector } from './redux/coaches/coaches.selectors';
import { useAppDispatch, useAppSelector } from './redux/store';
import {
  getStripeCustomer,
  getSubscription,
} from './redux/subscription/subscription.saga';
import { stripeIdSelector } from './redux/subscription/subscription.selectors';
import {
  isProfileCompleteSelector,
  userSelector,
} from './redux/user/user.selectors';
import { onAuthStateChanged } from './services/firebase';

const App: React.FC = ({ children }) => {
  const [ready, setReady] = React.useState(false);
  const auth = useAppSelector(authSelector);
  const user = useAppSelector(userSelector);
  const role = useAppSelector(roleSelector);
  const coach = useAppSelector(coachSelector);
  const stripeId = useAppSelector(stripeIdSelector);
  const dispatch = useAppDispatch();
  const isProfileComplete = useAppSelector(isProfileCompleteSelector);

  React.useEffect(() => {
    if (auth.status === 'pending' && !ready) {
      onAuthStateChanged(
        () => dispatch(verifyRole.action()),
        () => dispatch(logout.action())
      );
    }
  }, [auth, ready, dispatch]);

  React.useEffect(() => {
    if (!ready) {
      if (auth.status === 'failed') {
        setReady(true);
      } else if (stripeId) {
        dispatch(getStripeCustomer.action(stripeId));
        setReady(true);
      }
    }
  }, [auth.status, dispatch, ready, stripeId, user.status]);

  React.useEffect(() => {
    if (coach.uid && role === 'user') {
      dispatch(getUserCoach.action(coach.uid));
    }
  }, [coach.uid, dispatch, role]);

  React.useEffect(() => {
    if (auth.uid && role === 'user') {
      dispatch(getSubscription.action());
      if (ready && !isProfileComplete) {
        dispatch(replace('/onboarding/session-plan'));
      }
    }
  }, [auth.uid, coach.uid, dispatch, isProfileComplete, ready, role]);

  if (auth.status === 'pending') {
    return (
      <Dimmer active inverted>
        <Loader active size="big" inline="centered">
          Loading
        </Loader>
      </Dimmer>
    );
  }

  return <>{children}</>;
};

export default App;
