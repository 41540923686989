import firebase from 'firebase';

const converter = {
  toFirestore: (data: Notification) => data,
  fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) =>
    snap.data() as Notification,
};

const settingConverter = {
  toFirestore: (data: NotificationSettings) => data,
  fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) =>
    snap.data() as NotificationSettings,
};

const notificationsCollection = firebase
  .firestore()
  .collection('notifications')
  .withConverter(converter);

const notificationsSettingsCollection = firebase
  .firestore()
  .collection('notifications_settings')
  .withConverter(settingConverter);

/**
 * Notifications observer
 */
export const notificationsObserver = (
  emitter: (notification: Notification[] | Error) => void
) => {
  const { currentUser } = firebase.auth();

  return notificationsCollection
    .where('uid', '==', currentUser!.uid)
    .orderBy('created_at', 'desc')
    .limit(50)
    .onSnapshot(
      (snapshot) => {
        if (snapshot.empty) {
          return emitter([]);
        }

        const notifications = snapshot.docs.map((doc) => ({
          ...doc.data(),
          created_at: doc.data().created_at.toDate(),
          uid: doc.id,
        }));

        return emitter(notifications);
      },
      (err) => {
        console.log(err);
        return emitter(new Error(err.message));
      }
    );
};

export const getNotificationSettings = async () => {
  const { currentUser } = firebase.auth();

  const docSnapshot = await notificationsSettingsCollection
    .doc(currentUser!.uid)
    .get();

  return docSnapshot.data();
};

export const updateNotificationSettings = async (data: {
  settings: NotificationSettings;
}) => {
  if (!data || typeof data.settings !== 'object')
    throw new Error('Invalid settings');

  const response = await firebase
    .functions()
    .httpsCallable('updateNotificationsSettingsHandler')(data);

  return response;
};

export const readNotification = async ({
  notificationId,
}: {
  notificationId: string;
}) => {
  const response = await notificationsCollection.doc(notificationId).update({
    read: true,
    updated_at: firebase.firestore.Timestamp.now(),
  });
  return response;
};
