import styled from 'styled-components';

export const NotificationsDropdownMobileContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding-left: 4px;
`;

export const NotificationsDialogContainer = styled.div`
  .notification-item {
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .notification-item.unread {
    .content {
      font-weight: 600;
    }
  }
`;
