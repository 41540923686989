import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '../store';

export const coachesSelector = (state: ApplicationState) => state.coaches;

export const allCoachesSelector = createSelector(
  coachesSelector,
  (coaches) => coaches.coaches || []
);

export const coachSelector = createSelector(
  coachesSelector,
  (coaches) =>
    coaches.coach || ({ profile: {} } as Coach & { matchResults?: Coach[] })
);

export const previousCoachesSelector = createSelector(
  coachesSelector,
  (coaches) => coaches.previousCoaches || []
);
