import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Icon } from 'semantic-ui-react';
import { Pane, WizardLayout } from '../../../../components/layout';
import {
  Button,
  WizBtnGroup,
  WizCard,
  WizContent,
  WizSubtitle,
  WizText,
  WizTitle,
} from '../../../../components/layout/WizardLayout/styles';
import { INTERESTS } from '../../../../config/constants';
import { userInterestsSelector } from '../../../../redux/interests/interests.selectors';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { updateUser } from '../../../../redux/user/user.saga';
import { userSelector } from '../../../../redux/user/user.selectors';

const Interests: React.FC = () => {
  const history = useHistory();
  const user = useAppSelector(userSelector);
  const interestsArray = useAppSelector(userInterestsSelector);
  const dispatch = useAppDispatch();

  const [selectedInterests, setSelectedInterests] = useState(interestsArray);

  const onClickInterest = (interest: Interest) => {
    if (selectedInterests.includes(interest)) {
      setSelectedInterests(selectedInterests.filter((int) => int !== interest));
    } else {
      setSelectedInterests([...selectedInterests, interest]);
    }
  };

  const onUpdateInterests = () => {
    dispatch(
      updateUser.action({
        data: {
          'interests.interests': selectedInterests,
        },
        meta: {
          onboarding: true,
          successPath: '/onboarding/availability',
        },
      })
    );
  };

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <WizardLayout sideBanner="onboarding_img_4">
      <WizContent textAlign="left">
        <WizTitle>Amazing!</WizTitle>
        <WizSubtitle>
          Now it&apos;s time for the fun part. Let&apos;s find you a coach!
        </WizSubtitle>
        <WizText>
          Pick three or more topics you&apos;re interested in discussing with
          your coach.
        </WizText>
        <Pane height="40px" />
        <Card.Group stackable itemsPerRow={3}>
          {INTERESTS.map((interest) => (
            <WizCard
              key={interest}
              onClick={() => onClickInterest(interest)}
              selected={selectedInterests.includes(interest)}
              $whiteHighlightedTitle
              type="single">
              {selectedInterests.includes(interest) && (
                <Icon name="check circle" size="large" color="green" />
              )}
              <Card.Header>{interest}</Card.Header>
            </WizCard>
          ))}
        </Card.Group>
      </WizContent>
      <WizBtnGroup>
        <Button
          basic
          color="black"
          size="big"
          style={{ marginRight: 12 }}
          onClick={goBack}>
          Back
        </Button>
        <Button
          icon
          labelPosition="right"
          size="big"
          color="black"
          loading={user.status === 'loading'}
          disabled={selectedInterests.length < 3 || user.status === 'loading'}
          onClick={onUpdateInterests}>
          Continue
          <Icon name="chevron right" />
        </Button>
      </WizBtnGroup>
    </WizardLayout>
  );
};

export default Interests;
