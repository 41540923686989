import moment from 'moment';
import firebase from 'firebase';
import { Message } from '../../types/message';

const { now, fromDate } = firebase.firestore.Timestamp;

const converter = {
  toFirestore: (data: Message) => data,
  fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) =>
    snap.data() as Message,
};

class ChatManage {
  recipientId = '';

  role: Role = '';

  db: firebase.firestore.Firestore;

  constructor() {
    this.db = firebase.firestore();
  }

  get collection() {
    return this.db.collection('messages');
  }

  get recipientID() {
    return this.recipientId;
  }

  set recipientID(id) {
    this.recipientId = id;
  }

  get chatID() {
    const senderID = firebase.auth().currentUser!.uid;

    // Always place coach ID first
    const idSet =
      this.role === 'coach'
        ? [senderID, this.recipientID]
        : [this.recipientID, senderID];

    return idSet.join('_');
  }

  get = (
    recipientID: string,
    role: Role,
    orderBy = 'created_at',
    orderDir: firebase.firestore.OrderByDirection = 'asc'
  ) => {
    this.recipientID = recipientID;
    this.role = role;

    let collectionRef = this.collection.where('chatID', '==', this.chatID);

    if (orderBy) {
      collectionRef = collectionRef.orderBy(orderBy, orderDir);
    }

    return collectionRef.get().then((snapshot) => {
      if (snapshot.empty) {
        return [];
      }

      const messages = snapshot.docs.map((doc) => {
        const { created_at: createdAtTimeStamp, read, ...rest } = doc.data();
        const createdAtDate = createdAtTimeStamp.toDate();

        return {
          _id: doc.id,
          createdAt: createdAtDate,
          dateUTC: moment(createdAtDate).utc().format('x'),
          ...rest,
        };
      });

      return messages;
    });
  };

  observe = (
    emitter: (messages: Omit<Message, 'created_at' | 'read'>[] | Error) => void,
    recipientID: string,
    role: Role,
    _lastReceived: string,
    limit = 100,
    orderBy = 'created_at',
    orderDir: firebase.firestore.OrderByDirection = 'desc'
  ) => {
    this.recipientID = recipientID;
    this.role = role;

    let collectionRef = this.collection.where('chatID', '==', this.chatID);

    if (orderBy) {
      collectionRef = collectionRef.orderBy(orderBy, orderDir);
    }

    if (limit) {
      collectionRef = collectionRef.limit(limit);
    }

    // if (lastReceived) {
    //   collectionRef = collectionRef.where('dateUTC', '>=', lastReceived);
    // }

    return collectionRef.withConverter(converter).onSnapshot(
      { includeMetadataChanges: true },
      (snapshot) => {
        const messages = snapshot.docs.map((doc) => {
          const { created_at: createdAtTimeStamp, read, ...rest } = doc.data();
          const createdAtDate = createdAtTimeStamp?.toDate();

          return {
            _id: doc.id,
            createdAt: createdAtDate,
            dateUTC: moment(createdAtDate).utc().format('x'),
            sent: !doc.metadata.hasPendingWrites,
            received: !!read,
            pending: doc.metadata.hasPendingWrites,
            ...rest,
          };
        });

        return emitter(messages);
      },
      (err) => emitter(new Error(err.message))
    );
  };

  add = (
    recipientID: string,
    role: Role,
    data: Partial<Message> & { isWelcomeMessage?: boolean; resend?: boolean }
  ) => {
    this.recipientID = recipientID;
    this.role = role;

    const { isWelcomeMessage, dateUTC, resend, ...rest } = data;

    return this.collection.add({
      ...rest,
      ...(resend && { resend }),
      chatID: this.chatID,
      dateUTC: resend ? dateUTC : moment(now().toDate()).utc().format('x'),
      recipientID: isWelcomeMessage
        ? firebase.auth().currentUser!.uid
        : recipientID,
      read: false,
      created_at: resend ? fromDate(new Date(parseInt(dateUTC!, 10))) : now(),
      updated_at: now(),
    });
  };

  upload = async (
    recipientID: string,
    role: Role,
    blob: Blob | Uint8Array | ArrayBuffer
  ): Promise<string> => {
    this.recipientID = recipientID;
    this.role = role;

    const timestamp = now().toMillis();

    const ref = `${this.chatID}/${timestamp}`;
    const childRef = firebase.storage().ref(ref);

    await childRef.put(blob);

    const url = childRef.getDownloadURL();

    return url;
  };

  updateChatReadReceipt = async (recipientID: string, role: Role) => {
    this.recipientID = recipientID;
    this.role = role;

    const { currentUser } = firebase.auth();
    const batch = this.db.batch();

    const snapshot = await this.collection
      .where('chatID', '==', this.chatID)
      .where('recipientID', '==', currentUser!.uid)
      .where('read', '==', false)
      .get();

    snapshot.docs.forEach((doc) => {
      const docRef = this.collection.doc(doc.id);
      batch.update(docRef, {
        read: true,
      });
    });

    return batch.commit();
  };
}

export default ChatManage;
