import styled from 'styled-components';
import { colors } from '../../../theme';

export const QuoteSticky = styled.div`
  background: ${colors.oldLavender};
  text-align: center;
  color: ${colors.white};
  padding: 36px 44px;
  .icon {
    font-size: 32px;
    margin-top: 12px;
  }
  .header {
    color: ${colors.white};
    margin-top: 16px;
    margin-bottom: 20px;
  }
  .quote-text {
    font-size: 16px;
  }
  .quote-author {
    display: block;
    font-size: 16px;
    margin-top: 20px;
  }
`;
