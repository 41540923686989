import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { SessionDetailsModal } from '../../../ui';
import { UnreadIcon } from '../styles';
import { Pane } from '../../Pane';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { authSelector } from '../../../../redux/auth/auth.selectors';
import { notificationsSelector } from '../../../../redux/notifications/notifications.selectors';
import {
  getNotifications as getNotificationsAction,
  readNotification as readNotificationAction,
} from '../../../../redux/notifications/notifications.saga';
import { cancelSession as cancelSessionAction } from '../../../../redux/video/video.saga';

interface Props {
  video?: { status: string };
}

const NotificationsDropdown: FC<Props> = ({ video }) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(authSelector);
  const notificationSelector = useAppSelector(notificationsSelector);

  const role = useMemo<string>(() => auth?.role ?? '', [auth]);
  const { notifications, status }: { notifications: any; status: string } =
    useMemo<any>(
      () => ({
        notifications: notificationSelector?.notifications,
        status: notificationSelector?.status,
      }),
      [notificationSelector]
    );

  const cancelSession = useCallback(
    (sessionId: string) => {
      dispatch(cancelSessionAction.action(sessionId));
    },
    [dispatch]
  );
  const getNotifications = useCallback(() => {
    dispatch(getNotificationsAction.action());
  }, [dispatch]);
  const readNotification = useCallback(
    (data) => {
      dispatch(readNotificationAction.action(data));
    },
    [dispatch]
  );

  const history = useHistory();
  const [sessionNotification, setSessionNotification] = useState<any>(null);
  const [sessionProfile, setSessionProfile] = useState<any>({});

  useEffect(() => {
    if (status !== 'success') getNotifications();
  }, [getNotifications, status]);

  useEffect(() => {
    if (sessionNotification) {
      const target = role === 'user' ? 'coach' : 'client';
      setSessionProfile(sessionNotification.payload[target].profile);
    }
  }, [role, sessionNotification]);

  const unreadCount = notifications.filter(
    (notification: any) => !notification.read
  ).length;

  const onReadNotification = (notification: any) => {
    readNotification({ notificationId: notification.uid });
    switch (notification.action.type) {
      case 'CHAT_DETAIL':
        return history.push(
          `/${role}/chat?uid=${notification.action.accessor}`
        );
      case 'CLIENT_PROFILE':
        return history.push(
          `/${role}/clients?id=${notification.action.accessor}`
        );
      case 'SESSION_DETAIL':
        return setSessionNotification(notification);
      default:
        return null;
    }
  };

  const onJoinSession = (sessionId: string) => {
    window.open(
      `${role}/session/${sessionId}`,
      'Session',
      'width=600,height=800'
    );
  };

  return (
    <>
      <Dropdown
        item
        className="notification"
        trigger={
          <UnreadIcon>
            {!!unreadCount && <Icon name="circle" />}
            {unreadCount}
          </UnreadIcon>
        }
        icon={null}
        pointing="top right"
        scrolling>
        <Dropdown.Menu>
          {!notifications.length ? (
            <Dropdown.Item>
              <Pane padding="10px">
                <Pane>No notification</Pane>
              </Pane>
            </Dropdown.Item>
          ) : (
            notifications.map((notification: any) => (
              <React.Fragment key={notification.uid}>
                <Dropdown.Item
                  onClick={() => onReadNotification(notification)}
                  active={!notification.read}>
                  <Pane paddingTop="10px" paddingBottom="10px">
                    <Pane textAlign="right" marginBottom="5px">
                      <span style={{ fontSize: '12px' }}>
                        {moment(notification.created_at).fromNow()}
                      </span>
                    </Pane>
                    <Pane>{notification.message}</Pane>
                  </Pane>
                </Dropdown.Item>
                <Dropdown.Divider />
              </React.Fragment>
            ))
          )}
        </Dropdown.Menu>
      </Dropdown>
      {sessionNotification && (
        <SessionDetailsModal
          userRole={role}
          session={sessionNotification?.payload?.session}
          name={sessionProfile.name}
          photo={sessionProfile.photo}
          showModal
          confirmModalLoader={video?.status === 'loading'}
          onCloseModal={() => setSessionNotification(null)}
          onJoinSession={() =>
            onJoinSession(sessionNotification.action.accessor)
          }
          onCancelSession={() =>
            cancelSession(sessionNotification.action.accessor)
          }
        />
      )}
    </>
  );
};

export default NotificationsDropdown;
