import React from 'react';

// import { Header, Container } from 'semantic-ui-react';

function TermsOfService() {
  return (
    <div style={{ textAlign: 'justify' }}>
      {/* <Container textAlign="justified"> */}
      <br />
      {/* <Header as="h2">Terms of Service</Header>
      <Header as="h4">All the fine little details you love to read.</Header> */}
      <p>
        The Site is an Internet-based venue where Clients and Coaches use our
        platform for online coaching. Blush created, and continues to host,
        maintain, and provide the Site (Site Services). To access private online
        video conference and text-based coaching (Coaching Services), you must
        register for a Blush Account (Account) and become a Client.
      </p>
      <p>
        To register for an Account, you must be 18 or older. You agree to
        provide accurate information in your registration. Blush reserves the
        right in its sole discretion to refuse, suspend, or terminate service to
        anyone for any reason, including providing false or misleading
        information.
      </p>
      <p>
        Blush is not a party to the coaching relationship between Client and
        Coach. Blush selects qualified Coaches, introduces Clients to Coaches
        and makes the Site Services available to enable online payment and
        delivery of Coaching Services. Blush does not direct, has no control
        over, makes no representations, and does not guarantee the quality of
        Coaching Services or the ability of Coaches to deliver Coaching Services
        satisfactory to Clients. The coaching relationship exists exclusively
        between Client and Coach and the Client pays her Coach for Coaching
        Services. If you have questions or concerns about the coaching
        relationship, please raise these directly with the Coach.
      </p>
      <p>
        Blush offers coaching services only, and does not offer mental health
        psychotherapy or any other type of state-regulated mental health
        services. Although a Coach may be licensed to practice mental health
        counseling or psychology, she is not doing so through Blush.
      </p>
      <p>
        Client payments are made in advance through the purchase of credits. For
        ease of administration, Client payments are made through the Site. When
        a Coach delivers pre-paid Coaching Services, Blush will credit the Coach
        and then deduct a service fee that Blush earns for introducing Clients
        to Coaches and for providing Site Services. Membership credits expire
        and are nonrefundable. Pay As You Go credits do not expire and are
        non-refundable.
      </p>
      <p>
        Payment processing services for Blush Online Life Coaching are provided
        by Stripe and are subject to the Stripe Connected Account Agreement,
        which includes the Stripe Terms of Service (collectively, the “Stripe
        Services Agreement”). By agreeing to these terms or continuing to
        operate as a Coach or a Client for Blush Online Life Coaching, you agree
        to be bound by the Stripe Services Agreement, as the same may be
        modified by Stripe from time to time. As a condition of Blush Online
        Life Coaching, enabling payment processing services through Stripe, you
        agree to provide Blush Online Life Coaching accurate and complete
        information about you and your business, and you authorize Blush Online
        Life Coaching to share it and transaction information related to your
        use of the payment processing services provided by Stripe.
      </p>
    </div>
    // </Container>
  );
}

export default TermsOfService;
