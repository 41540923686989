import { RouterState } from 'connected-react-router';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import store from './configureStore';

export type ApplicationState = ReturnType<typeof store.getState> & {
  router: RouterState;
};

export type AppDispatch = typeof store.dispatch;

export { history, persistor } from './configureStore';

export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<ApplicationState> =
  useSelector;

export default store;
