import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../pages/shared/NotFound';
import PrivacyPolicy from '../pages/shared/PrivacyPolicy';
import TermsOfService from '../pages/shared/TermsOfService';

export default function SharedRoutes(): JSX.Element {
  return (
    <Switch>
      <Route exact path="/info/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/info/terms-of-service" component={TermsOfService} />
      <Route component={NotFound} />
    </Switch>
  );
}
