import moment from 'moment';

export function filterBlocksByDate(blocks: any, date: any) {
  const filteredBlocks = blocks.filter((block: any) => {
    if (block.status !== 'available') return false;

    const utcToDate = moment(parseInt(block.dateUTC, 10)).format('YYYY-MM-DD');

    const blockStartDate = moment(utcToDate).toDate();

    return moment(blockStartDate).isSame(date);
  });

  return filteredBlocks;
}

export function getBlocksWithDisplay(blocks: any) {
  return blocks
    .filter((block: any) => block.status === 'available')
    .map((block: any) => {
      // create human-readable string out of start time
      const timeUtcString = moment(parseInt(block.dateUTC, 10))
        // .utc(parseInt(block.dateUTC, 10))
        .format('LT');

      // add to block
      return {
        ...block,
        startDisplayTime: timeUtcString,
      };
    });
}

export function getRemainingDays() {
  const currentDate = moment();

  let maxDays = 30;
  const remainingDays = [];

  while (maxDays) {
    const dateToCheck = moment().add(maxDays, 'days');
    if (!currentDate.isBefore(currentDate)) {
      remainingDays.push(dateToCheck);
    }

    // eslint-disable-next-line no-plusplus
    maxDays--;
  }

  return remainingDays;
}

export function getUnavailableDates(blocks: any, dateRange: any) {
  const today = moment().format('YYYY-MM-DD');
  const unavailableDates = [today];

  dateRange.forEach((day: any) => {
    const date = moment(day).format('YYYY-MM-DD');
    const filtered = filterBlocksByDate(blocks, date);

    if (filtered.length === 0) unavailableDates.push(date);
  });

  return unavailableDates;
}
