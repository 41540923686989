import React, { useState } from 'react';
import { Form, Header } from 'semantic-ui-react';
import { Controller, useForm } from 'react-hook-form';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Token } from '@stripe/stripe-js';
import { VALIDATION_MESSAGES } from '../../../../../config/constants';
import { PrimaryButton } from '../../../../../components/layout/SettingsLayout/styles';
import { Pane } from '../../../../../components/layout';

interface FormData {
  cardholderName: string;
}

const schema = yup.object().shape({
  cardholderName: yup.string().required(VALIDATION_MESSAGES.required),
});

const PaymentForm: React.FC<{
  defaultValues: FormData;
  loading: boolean;
  stripeId: string;
  onSubmit: (data: {
    token: Token;
    customerId: string;
    successAlertMsg: string;
  }) => void;
  goBack: () => void;
}> = ({ defaultValues, loading, stripeId, onSubmit, goBack }) => {
  const [isCardComplete, setIsCardComplete] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const { handleSubmit, control, formState } = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const onFormSubmit = async (formData: FormData) => {
    try {
      const cardElement = elements!.getElement('card');
      const { token } = await stripe!.createToken(cardElement!, {
        name: formData.cardholderName,
      });
      onSubmit({
        token: token!,
        customerId: stripeId,
        successAlertMsg: 'Your card was updated successfully.',
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  const onCardValueChange = (element: { complete: boolean }) => {
    setIsCardComplete(element.complete);
  };

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Header as="h4">Update Payment Info</Header>
      <Controller
        control={control}
        name="cardholderName"
        render={({ field: { ref, ...field } }) => (
          <Form.Input
            placeholder="Name"
            label="Name of card"
            error={errors?.cardholderName?.message}
            {...field}
          />
        )}
      />
      <Form.Field
        label="Credit card"
        name="creditCard"
        control={CardElement}
        onChange={onCardValueChange}
      />
      <Pane marginTop="8%">
        <PrimaryButton
          primary
          loading={loading}
          disabled={loading || !isCardComplete || !!errors.cardholderName}
          type="submit">
          Save
        </PrimaryButton>
        <PrimaryButton type="button" onClick={goBack}>
          Back
        </PrimaryButton>
      </Pane>
    </Form>
  );
};

export default PaymentForm;
