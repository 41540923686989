import React from 'react';
import { useHistory } from 'react-router-dom';
import { Image, Segment } from 'semantic-ui-react';
import AuthLayout from '../../../components/layout/AuthLayout';
import { Button } from '../../../components/ui';
import { loginWithEmail } from '../../../redux/auth/auth.saga';
import { useAppDispatch } from '../../../redux/store';
import { colors } from '../../../theme';
import LoginForm from './LoginForm';
import { Divider } from './styles';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  return (
    <AuthLayout>
      <Image
        src="/sml-logo.png"
        style={{ width: '128px', objectFit: 'contain' }}
      />
      <Segment basic>
        <LoginForm
          onSubmit={(values) => dispatch(loginWithEmail.action(values))}
        />
        <Divider horizontal>New to Blush?</Divider>
        <Button
          onClick={() => history.push({ pathname: '/signup' })}
          backgroundColor={colors.roseWhite}
          fluid
          size="huge">
          Sign Up now
        </Button>
      </Segment>
    </AuthLayout>
  );
};

export default Login;
