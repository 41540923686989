import firebase from 'firebase';

const converter = {
  toFirestore: (data: Content) => data,
  fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) =>
    snap.data() as Content,
};

const contentCollection = firebase
  .firestore()
  .collection('content')
  .withConverter(converter);

// eslint-disable-next-line import/prefer-default-export
export const fetchContent = async () => {
  const snapshot = await contentCollection.doc('dashboard-content').get();
  return snapshot.data();
};
