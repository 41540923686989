/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Icon, Loader, Message } from 'semantic-ui-react';
import { AppLayout, Pane, SettingsLayout } from '../../../../components/layout';
import { SettingMenuItem } from '../../../../components/layout/SettingsLayout/SettingsMenu';
import {
  BoldSpan,
  PrimaryButton,
  StyledSubscription,
} from '../../../../components/layout/SettingsLayout/styles';
import {
  LevelDetails,
  PLANS,
  SubscriptionPlan,
} from '../../../../config/constants';
import { USER_TABS } from '../../../../constants/tabs';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { purchaseSubscription } from '../../../../redux/subscription/subscription.saga';
import {
  activeSubscriptionSelector,
  defaultCardSelector,
  iosSubscriptionSelector,
  pendingSubscriptionSelector,
  stripeIdSelector,
  subscriptionSelector,
} from '../../../../redux/subscription/subscription.selectors';
import { userSelector } from '../../../../redux/user/user.selectors';
import Swal from '../../../../utils/Swal';
import { CancelLink } from './styles';

const Subscription: React.FC = () => {
  const history = useHistory();
  const user = useAppSelector(userSelector);
  const stripeId = useAppSelector(stripeIdSelector);
  const subscriptionState = useAppSelector(subscriptionSelector);
  const activeSubscription = useAppSelector(activeSubscriptionSelector);
  const pendingSubscription = useAppSelector(pendingSubscriptionSelector);
  const ios = useAppSelector(iosSubscriptionSelector);
  const defaultCard = useAppSelector(defaultCardSelector);
  const dispatch = useAppDispatch();

  const { status, purchaseSubscriptionStatus } = subscriptionState;
  const { isMobile } = useWindowSize();
  const [defaultSubscription, setDefaultSubscription] = useState('');
  const [subscription, setSubscription] = useState<null | string>(null);
  const [subscriptionLevel, setSubscriptionLevel] = useState<null | string>(
    null
  );
  const [planDescription, setPlanDescription] = useState('');

  const selectedPlan = useMemo<SubscriptionPlan | Record<string, any>>(() => {
    if (subscription) {
      return PLANS.find((p) => p.type === subscription) || {};
    }
    return {};
  }, [subscription]);

  const selectedLevel = useMemo<LevelDetails | Record<string, any>>(() => {
    if (selectedPlan?.levels) {
      return (
        (selectedPlan as SubscriptionPlan).levels.find(
          (l) => l.uid === subscriptionLevel
        ) || {}
      );
    }

    return {};
  }, [selectedPlan, subscriptionLevel]);

  useEffect(() => {
    if (!defaultSubscription && activeSubscription?.plan) {
      const activePlan = PLANS.find(
        (plan) =>
          activeSubscription.plan.id === plan.uid ||
          plan?.levels?.some(
            (level) => level.uid === activeSubscription.plan.id
          )
      );

      if (!activePlan) return;

      if (activePlan.levels) {
        const planDetails = activePlan.levels.find(
          (plan) => plan.uid === activeSubscription.plan.id
        );
        setPlanDescription(
          `${planDetails?.type} per month for ${planDetails?.duration} min. each`
        );
      }

      setSubscription(activePlan.type);
      setDefaultSubscription(activePlan.type);
    }
  }, [defaultSubscription, activeSubscription]);

  const onSelectPlan = useCallback((planType) => {
    setSubscription(planType);
    setSubscriptionLevel(null);
  }, []);

  const disabled = useMemo(
    () =>
      !(
        subscription &&
        selectedPlan &&
        ((selectedPlan.levels && subscriptionLevel) || !selectedPlan.levels)
      ),
    [selectedPlan, subscription, subscriptionLevel]
  );

  const handleNavigationChange = (item: SettingMenuItem) => {
    history.push(item.name);
  };

  useEffect(() => {
    if (purchaseSubscriptionStatus && purchaseSubscriptionStatus === 'failed') {
      setSubscription(null);
      setSubscriptionLevel(null);
    }
  }, [purchaseSubscriptionStatus]);

  const onSubmit = useCallback(() => {
    if (selectedPlan?.type === 'Unlimited Chat') {
      dispatch(
        purchaseSubscription.action({
          stripeId,
          plan: {
            uid: selectedPlan.uid!,
            name: 'Unlimited Chat',
            price: selectedPlan.price!,
          },
        })
      );
    } else {
      if (!defaultCard) {
        Swal.fire('Error', 'Please update your billing to continue', 'error');
        return;
      }

      dispatch(
        purchaseSubscription.action({
          stripeId,
          plan: {
            uid: selectedLevel?.uid || '',
            name: selectedLevel?.name,
            price: selectedLevel?.price,
          },
        })
      );
    }
  }, [
    selectedPlan.type,
    selectedPlan.uid,
    selectedPlan.price,
    dispatch,
    stripeId,
    defaultCard,
    selectedLevel.uid,
    selectedLevel.name,
    selectedLevel.price,
  ]);

  const planSelect = useMemo(
    () => (
      <>
        <Card.Group itemsPerRow={isMobile ? 1 : 3}>
          {PLANS.map((plan) => (
            <Card key={plan.type} onClick={() => onSelectPlan(plan.type)}>
              {subscription === plan.type && (
                <Icon name="check circle" size="big" color="green" />
              )}
              <Card.Content textAlign="left">
                <Card.Header>{plan.type}</Card.Header>
                <Card.Description>{plan.description}</Card.Description>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
        <Pane height={20} />
        {selectedPlan && selectedPlan.levels && (
          <>
            <h3>How many video sessions would you like to have per month?</h3>
            <Card.Group itemsPerRow={isMobile ? 1 : 3}>
              {selectedPlan.levels.map((level: LevelDetails) => (
                <Card
                  key={level.uid}
                  onClick={() => setSubscriptionLevel(level.uid)}>
                  {subscriptionLevel === level.uid && (
                    <Icon name="check circle" size="big" color="green" />
                  )}
                  <Card.Content textAlign="left">
                    <Card.Header>{level.type}</Card.Header>
                    <Card.Meta>{`(${level.duration} min. each)`}</Card.Meta>
                    <Card.Description>
                      <BoldSpan>{`$${level.price}/mo`}</BoldSpan>
                      <span>{level.description}</span>
                    </Card.Description>
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          </>
        )}
        <Pane height={50} />
        {(selectedPlan.price || selectedLevel.price) &&
          activeSubscription &&
          !ios?.isSubscribed && (
            <h4>
              Your changes will take effect at the end of your current billing
              cycle on{' '}
              {moment(activeSubscription.current_period_end, 'X')
                .add(1, 'day')
                .format('MM/DD/YYYY')}
              . At that time you will be billed $
              {selectedPlan.price || selectedLevel.price} per month.
            </h4>
          )}
        <PrimaryButton
          onClick={onSubmit}
          primary
          loading={
            status === 'loading' || purchaseSubscriptionStatus === 'loading'
          }
          disabled={
            disabled ||
            status === 'loading' ||
            purchaseSubscriptionStatus === 'loading'
          }>
          Save
        </PrimaryButton>
      </>
    ),
    [
      activeSubscription,
      disabled,
      isMobile,
      onSelectPlan,
      onSubmit,
      purchaseSubscriptionStatus,
      selectedLevel.price,
      selectedPlan,
      status,
      subscription,
      subscriptionLevel,
    ]
  );

  console.log('pending subs', pendingSubscription);

  return (
    <AppLayout page="settings">
      <SettingsLayout
        items={USER_TABS}
        selectedItem={{ name: 'subscription' }}
        onSelectItem={handleNavigationChange}>
        {status === 'pending' && !activeSubscription ? (
          <Loader active inline>
            <br />
          </Loader>
        ) : (
          <>
            {!activeSubscription ? (
              <>
                <h4>No subscription</h4>
                <Pane height={20} />
                <StyledSubscription>{planSelect}</StyledSubscription>
              </>
            ) : ios?.isSubscribed && ios?.autoRenew ? (
              <Pane
                marginBottom="2vh"
                alignItems="center"
                flexDirection={isMobile ? 'column' : 'row'}
                display="flex">
                <Pane flex={1}>
                  <Message negative>
                    <Message.Header>Sorry</Message.Header>
                    Please manage your subscription from an iOS device.
                  </Message>
                </Pane>
              </Pane>
            ) : (
              <StyledSubscription>
                {pendingSubscription && (
                  <>
                    <Pane
                      marginBottom="2vh"
                      alignItems="center"
                      flexDirection={isMobile ? 'column' : 'row'}
                      background="redTint"
                      display="flex">
                      <Pane flex={1}>
                        <Message negative>
                          <Message.Header>Notice</Message.Header>
                          You have a pending plan upgrade (
                          {pendingSubscription?.plan?.metadata?.description})
                          that will take effect at the end of your current
                          billing cycle on{' '}
                          {moment(
                            pendingSubscription?.billing_cycle_anchor,
                            'X'
                          )
                            .add(1, 'day')
                            .format('MM/DD/YYYY')}
                          .
                        </Message>
                      </Pane>
                    </Pane>
                  </>
                )}
                {defaultSubscription && (
                  <>
                    <h3>
                      Your current subscription is {defaultSubscription}.{' '}
                      {planDescription && `(${planDescription})`}
                    </h3>
                    <h3>Select a new plan to change your subscription</h3>
                  </>
                )}
                {planSelect}
                {!ios?.isSubscribed && (
                  <Pane marginLeft={5} marginTop={20}>
                    <CancelLink
                      href={`mailto:support@joinblush.com?subject=Cancel subscription - ${user.profile.name} | ${user.profile.email}`}
                      target="_blank">
                      Cancel subscription
                    </CancelLink>
                  </Pane>
                )}
              </StyledSubscription>
            )}
          </>
        )}
      </SettingsLayout>
    </AppLayout>
  );
};

export default Subscription;
