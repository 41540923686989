import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Form, Header, Message } from 'semantic-ui-react';
import { AppLayout, Pane, SettingsLayout } from '../../../../components/layout';
import { SettingMenuItem } from '../../../../components/layout/SettingsLayout/SettingsMenu';
import { PrimaryButton } from '../../../../components/layout/SettingsLayout/styles';
import { COACH_TABS } from '../../../../constants/tabs';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { updateUser } from '../../../../redux/user/user.saga';
import { userSelector } from '../../../../redux/user/user.selectors';

type FormData = {
  welcomeMessage: string;
  weekendMessage: string;
};

const WelcomeMessage = () => {
  const history = useHistory();
  const user = useAppSelector(userSelector);
  const { status } = user;
  const { handleSubmit, control, formState } = useForm<FormData>({
    defaultValues: {
      welcomeMessage: user.welcomeMessage,
      weekendMessage: user.weekendMessage,
    },
  });
  const { errors } = formState;
  const dispatch = useAppDispatch();

  const handleNavigationChange = (item: SettingMenuItem) => {
    history.push(item.name);
  };

  return (
    <AppLayout page="settings">
      <SettingsLayout
        items={COACH_TABS}
        selectedItem={{ name: 'system-message' }}
        onSelectItem={handleNavigationChange}>
        <Message compact>
          To personalize the message with your client&apos;s name, make sure to
          include (name) in your message.
        </Message>
        <Form
          onSubmit={handleSubmit((data) =>
            dispatch(updateUser.action({ data }))
          )}>
          <Controller
            control={control}
            name="welcomeMessage"
            render={({ field: { ref, ...field } }) => (
              <Form.TextArea
                label="Welcome Message"
                placeholder="Hello (name)! I'm so excited to start this journey with you. Talk soon!"
                error={errors?.welcomeMessage?.message}
                {...field}
              />
            )}
          />
          <Pane margin="20px" />
          <Controller
            control={control}
            name="weekendMessage"
            render={({ field: { ref, ...field } }) => (
              <Form.TextArea
                label="Weekend Message"
                placeholder="This message will be sent to your new clients if they on-board on weekends. Fallback to welcome message if empty."
                error={errors?.weekendMessage?.message}
                {...field}
              />
            )}
          />
          <Pane height={20} />
          <PrimaryButton
            loading={status === 'loading'}
            disabled={status === 'loading'}
            primary
            type="submit">
            Save
          </PrimaryButton>
        </Form>
      </SettingsLayout>
    </AppLayout>
  );
};

export default WelcomeMessage;
