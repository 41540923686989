import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';
import createRootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { setFeedbackSession } from '../sessionFeedback/sessionFeedback.slice';

export const history = createBrowserHistory();
const rootReducer = createRootReducer(history);

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: [],
  // blacklist: ['router', 'app', 'auth'],
};

const reduxSyncConfig = {
  whitelist: [setFeedbackSession.type],
};

const reduxSyncMiddleware = createStateSyncMiddleware(reduxSyncConfig);

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(
  persistConfig,
  rootReducer
);

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }),
    routerMiddleware(history),
    sagaMiddleware,
    reduxSyncMiddleware,
  ],
});

initMessageListener(store);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export default store;
