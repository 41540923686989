/* eslint-disable max-classes-per-file */
import { extendMoment } from 'moment-range';
import * as Moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useHistory } from 'react-router-dom';
import { RRule, rrulestr } from 'rrule';
import { Button, Label, Message } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import { AppLayout, Pane, SettingsLayout } from '../../../../components/layout';
import { PrimaryButton } from '../../../../components/layout/SettingsLayout/styles';
import { MobileView } from '../../../../components/ui';
import { COACH_TABS } from '../../../../constants/tabs';
import useWindowSize from '../../../../hooks/useWindowSize';
import { availabilityRangesSelector } from '../../../../redux/availability/availability.selectors';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { updateUser } from '../../../../redux/user/user.saga';
import {
  userProfileSelector,
  userSelector,
} from '../../../../redux/user/user.selectors';
import Swal from '../../../../utils/Swal';

// define default timezone
const moment = extendMoment(Moment);
const timezone = moment.tz.guess();
moment.tz.setDefault(timezone);

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop<Availability>(Calendar as any);

// helper function for timezone
function momentz(...args: any) {
  return moment(...args).tz(timezone);
}

function confirm(message: string) {
  const alert = window.confirm;

  return alert(message);
}

const Availability = () => {
  const today = moment();
  const user = useAppSelector(userSelector);
  const profile = useAppSelector(userProfileSelector);
  const availabilities = useAppSelector(availabilityRangesSelector);
  const { status, takingNewClients } = user;
  const { isProfileComplete } = profile;
  const history = useHistory();
  const [pendingChanges, setPendingChanges] = useState<
    Availability[] | undefined
  >(availabilities);
  const dispatch = useAppDispatch();

  // ranges for calculated values
  const [ranges, setRanges] = useState<Availability[] | undefined>([]);
  // display range for the calendar (weekly)
  const [displayRange, setDisplayRange] = useState({
    start: today.startOf('week').toDate(),
    end: today.endOf('week').toDate(),
  });

  // const prevRanges = usePrevious(ranges);

  useEffect(() => {
    const newRanges = pendingChanges?.map((availability: Availability) => {
      const rrule = rrulestr(availability.rrule);

      return rrule
        .between(displayRange.start, displayRange.end)
        .map((start) => ({
          title: `Available ${rrule.options.until ? '' : '(Recurring)'}`,
          start,
          end: moment(start).add(availability.duration, 'minutes').toDate(),
          resource: availability.resource,
        }));
    });

    setRanges(newRanges?.flat() as Availability[] | undefined);
  }, [pendingChanges, displayRange]);

  // const syncRanges = useCallback(() => {
  //   setRanges(
  //     pendingChanges.reduce((acc, availability) => {
  //       return acc.concat(availabilityToRanges(displayRange, availability));
  //     }, [])
  //   );
  // }, [pendingChanges, displayRange]);

  // when a user creates a new slot
  const onSelectSlot = useCallback(
    async ({ start, end }) => {
      const timeRange = moment.range(start, end);
      // we want to prevent overlaps in availability calendar
      const overlap = ranges?.some((range) =>
        moment.range(range.start, range.end).overlaps(timeRange)
      );
      if (!overlap) {
        const { value: recurring, dismiss } = await Swal.fire({
          title: 'Availability',
          text: `${momentz(start).format('LT z')} ~ ${momentz(end).format(
            'LT z'
          )}`,
          input: 'checkbox',
          inputValue: 0,
          inputPlaceholder: `Recur every ${momentz(start).format('dddd')}?`,
          confirmButtonText: 'Confirm',
          showCancelButton: true,
        });
        if (dismiss !== Swal.DismissReason.cancel) {
          const availability = {
            start,
            duration: timeRange.diff('minutes'),
            resource: uuid(),
          } as Availability;
          const rrule = new RRule({
            freq: RRule.WEEKLY,
            dtstart: start,
            until: !recurring
              ? moment(start).add(timeRange.diff('minutes'), 'minutes').toDate()
              : undefined,
          });
          availability.rrule = rrule.toString();
          setPendingChanges([
            ...(pendingChanges as Availability[]),
            availability,
          ]);
        }
      }
    },
    [pendingChanges, ranges, setPendingChanges]
  );

  // when user moves or resizes an availability
  const onResizeSlot = useCallback(
    ({ event, start, end }) => {
      const timeRange = moment.range(start, end);
      const overlap = ranges
        ?.filter((range: Availability) => range.resource !== event.resource)
        .some((range: Availability) =>
          moment
            .range(range.start, range.end)
            .overlaps(moment.range(start, end))
        );

      if (!overlap) {
        const newChanges = pendingChanges?.map((change: Availability) => {
          if (change.resource !== event.resource) return change;

          const oldRule = rrulestr(change.rrule);
          const rrule = new RRule({
            freq: RRule.WEEKLY,
            dtstart: start,
            until: oldRule.options.until
              ? moment(start).add(timeRange.diff('minutes'), 'minutes').toDate()
              : undefined,
          }).toString();

          return {
            ...change,
            start,
            duration: timeRange.diff('minutes'),
            rrule,
          };
        });

        setPendingChanges(newChanges);
      }
    },
    [pendingChanges, ranges]
  );

  // select to delete
  const onSelectEvent = useCallback(
    (event) => {
      const toDelete = confirm('Are you sure you want to delete?');
      if (toDelete) {
        setPendingChanges(
          pendingChanges?.filter(
            (range: Availability) => range.resource !== event.resource
          )
        );
      }
    },
    [pendingChanges]
  );

  // used as a helper to update display range
  const onRangeChange = useCallback((range) => {
    setDisplayRange({
      start: range[0],
      // End of Day for the last day in range
      end: moment(range[6]).endOf('day').toDate(),
    });
  }, []);

  const handleNavigationChange = useCallback(
    (item) => {
      history.push(item.name);
    },
    [history]
  );

  const handleUpdateUser = useCallback(() => {
    dispatch(
      updateUser.action({
        data: {
          'availability.ranges': pendingChanges,
        },
      })
    );
  }, [dispatch, pendingChanges]);

  const handleTakingNewClients = useCallback(() => {
    dispatch(
      updateUser.action({
        data: {
          takingNewClients: !takingNewClients,
        },
      })
    );
  }, [dispatch, takingNewClients]);

  const { isMobile } = useWindowSize();

  return (
    <AppLayout page="settings">
      <SettingsLayout
        items={COACH_TABS}
        selectedItem={{ name: 'availability' }}
        onSelectItem={handleNavigationChange}>
        <Message content="Drag on the calendar to select your desired availability. Click Save to finalize your changes." />
        <Pane overflowX="scroll">
          <Pane minWidth="800px">
            <DragAndDropCalendar
              selectable
              localizer={localizer}
              events={ranges}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              onView={onRangeChange}
              onNavigate={onRangeChange}
              onRangeChange={onRangeChange}
              onSelectEvent={onSelectEvent}
              onSelectSlot={onSelectSlot}
              onEventResize={onResizeSlot}
              onEventDrop={onResizeSlot}
              defaultView={Views.WEEK}
              views={{
                week: true,
              }}
            />
          </Pane>
        </Pane>
        <Pane height={20} />
        <PrimaryButton
          loading={status === 'loading'}
          disabled={status === 'loading'}
          onClick={handleUpdateUser}
          primary>
          Save
        </PrimaryButton>
        <MobileView>
          <Pane height={20} />
        </MobileView>
        <PrimaryButton floated={isMobile ? null : 'right'} labelPosition="left">
          <Label basic pointing="right">
            Taking New Clients
          </Label>
          <Button
            as="div"
            disabled={!isProfileComplete}
            onClick={handleTakingNewClients}>
            {takingNewClients ? 'Yes' : 'No'}
          </Button>
        </PrimaryButton>
      </SettingsLayout>
    </AppLayout>
  );
};

export default Availability;
