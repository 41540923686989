import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { logout } from '../auth/auth.saga';
import { SessionFeedbackState } from '../store/types';
import { createSessionFeedback } from './sessionFeedback.saga';

const initialState = {
  status: 'pending',
  session: null,
} as SessionFeedbackState;

const sessionFeedbackSlice = createSlice({
  name: 'sessionFeedback',
  initialState,
  reducers: {
    setFeedbackSession: (
      state,
      action: PayloadAction<Session | null | undefined>
    ) => {
      state.session = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
    }));
    builder.addCase(createSessionFeedback.fulfilled, (state) => {
      state.status = 'success';
      state.session = null;
    });
    builder.addMatcher(isAnyOf(createSessionFeedback.pending), (state) => {
      state.status = 'loading';
    });
    builder.addMatcher(isAnyOf(createSessionFeedback.rejected), (state) => {
      state.status = 'failed';
    });
  },
});

export const { setFeedbackSession } = sessionFeedbackSlice.actions;

export default sessionFeedbackSlice.reducer;
