import Collapse from '@kunukn/react-collapse';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Icon } from 'semantic-ui-react';
import { Pane, WizardLayout } from '../../../../components/layout';
import {
  BitterSweetSpan,
  Button,
  WizBtnGroup,
  WizCard,
  WizContent,
  WizSubtitle,
  WizTitle,
} from '../../../../components/layout/WizardLayout/styles';
import { PLANS } from '../../../../config/constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  selectedSubscriptionLevelSelector,
  selectedSubscriptionSelector,
} from '../../../../redux/subscription/subscription.selectors';
import { selectSubscriptionLevel } from '../../../../redux/subscription/subscription.slice';

const SessionPlanLevel: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const selectedSubscription = useAppSelector(selectedSubscriptionSelector);
  const selectedSubscriptionLevel = useAppSelector(
    selectedSubscriptionLevelSelector
  );

  const plan = PLANS.find((p) => p.type === selectedSubscription);

  const planLevelDescription = useCallback(
    (level) => {
      if (selectedSubscriptionLevel === level.uid) {
        return (
          <Card.Description>
            <BitterSweetSpan>{`$${level.price}/mo`}</BitterSweetSpan>
            <span>{level.description}</span>
          </Card.Description>
        );
      }
      return <></>;
    },
    [selectedSubscriptionLevel]
  );

  return (
    <WizardLayout sideBanner="onboarding_img_3">
      <WizContent textAlign="left">
        <WizTitle>Awesome</WizTitle>
        <WizSubtitle>
          How many video sessions would you like to have per month?
        </WizSubtitle>
        <Pane height="40px" />
        <Pane maxWidth="30rem">
          <Card.Group stackable itemsPerRow={1}>
            {plan &&
              plan.levels.map((level) => (
                <WizCard
                  key={level.uid}
                  selected={selectedSubscriptionLevel === level.uid}
                  onClick={() => dispatch(selectSubscriptionLevel(level.uid))}
                  $whiteHighlightedTitle>
                  {selectedSubscriptionLevel === level.uid && (
                    <Icon name="check circle" size="big" color="green" />
                  )}
                  <Card.Content textAlign="left">
                    <Card.Header>{level.type}</Card.Header>
                    <Card.Meta>{`(${level.duration} min. each)`}</Card.Meta>
                    <Collapse isOpen={selectedSubscriptionLevel === level.uid}>
                      {planLevelDescription(level)}
                    </Collapse>
                  </Card.Content>
                </WizCard>
              ))}
          </Card.Group>
        </Pane>
      </WizContent>
      <WizBtnGroup>
        <Button
          basic
          color="black"
          size="big"
          style={{ marginRight: 12, minWidth: '8rem' }}
          onClick={() => history.push('/onboarding/session-plan')}>
          Back
        </Button>
        <Button
          icon
          labelPosition="right"
          size="big"
          color="black"
          disabled={!selectedSubscriptionLevel}
          // onClick={() => history.push('/onboarding/summary')}>
          onClick={() => history.push('/onboarding/option-choose-coach')}>
          Continue
          <Icon name="chevron right" />
        </Button>
      </WizBtnGroup>
    </WizardLayout>
  );
};

export default SessionPlanLevel;
