import styled from 'styled-components';
import { Card } from 'semantic-ui-react';

// eslint-disable-next-line import/prefer-default-export
export const StyledClientCard = styled(Card)`
  && {
    .image > img {
      max-height: 400px;
      object-fit: cover;
    }
  }
`;
