import moment from 'moment';
import firebase from 'firebase';

const converter = {
  toFirestore: (data: AvailabilityBlock) => data,
  fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) =>
    snap.data() as AvailabilityBlock,
};

const availabilityCollection = firebase
  .firestore()
  .collection('availability')
  .withConverter(converter);

const formatBlockList30 = (blocks: Block[]): FormattedBlock[] =>
  blocks.map((block) => {
    const { dateUTC, start, end, status } = block;

    return {
      dateUTC,
      start,
      end,
      status,
      blocks: [block],
    };
  });

const formatBlockList60 = (blocks: Block[]) =>
  blocks
    .reduce((blocksFormatted: FormattedBlock[], currentBlock: Block) => {
      const { dateUTC, start, end, status } = currentBlock;
      const matchedBlock = blocks.find((block) => {
        const blockToFind = moment(parseInt(dateUTC, 10))
          .add(30, 'minutes')
          .format('x');

        return parseInt(block.dateUTC, 10) === parseInt(blockToFind, 10);
      });

      if (matchedBlock?.status === 'available') {
        return [
          ...blocksFormatted,
          {
            dateUTC,
            start,
            end,
            status,
            blocks: [currentBlock, matchedBlock],
          },
        ];
      }

      return blocksFormatted;
    }, [])
    .filter((el) => el !== null);

// eslint-disable-next-line import/prefer-default-export
export const coachAvailabilityObserver = (
  emitter: (payload: { blocks: FormattedBlock[] } | Error) => void,
  data: { uid: string; duration: number }
) => {
  const { uid: coachUid, duration } = data;

  if (!coachUid) throw new Error('Missing coachId');

  return availabilityCollection.doc(coachUid).onSnapshot(
    (snapshot) => {
      if (!snapshot.exists) {
        return emitter({ blocks: [] });
      }

      const maxDay = moment().add(30, 'days');
      const snapshotBlocks = Object.values(snapshot.data()!.blocks).filter(
        (block) => {
          if (block.status !== 'available') return false;

          const utcToDate = moment(parseInt(block.dateUTC, 10)).format(
            'YYYY-MM-DD'
          );

          const blockStartDate = moment(utcToDate).toDate();

          return moment(blockStartDate).isBefore(maxDay);
        }
      );

      const formattedBlocks =
        duration === 30
          ? formatBlockList30(snapshotBlocks)
          : formatBlockList60(snapshotBlocks);

      const blocks = formattedBlocks
        .map((block) => {
          const referenceBlocks = block.blocks.map((refBlock) => ({
            ...refBlock,
            start: moment(block.start.seconds * 1000).toDate(),
            end: moment(block.end.seconds * 1000).toDate(),
          }));

          return {
            ...block,
            start: moment(block.start.seconds * 1000).toDate(),
            end: moment(block.end.seconds * 1000).toDate(),
            blocks: referenceBlocks,
          };
        })
        .sort((a, b) => parseInt(a.dateUTC, 10) - parseInt(b.dateUTC, 10));

      return emitter({ blocks });
    },
    (err) => {
      console.log(err);
      return emitter(new Error(err.message));
    }
  );
};
