import firebase from 'firebase';

const converter = {
  toFirestore: (data: UserPublic) => data,
  fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) =>
    snap.data() as UserPublic,
};

const usersPublicCollection = firebase
  .firestore()
  .collection('usersPublic')
  .withConverter(converter);

export const getAllClients = async () => {
  const response = await firebase.functions().httpsCallable('getAllClients')();

  return response.data;
};

export const clientsObserver = (
  emitter: (users: UserPublic[] | Error) => void
) => {
  const { currentUser } = firebase.auth();

  return usersPublicCollection
    .where('coach.uid', '==', currentUser!.uid)
    .onSnapshot(
      (snapshot) => {
        if (snapshot.empty) {
          return emitter([]);
        }

        const clients = snapshot.docs.map((doc) => ({
          ...doc.data(),
          uid: doc.id,
        }));

        return emitter(clients);
      },
      (error) => {
        console.log('Clients Observer Error', error);
        return emitter(new Error(error.message));
      }
    );
};
