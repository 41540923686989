import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Icon } from 'semantic-ui-react';
import { Pane, WizardLayout } from '../../../../components/layout';
import {
  Button,
  WizBtnGroup,
  WizCard,
  WizContent,
  WizSubtitle,
  WizText,
  WizTitle,
} from '../../../../components/layout/WizardLayout/styles';
import { AVAILABILITY_PREFERENCE } from '../../../../config/constants';
import { availabilitySelector } from '../../../../redux/availability/availability.selectors';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { updateUser } from '../../../../redux/user/user.saga';
import { userSelector } from '../../../../redux/user/user.selectors';

const Availability: React.FC = () => {
  const history = useHistory();
  const user = useAppSelector(userSelector);
  const availability = useAppSelector(availabilitySelector);
  const dispatch = useAppDispatch();
  const { days, sessionLength } = availability;
  const [selectedLength, setSelectedLength] = useState(sessionLength);
  const [selectedDays, setSelectedDays] = useState(days);

  const onClickLength = (duration: SessionLength) => {
    console.log(duration);
    if (selectedLength.includes(duration)) {
      setSelectedLength(selectedLength.filter((lng) => lng !== duration));
    } else {
      setSelectedLength([...selectedLength, duration]);
    }
  };

  const onClickDays = (day: Days) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const onUpdateAvailability = () => {
    dispatch(
      updateUser.action({
        data: {
          'availability.days': selectedDays,
          'availability.sessionLength': selectedLength,
        },
        meta: {
          onboarding: true,
          successPath: '/onboarding/choose-coach',
        },
      })
    );
  };

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <WizardLayout sideBanner="onboarding_img_3">
      <WizContent textAlign="left">
        <WizTitle>One more step.</WizTitle>
        <Pane height="20px" />
        <WizText>
          What time(s) are you usually available? We use this to make sure
          <br /> you have plenty of time to connect with your coach. (choose all
          that apply)
        </WizText>
        <Pane height="40px" />
        <Card.Group stackable itemsPerRow={3}>
          {AVAILABILITY_PREFERENCE.sessionDurations.map((duration) => (
            <WizCard
              key={duration}
              onClick={() => onClickLength(duration)}
              $whiteHighlightedTitle
              type="single"
              selected={selectedLength.includes(duration)}>
              {selectedLength.includes(duration) && (
                <Icon name="check circle" size="large" color="green" />
              )}
              <Card.Header>{duration}</Card.Header>
            </WizCard>
          ))}
        </Card.Group>
        <Pane height="20px" />
        <WizSubtitle>Weekend or weekdays?</WizSubtitle>
        <Pane height="4px" />
        <Card.Group stackable itemsPerRow={3}>
          {AVAILABILITY_PREFERENCE.daysOfWeek.map((dayOfWeek) => (
            <WizCard
              key={dayOfWeek}
              onClick={() => onClickDays(dayOfWeek)}
              $whiteHighlightedTitle
              type="single"
              selected={selectedDays.includes(dayOfWeek)}>
              {selectedDays.includes(dayOfWeek) && (
                <Icon name="check circle" size="large" color="green" />
              )}
              <Card.Header>{dayOfWeek}</Card.Header>
            </WizCard>
          ))}
        </Card.Group>
      </WizContent>
      <WizBtnGroup>
        <Button
          basic
          color="black"
          size="big"
          style={{ marginRight: 12 }}
          onClick={goBack}>
          Back
        </Button>
        <Button
          icon
          labelPosition="right"
          size="big"
          color="black"
          loading={user.status === 'loading'}
          disabled={user.status === 'loading'}
          onClick={onUpdateAvailability}>
          Continue
          <Icon name="chevron right" />
        </Button>
      </WizBtnGroup>
    </WizardLayout>
  );
};

export default Availability;
