/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAsyncSaga } from 'create-async-saga';
import { eventChannel } from 'redux-saga';
import {
  cancel,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';
import * as Firebase from '../../services/firebase';
import * as authTypes from '../auth/auth.actionTypes';
import * as types from './clients.actionTypes';

function* observeClients(): any {
  const channel = eventChannel((emitter) => {
    const observer = Firebase.clientsObserver(emitter);
    return () => observer();
  });

  while (true) {
    try {
      const response = yield take(channel);
      yield put({
        type: getClients.fulfilled.type,
        payload: response,
      });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

export const getClients = createAsyncSaga(types.GET_CLIENTS, function* () {
  const observer = yield fork(observeClients);
  yield take(authTypes.LOGOUT);
  yield cancel(observer);
});

export default function* clientsSaga() {
  yield takeLatest(getClients.actionType, getClients.asyncSaga);
}
