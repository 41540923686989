import firebase from 'firebase';
import moment from 'moment';

const converter = {
  toFirestore: (data: Session) => data,
  fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) =>
    snap.data() as Session,
};

const sessionsCollection = firebase
  .firestore()
  .collection('sessions')
  .withConverter(converter);

/**
 * Get coach availability
 */
export const getCoachAvailabilityBlocks = async (coachId: string) => {
  if (!coachId) throw new Error('Missing coachId');

  const blocksData = await firebase
    .functions()
    .httpsCallable('getAvailableBlocks')(coachId);
  const {
    data: { blocks },
  } = blocksData;

  const response = {
    blocks: blocks
      .map((block: FormattedBlock) => {
        const referenceBlocks = block.blocks.map((refBlock: Block) => ({
          ...refBlock,
          // eslint-disable-next-line no-underscore-dangle
          start: moment(block.start._seconds * 1000).toDate(),
          // eslint-disable-next-line no-underscore-dangle
          end: moment(block.end._seconds * 1000).toDate(),
        }));

        return {
          ...block,
          // eslint-disable-next-line no-underscore-dangle
          start: moment(block.start._seconds * 1000).toDate(),
          // eslint-disable-next-line no-underscore-dangle
          end: moment(block.end._seconds * 1000).toDate(),
          blocks: referenceBlocks,
        };
      })
      .sort(
        (a: FormattedBlock, b: FormattedBlock) =>
          parseInt(a.dateUTC, 10) - parseInt(b.dateUTC, 10)
      ),
  };

  return response as { blocks: FormattedBlock[] };
};

/**
 * Sessions observer
 */
export const sessionsObserver = (
  emitter: (sessions: Session[] | Error) => void,
  role: Role
) => {
  const { currentUser } = firebase.auth();

  const fieldPath = role === 'coach' ? 'coachUid' : 'userUid';
  return sessionsCollection.where(fieldPath, '==', currentUser?.uid).onSnapshot(
    (snapshot) => {
      if (snapshot.empty) {
        console.log('No matching video sessions found.');
        return emitter([]);
      }

      const sessions = snapshot.docs
        .map((doc) => ({ ...doc.data(), uid: doc.id }))
        .filter((session) => session !== null && session.status !== 'CANCELED');

      console.log('*** SESSIONS BEING EMITTED! ***', sessions);
      return emitter(sessions);
    },
    (error) => {
      console.log('Sessions Observer Error', error);
      return emitter(new Error(error.message));
    }
  );
};

/**
 * Session observer
 */
export const sessionObserver = (
  emitter: (session: Record<string, unknown> | Session | Error) => void,
  sessionId: string
) =>
  sessionsCollection.doc(sessionId).onSnapshot(
    (snapshot) => {
      if (!snapshot.exists) return emitter({});
      return emitter(snapshot.data()!);
    },
    (error) => {
      console.log('Session Observer Error', error);
      return emitter(new Error(error.message));
    }
  );

/**
 * Get sessions
 */
export const getSessions = async () => {
  const { currentUser } = firebase.auth();
  const sessions = await sessionsCollection
    .where('userUid', '==', currentUser?.uid)
    .get();

  if (sessions.empty) {
    console.log('No matching video sessions found.');
    return [];
  }

  return sessions.docs
    .map((doc) => ({ ...doc.data(), uid: doc.id }))
    .filter(
      (session) =>
        Boolean(session.openTok) &&
        session.status !== 'CANCELED' &&
        session !== null
    );
};
/**
 * Create a session
 */
export const createSession = (session: {
  coach: string;
  block: any;
  duration: SessionDuration;
}) => {
  const { currentUser } = firebase.auth();

  try {
    const { coach, block, duration } = session;
    const docUid = `${coach}_${currentUser?.uid}_${
      block.dateUTC
    }_${Date.now()}`;

    console.log('DOC UID', docUid);

    const data = {
      block,
      duration,
      coachUid: coach,
      userUid: currentUser?.uid as string,
      sessionFirebaseUid: docUid,
    };

    // return Sessions.addDoc(data);
    return sessionsCollection.doc(docUid).set({
      ...data,
      created_at: firebase.firestore.Timestamp.now(),
      updated_at: firebase.firestore.Timestamp.now(),
    });
  } catch (err: any) {
    console.error(err);
    throw err;
  }
};

/**
 * Cancel a session
 */
export const cancelSession = (sessionId: string) => {
  if (!sessionId) throw new Error('No sessionId');

  return firebase
    .functions()
    .httpsCallable('cancelSession')(sessionId)
    .then((result) => console.log('cancel session result', result));
};

export const updateSessionStatus = firebase
  .functions()
  .httpsCallable('updateSessionStatus');
