import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import * as yup from 'yup';
import { Button, Link } from '../../../../components/ui';
import { VALIDATION_MESSAGES } from '../../../../config/constants';
import { authSelector } from '../../../../redux/auth/auth.selectors';
import { useAppSelector } from '../../../../redux/store';

type FormData = {
  email: string;
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email(VALIDATION_MESSAGES.email)
    .required(VALIDATION_MESSAGES.required),
});

const ResetPasswordForm: React.FC<{ onSubmit: (data: FormData) => void }> = ({
  onSubmit,
}) => {
  const auth = useAppSelector(authSelector);
  const { handleSubmit, control, formState } = useForm<FormData>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  return (
    <Form size="big" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="email"
        render={({ field: { ref, ...field } }) => (
          <Form.Input
            label="Email Address"
            error={errors?.email?.message}
            {...field}
          />
        )}
      />
      <Button
        primary
        type="submit"
        loading={auth.status === 'loading'}
        fluid
        size="huge">
        Reset password
      </Button>
      <Form.Field style={{ marginTop: 16, textAlign: 'center', fontSize: 14 }}>
        Ready to log in? &nbsp;
        <Link to="/login">Log in</Link>
      </Form.Field>
    </Form>
  );
};

export default ResetPasswordForm;
