import styled from 'styled-components';

import { metrics } from '../../../theme';

export const LeftSide = styled.div`
  width: 62%;
  padding: 24px 24px 24px 4px;
  @media (min-width: ${metrics.device_size.mobile_s}) and (max-width: ${metrics
      .device_size.tablet}) {
    width: 100%;
    padding: 24px 12px 24px 4px;
  }
`;

export const RightSide = styled.div`
  width: 38%;
  padding: 0 4px 0 12px;
  @media (min-width: ${metrics.device_size.mobile_s}) and (max-width: ${metrics
      .device_size.tablet}) {
    width: 100%;
    padding: 24px 12px 24px 4px;
  }
`;
