import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

import { colors } from '../../../theme';

const StyledInput = styled(Input)`
  &&&& input {
    border: 1px solid ${colors.silver};
    border-radius: 10px;
    color: ${colors.nightRider};
    font-size: 16px;
    padding: 16px 24px;

    :focus {
      border-radius: 10px;
    }
  }
`;

export default StyledInput;
