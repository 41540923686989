import React, { FC, ReactNode, useEffect, useMemo } from 'react';

import { DASHBOARD_CONTENT } from '../../../config/constants';

import {
  Announcements,
  Calendar as UserCalendar,
  MyCoach,
  QuoteOfTheDay,
  TipOfTheWeek,
  UpcomingSessions,
} from '../../ui';

import { LeftSide, RightSide } from './styles';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { getContent } from '../../../redux/app/app.saga';
import { appSelector } from '../../../redux/app/app.selectors';

interface Props {
  children: ReactNode;
  coach?: Coach;
  role: string;
  sessions: Sessions;
  upcomingSessions: Sessions;
  onSetSessionId: (id: string | undefined) => void;
}

const DashboardLayout: FC<Props> = ({
  coach,
  role,
  children,
  upcomingSessions,
  sessions,
  onSetSessionId,
}) => {
  const dispatch = useAppDispatch();
  const app = useAppSelector(appSelector);

  useEffect(() => {
    dispatch(getContent.action());
  }, [dispatch]);

  const calendarSessions = useMemo(
    () => sessions.map(({ block }) => block.start.toDate()),
    [sessions]
  );

  const quoteOfTheDay = useMemo(
    () =>
      app.dashboardContent ? (
        <QuoteOfTheDay
          quote={
            app?.dashboardContent?.quote?.quote ??
            DASHBOARD_CONTENT.QUOTE_OF_THE_DAY.text
          }
          author={
            app?.dashboardContent?.quote?.author ??
            DASHBOARD_CONTENT.QUOTE_OF_THE_DAY.author
          }
        />
      ) : (
        <></>
      ),
    [app.dashboardContent]
  );

  const tipOfTheWeek = useMemo(
    () =>
      app.dashboardContent ? (
        <TipOfTheWeek
          tip={app?.dashboardContent?.tip ?? DASHBOARD_CONTENT.TIP_OF_THE_WEEK}
        />
      ) : (
        <></>
      ),
    [app.dashboardContent]
  );

  const announcements = useMemo(
    () =>
      role === 'user' && app.dashboardContent ? (
        <Announcements
          announcements={
            app?.dashboardContent?.announcements ??
            DASHBOARD_CONTENT?.ANNOUNCEMENTS
          }
        />
      ) : (
        <></>
      ),
    [app.dashboardContent, role]
  );

  return (
    <>
      <LeftSide>{children}</LeftSide>
      <RightSide>
        {announcements}

        {quoteOfTheDay}

        <UpcomingSessions
          sessions={upcomingSessions}
          onSetSessionId={onSetSessionId}
        />
        {tipOfTheWeek}

        {role === 'user' && coach && <MyCoach coach={coach} />}

        <UserCalendar calendarSessions={calendarSessions} />
      </RightSide>
    </>
  );
};

export default DashboardLayout;
