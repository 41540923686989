import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '../store';

// eslint-disable-next-line import/prefer-default-export
export const userSelector = (state: ApplicationState) => state.user;

export const isProfileCompleteSelector = createSelector(
  userSelector,
  (user) => !!user.profile?.isProfileComplete
);

export const userProfileSelector = createSelector(
  userSelector,
  (user) => user.profile || {}
);
