import { Plan } from '../types/plan';

const plans: Plan[] = [
  {
    sku: 'V_2_30',
    category: 'video',
    name: 'Video - 2 - 30',
    id: 'price_1Mm0Vm2tjBa8SBT2Wt0DX81p',
    iapProductID: 'video_2_30',
    price: 119,
    displayPrice: '$119/mo',
    details: {
      chat: false,
      video: true,
      credits: 2,
      duration: 30,
    },
    description:
      'Check in with your coach twice a month to maintain positive results.',
  },
  {
    sku: 'V_4_30',
    category: 'video',
    name: 'Video - 4 - 30',
    id: 'price_1Mm0l92tjBa8SBT2BhNtpwFs',
    iapProductID: 'video_4_30',
    price: 199,
    displayPrice: '$199/mo',
    details: {
      chat: false,
      video: true,
      credits: 4,
      duration: 30,
    },
    description:
      'Speak with your coach weekly to stay on track with goals and create positive change.',
  },
  {
    sku: 'V_4_60',
    category: 'video',
    name: 'Video - 4 - 60',
    id: 'price_1Mm10t2tjBa8SBT2Qhj5nRQX',
    iapProductID: 'video_4_60',
    price: 379,
    displayPrice: '$379/mo',
    details: {
      chat: false,
      video: true,
      credits: 4,
      duration: 60,
    },
    description:
      'Meet with your coach weekly to start your self discovery journey and see real results.',
  },
  {
    sku: 'VC_2_30',
    category: 'videoChat',
    name: 'Video Chat - 2 - 30',
    id: 'price_1Mm0tj2tjBa8SBT24OQCGLSR',
    iapProductID: 'video_chat_2_30',
    price: 199,
    displayPrice: '$199/mo',
    details: {
      chat: true,
      video: true,
      credits: 2,
      duration: 30,
    },
    description:
      'Unlimited chat on weekdays, plus two face-to-face sessions with your coach.',
  },
  {
    sku: 'VC_4_30',
    category: 'videoChat',
    name: 'Video Chat - 4 - 30',
    id: 'price_1Mm0wi2tjBa8SBT21NHgdtO9',
    iapProductID: 'video_chat_4_30',
    price: 279,
    displayPrice: '$279/mo',
    details: {
      chat: true,
      video: true,
      credits: 4,
      duration: 30,
    },
    description:
      'Unlimited chat on weekdays, plus four face-to-face sessions with your coach.',
  },
  {
    sku: 'VC_4_60',
    category: 'videoChat',
    name: 'Video Chat - 4 - 60',
    id: 'price_1Mm0yx2tjBa8SBT22JGlYeee',
    iapProductID: 'video_chat_4_60',
    price: 449,
    displayPrice: '$449/mo',
    details: {
      chat: true,
      video: true,
      credits: 4,
      duration: 60,
    },
    description:
      'Unlimited chat on weekdays, plus four hour-long sessions with your coach.',
  },
];

export default plans;
