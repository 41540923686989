/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAsyncSaga } from 'create-async-saga';
import { eventChannel } from 'redux-saga';
import {
  call,
  cancelled,
  put,
  race,
  take,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as Firebase from '../../services/firebase';
import Swal from '../../utils/Swal';
import * as authTypes from '../auth/auth.actionTypes';
import * as types from './notifications.actionTypes';

export const getNotifications: any = createAsyncSaga(
  types.GET_NOTIFICATIONS,
  function* () {
    const channel = eventChannel((emitter) => {
      const observer = Firebase.notificationsObserver(emitter);
      return () => observer();
    });

    while (true) {
      try {
        const { logoutAction, response } = yield race({
          logoutAction: take(authTypes.LOGOUT),
          response: take(channel),
        });

        if (logoutAction) {
          console.log('Stopping notifications observer');
          channel.close();
        } else {
          yield put({
            type: getNotifications.fulfilled.type,
            payload: response,
          });
        }
      } finally {
        if (yield cancelled()) {
          channel.close();
        }
      }
    }
  }
);

export const getNotificationSettings = createAsyncSaga<NotificationSettings>(
  types.GET_NOTIFICATION_SETTINGS,
  function* () {
    const response = yield call(Firebase.getNotificationSettings);
    return response;
  }
);

export const updateNotificationSettings = createAsyncSaga(
  types.UPDATE_NOTIFICATION_SETTINGS,
  function* (data: { settings: NotificationSettings }) {
    try {
      const response = yield call(Firebase.updateNotificationSettings, data);
      yield Swal.fire({
        icon: 'success',
        title: 'Changes are updated.',
      });
      return response;
    } catch (err: any) {
      yield Swal.fire({
        icon: 'error',
        title: err.message,
      });
      throw err;
    }
  }
);

export const readNotification = createAsyncSaga(
  types.READ_NOTIFICATION,
  function* (data: { notificationId: string }) {
    const response = yield call(Firebase.readNotification, data);
    return response;
  }
);

export const updateWebPushToken = createAsyncSaga(
  types.UPDATE_WEB_PUSH_TOKEN,
  function* (userId: string) {
    const response = yield call(Firebase.updateWebPushToken, userId);
    return response;
  }
);

export default function* notificationsSaga() {
  yield takeLeading(getNotifications.actionType, getNotifications.asyncSaga);
  yield takeLatest(
    getNotificationSettings.actionType,
    getNotificationSettings.asyncSaga
  );
  yield takeLatest(
    updateNotificationSettings.actionType,
    updateNotificationSettings.asyncSaga
  );
  yield takeLatest(readNotification.actionType, readNotification.asyncSaga);
  yield takeLatest(updateWebPushToken.actionType, updateWebPushToken.asyncSaga);
}
