/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
// import { Header, Container } from 'semantic-ui-react';
// import { Pane } from 'evergreen-ui';

function PrivacyPolicy() {
  return (
    <div style={{ textAlign: 'justify' }}>
      {/* <Container textAlign="justified"> */}
      {/* <Pane height="24px" />
      <Header as="h2">Privacy Policy</Header>
      <Header as="h4">1. Introduction</Header> */}
      <p>
        Blush Enterprises, LLC, a Texas limited liability company (“Company” or
        ”We”), respect your privacy and are committed to protecting it through
        our compliance with this policy. This policy describes the types of
        information we may collect from you or that you may provide when you
        visit the website www.joinblush.com (our “Website”) and our practices
        for collecting, using, maintaining, protecting and disclosing that
        information. This policy applies to information we collect: - On this
        Website. - In e-mail, text and other electronic messages between you and
        this Website. - In intake and preclearance forms submitted by you. -
        When you interact with our advertising and applications on third-party
        websites and services, if those applications or advertising include
        links to this policy. - All communications between you and Blush coaches
        or personnel whether through videoconference or written communication It
        does not apply to information collected by: - through any other means,
        including on any other website operated by Company or any third party;
        or - any third party, including through any application or content
        (including advertising) that may link to or be accessible from or on the
        Website. Please read this policy carefully to understand our policies
        and practices regarding your information and how we will treat it. If
        you do not agree with our policies and practices, your choice is not to
        use our Website. By accessing or using this Website, you agree to this
        privacy policy. This policy may change from time to time. Your continued
        use of this Website after we make changes is deemed to be acceptance of
        those changes, so please check the policy periodically for updates.
      </p>
      {/* <Header as="h4">2. Children Under the Age of 13</Header> */}
      <p>
        Our Website is not intended for children under 13 years of age. No one
        under age 13 may provide any information to the Website. We do not
        knowingly collect personal information from children under 13. If you
        are under 13, do not use or provide any information on this Website or
        on or through any of its features/register on the Website, make any
        purchases through the Website, use any of the interactive or public
        comment features of this Website or provide any information about
        yourself to us, including your name, address, telephone number, e-mail
        address or any screen name or user name you may use. If we learn we have
        collected or received personal information from a child under 13 without
        verification of parental consent, we will delete that information. If
        you believe we might have any information from or about a child under
        13, please contact us at info@joinblush.com.
      </p>
      {/* <Header as="h4">
        3. Information We Collect About You and How We Collect It
      </Header> */}
      <p>
        We collect several types of information from and about users of our
        Website, including information: - by which you may be personally
        identified, such as name, postal address, e-mail address, telephone
        number or ANY OTHER INFORMATION THE WEBSITE COLLECTS THAT IS DEFINED AS
        PERSONAL OR PERSONALLY IDENTIFIABLE INFORMATION UNDER AN APPLICABLE LAW
        (“personal information”); - about your internet connection, the
        equipment you use to access our Website and usage details. - Payment
        information We collect this information: - Directly from you when you
        provide it to us. - Automatically as you navigate through the site.
        Information collected automatically may include usage details, IP
        addresses and information collected through cookies and other tracking
        technologies.
      </p>
      {/* <Header as="h4">
        4. Information You Provide to Us. The information we collect on or
        through our Website may include:
      </Header> */}
      <p>
        - Information that you provide by filling in forms on our Website. This
        includes information provided at the time of registering to use our
        Website, subscribing to our service, posting material or requesting
        further services. We may also ask you for information when you enter a
        contest or promotion sponsored by us, and when you report a problem with
        our Website. - Records and copies of your correspondence (including
        e-mail addresses), if you contact us. - Your responses to surveys that
        we might ask you to complete for research purposes. - Details of
        transactions you carry out through our Website and of the fulfillment of
        your orders. You may be required to provide financial information before
        placing an order through our Website. - Information communicated by you
        to Blush coaches or personnel. You also may provide information to be
        published or displayed (hereinafter, “posted”) on public areas of the
        Website, or transmitted to other users of the Website or third parties
        (collectively, “User Contributions”). Your User Contributions are posted
        on and transmitted to others at your own risk. Please be aware that no
        security measures are perfect or impenetrable. Additionally, we cannot
        control the actions of other users of the Website with whom you may
        choose to share your User Contributions. Therefore, we cannot and do not
        guarantee that your User Contributions will not be viewed by
        unauthorized persons.
      </p>
      {/* <Header as="h4">
        5. Information We Collect Through Automatic Data Collection
        Technologies. As you navigate through and interact with our Website, we
        may use automatic data collection technologies to collect certain
        information about your equipment, browsing actions and patterns,
        including:
      </Header> */}
      <p>
        - Details of your visits to our Website, including traffic data,
        location data, logs and other communication data and the resources that
        you access and use on the Website. - Information about your computer and
        internet connection, including your IP address, operating system and
        browser type. The information we collect automatically is statistical
        data and may include personal information, or we may maintain it or
        associate it with personal information we collect in other ways or
        receive from third parties. It helps us to improve our Website and to
        deliver a better and more personalized service, including by enabling us
        to: - Estimate our audience size and usage patterns. - Store information
        about your preferences, allowing us to customize our Website according
        to your individual interests. - Speed up your searches. - Recognize you
        when you return to our Website. The technologies we use for this
        automatic data collection may include: - Cookies (or browser cookies). A
        cookie is a small file placed on the hard drive of your computer. You
        may refuse to accept browser cookies by activating the appropriate
        setting on your browser. However, if you select this setting you may be
        unable to access certain parts of our Website. Unless you have adjusted
        your browser setting so that it will refuse cookies, our system will
        issue cookies when you direct your browser to our Website. - Flash
        Cookies. Certain features of our Website may use local stored objects
        (or Flash cookies) to collect and store information about your
        preferences and navigation to, from and on our Website. Flash cookies
        are not managed by the same browser settings as are used for browser
        cookies. - Web Beacons. Pages of our the Website and our e-mails may
        contain small electronic files known as web beacons (also referred to as
        clear gifs. pixel tags and single-pixel gifs) that permit the Company,
        for example, to count users who have visited those pages or opened an
        e-mail and for other related website statistics (for example, recording
        the popularity of certain website content and verifying system and
        server integrity).
      </p>
      {/* <Header as="h4">
        6. Third-party Use of Cookies [and Other Tracking Technologies.
      </Header> */}
      <p>
        Some content or applications, including advertisements, on the Website
        are served by third-parties, including advertisers, ad networks and
        servers, content providers and application providers. These third
        parties may use cookies [alone or in conjunction with web beacons or
        other tracking technologies] to collect information about you when you
        use our website. The information they collect may be associated with
        your personal information or they may collect information, including
        personal information, about your online activities over time and across
        different websites and other online services. They may use this
        information to provide you with interest-based (behavioral) advertising
        or other targeted content. We do not control these third parties’
        tracking technologies or how they may be used. If you have any questions
        about an advertisement or other targeted content, you should contact the
        responsible provider directly.
      </p>
      {/* <Header as="h4">7. How We Use Your Information</Header> */}
      <p>
        We use information that we collect about you or that you provide to us,
        including any personal information: - To present our Website and its
        contents to you. - To provide you with information, products or services
        that you request from us. - To fulfill any other purpose for which you
        provide it. - To provide you with notices about your
        account/subscription, including expiration and renewal notices. - To
        carry out our obligations and enforce our rights arising from any
        contracts entered into between you and us, including for billing and
        collection. - To notify you about changes to our Website or any products
        or services we offer or provide though it. - In any other way we may
        describe when you provide the information. - For any other purpose with
        your consent. We may use the information we have collected from you to
        enable us to display advertisements to our advertisers’ target
        audiences. Even though we do not disclose your personal information for
        these purposes without your consent, if you click on or otherwise
        interact with an advertisement, the advertiser may assume that you meet
        its target criteria.
      </p>
      {/* <Header as="h4">8. Disclosure of Your Information</Header> */}
      <p>
        We may disclose aggregated information about our users, and information
        that does not identify any individual,] without restriction. We may
        disclose personal information that we collect or you provide as
        described in this privacy policy: - To our subsidiaries and affiliates.
        - To contractors, service providers and other third parties we use to
        support our business and who are bound by contractual obligations to
        keep personal information confidential and use it only for the purposes
        for which we disclose it to them. - To a buyer or other successor in the
        event of a merger, divestiture, restructuring, reorganization,
        dissolution or other sale or transfer of some or all of Blush
        Enterprises, LLC’s assets, whether as a going concern or as part of
        bankruptcy, liquidation or similar proceeding, in which personal
        information held by Blush Enterprises, LLC about our Website users is
        among the assets transferred. - To third parties to market their
        products or services to you if you have consented to/not opted out of
        these disclosures. - To fulfill the purpose for which you provide it.
        For any other purpose disclosed by us when you provide the information.
        - With your consent. We may also disclose your personal information: -
        To comply with any court order, law or legal process, including to
        respond to any government or regulatory request. - To enforce or apply
        our terms of use and other agreements, including for billing and
        collection purposes. - If we believe disclosure is necessary or
        appropriate to protect the rights, property, or safety of Blush
        Enterprise, LLC, our customers or others. This includes exchanging
        information with other companies and organizations for the purposes of
        fraud protection and credit risk reduction.
      </p>
      {/* <Header as="h4">
        9. Choices About How We Use and Disclose Your Information
      </Header> */}
      <p>
        We strive to provide you with choices regarding the personal information
        you provide to us. We have created mechanisms to provide you with the
        following control over your information: - Tracking Technologies and
        Advertising. You can set your browser to refuse all or some browser
        cookies, or to alert you when cookies are being sent. To learn how you
        can manage your Flash cookie settings, visit the Flash player settings
        page on Adobe’s website. If you disable or refuse cookies, please note
        that some parts of this site may then be inaccessible or not function
        properly. - Disclosure of Your Information for Third-Party Advertising.
        If you do not want us to share your personal information with
        unaffiliated or non-agent third parties for promotional purposes, you
        can opt-out by logging into the Website and adjusting your user
        preferences in your account profile by checking or unchecking the
        relevant boxes or by sending us an e-mail stating your request to
        info@joinblush.com. - Targeted Advertising. If you do not want us to use
        information that we collect or that you provide to us to deliver
        advertisements according to our advertisers’ target-audience
        preferences, you can opt-out by by checking or unchecking the relevant
        boxes or by sending us an e-mail stating your request to
        info@joinblush.com. For this opt-out to function, you must have your
        browser set to accept browser cookies. We do not control third parties’
        collection or use of your information to serve interest-based
        advertising. However these third parties may provide you with ways to
        choose not to have your information collected or used in this way. You
        can opt out of receiving targeted ads from members of the Network
        Advertising Initiative (“NAI”) on the NAI’s website.
      </p>
      {/* <Header as="h4">10. Accessing and Correcting Your Information</Header> */}
      <p>
        You can review and change your personal information by logging into the
        Website and visiting your account profile page. You may also send us an
        e-mail at info@joinblush.com to request access to, correct or delete any
        personal information that you have provided to us. We cannot delete your
        personal information except by also deleting your user account. We may
        not accommodate a request to change information if we believe the change
        would violate any law or legal requirement or cause the information to
        be incorrect. If you delete your User Contributions from the Website,
        copies of your User Contributions may remain viewable in cached and
        archived pages, or might have been copied or stored by other Website
        users. Proper access and use of information provided on the Website,
        including User Contributions, is governed by our terms of use.
      </p>
      {/* <Header as="h4">11. Your California Privacy Rights</Header> */}
      <p>
        California Civil Code Section § 1798.83 permits users of our Website
        that are California residents to request certain information regarding
        our disclosure of personal information to third parties for their direct
        marketing purposes. To make such a request, please send an e-mail to
        info@joinblush.com or write us at: 4700 N Capital of Texas Hwy, Apt 515,
        Austin, TX 78746.
      </p>
      {/* <Header as="h4">12. Data Security</Header> */}
      <p>
        We have implemented measures designed to secure your personal
        information from accidental loss and from unauthorized access, use,
        alteration and disclosure. All information you provide to us is stored
        on our secure servers behind firewalls. Any payment transactions will be
        encrypted. The safety and security of your information also depends on
        you. Where we have given you (or where you have chosen) a password for
        access to certain parts of our Website, you are responsible for keeping
        this password confidential. We ask you not to share your password with
        anyone. We urge you to be careful about giving out information in public
        areas of the Website like message boards. The information you share in
        public areas may be viewed by any user of the Website. Unfortunately,
        the transmission of information via the internet is not completely
        secure. Although we do our best to protect your personal information, we
        cannot guarantee the security of your personal information transmitted
        to our Website. Any transmission of personal information is at your own
        risk. We are not responsible for circumvention of any privacy settings
        or security measures contained on the Website.
      </p>
      {/* <Header as="h4">13. Changes to Our Privacy Policy</Header> */}
      <p>
        It is our policy to post any changes we make to our privacy policy on
        this page. If we make material changes to how we treat our users’
        personal information, we will notify you by e-mail to the primary e-mail
        address specified in your account. The date the privacy policy was last
        revised is identified at the top of the page. You are responsible for
        ensuring we have an up-to-date active and deliverable e-mail address for
        you, and for periodically visiting our Website and this privacy policy
        to check for any changes.
      </p>
      {/* <Header as="h4">14. Contact Information</Header> */}
      <p>
        To ask questions or comment about this privacy policy and our privacy
        practices, contact us at:
      </p>
      <a
        href="#"
        onClick={() => {
          window.location.href = 'mailto:info@joinblush.com';
        }}>
        info@joinblush.com
      </a>
      {/* <Header as="h4">15. Clients</Header> */}
      <p>
        We will not collect information about our Clients’ use of the Site
        except as required for system administration. We use the Secure Sockets
        Layer (SSL) protocol and data encryption to protect your privacy. We do
        not record or store coaching sessions and we utilize encryption,
        firewall, virus, and password protection in order to protect your stored
        personal information and written communications. We also destroy all
        Client records after a given period. We recommend you take further steps
        to ensure confidentiality by changing your password regularly. There are
        some rare situations in which your Coach may be legally or ethically
        obligated to take actions she believes are necessary to protect you or
        others from harm. If a Coach has reason to believe that a minor or
        elderly or disabled person is being neglected or abused, the law
        requires that she report the situation to the appropriate state agency.
        If you present a clear and substantial danger or harm to yourself or
        others, your Coach is obligated to take protective actions. We don’t
        expect such situations to arise. But if they do, your Coach will make
        every effort to fully discuss the issue with you before taking any
        action and will limit disclosure to what is absolutely necessary. These
        actions may include contacting family members, seeking hospitalization,
        notifying any potential victims, and notifying the police. Information
        that you share with your Coach during coaching services is strictly
        confidential. No other person, including the Site’s administrator, will
        have access to this information. If you feel at any time that we or your
        Coach have compromised your privacy, please immediately contact us at to
        <a
          href="#"
          onClick={() => {
            window.location.href = 'mailto:info@joinblush.com';
          }}>
          info@joinblush.com
        </a>
      </p>
      {/* <Pane height="24px" /> */}
    </div>
  );
}

export default PrivacyPolicy;
