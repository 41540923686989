import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form, Icon } from 'semantic-ui-react';
import * as yup from 'yup';
import {
  Ahref,
  Button,
  WizForm,
} from '../../../../components/layout/WizardLayout/styles';
import { VALIDATION_MESSAGES } from '../../../../config/constants';

type FormData = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const schema = yup.object().shape({
  name: yup.string().required(VALIDATION_MESSAGES.required),
  email: yup
    .string()
    .email(VALIDATION_MESSAGES.email)
    .required(VALIDATION_MESSAGES.required),
  password: yup
    .string()
    .required(VALIDATION_MESSAGES.required)
    .min(6, 'Must be at least 6 characters long'),
  confirmPassword: yup
    .string()
    .required(VALIDATION_MESSAGES.required)
    .oneOf([yup.ref('password'), null], 'Password must match'),
});

const SignUpForm: React.FC<{
  loading: boolean;
  onSubmit: (data: FormData) => void;
}> = ({ loading, onSubmit }) => {
  const { handleSubmit, control, formState } = useForm<FormData>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  return (
    <WizForm size="big" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="email"
        render={({ field: { ref, ...field } }) => (
          <Form.Input
            label="Email Address"
            error={errors?.email?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="name"
        render={({ field: { ref, ...field } }) => (
          <Form.Input label="Name" error={errors?.name?.message} {...field} />
        )}
      />
      <Controller
        control={control}
        name="password"
        render={({ field: { ref, ...field } }) => (
          <Form.Input
            label="Password"
            type="password"
            error={errors?.password?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="confirmPassword"
        render={({ field: { ref, ...field } }) => (
          <Form.Input
            label="Confirm Password"
            type="password"
            error={errors?.confirmPassword?.message}
            {...field}
          />
        )}
      />
      <Form.Field style={{ marginTop: 16, marginBottom: 4, fontSize: 14 }}>
        By creating an account, you accept our&nbsp;
        <Ahref onClick={() => window.open('/info/terms-of-service', '_blank')}>
          Terms and Conditions.
        </Ahref>
      </Form.Field>
      <Form.Field
        style={{
          marginTop: 4,
          marginBottom: 32,
          fontSize: 12,
          fontStyle: 'italic',
        }}>
        *Blush is currently for clients 18 and older.
      </Form.Field>
      <Button
        icon
        labelPosition="right"
        size="big"
        color="black"
        disabled={loading}
        loading={loading}>
        Get Started Now
        <Icon name="chevron right" />
      </Button>
    </WizForm>
  );
};

export default SignUpForm;
