import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Dashboard from '../pages/coach/Dashboard';
import SettingsProfile from '../pages/coach/Settings/Profile';
import SettingsAvailability from '../pages/coach/Settings/Availability';
import SettingsNotification from '../pages/coach/Settings/Notifications';
import SettingsPayment from '../pages/coach/Settings/Payment';
import SettingsEmail from '../pages/coach/Settings/Email';
import SettingsSecurity from '../pages/coach/Settings/Security';
import SettingsWelcomeMessage from '../pages/coach/Settings/WelcomeMessage';
import AppRoute from './AppRoute';
import Chat from '../pages/coach/Chat';
import Clients from '../pages/coach/Clients';
import Session from '../pages/shared/Session';

export default function CoachRoutes(): JSX.Element {
  return (
    <Switch>
      <AppRoute exact path="/coach/dashboard" component={Dashboard} />
      <AppRoute exact path="/coach/chat" component={Chat} />
      <AppRoute exact path="/coach/clients" component={Clients} />
      <AppRoute path="/coach/settings/profile" component={SettingsProfile} />
      <AppRoute
        path="/coach/settings/availability"
        component={SettingsAvailability}
      />
      <AppRoute
        path="/coach/settings/notifications"
        component={SettingsNotification}
      />
      <AppRoute path="/coach/settings/payment" component={SettingsPayment} />
      <AppRoute path="/coach/settings/email" component={SettingsEmail} />
      <AppRoute path="/coach/settings/security" component={SettingsSecurity} />
      <AppRoute
        path="/coach/settings/system-message"
        component={SettingsWelcomeMessage}
      />
      <AppRoute path="/coach/session/:sessionId" component={Session} />
      <Redirect path="/" to="/coach/dashboard" />
    </Switch>
  );
}
