import styled, { css, keyframes } from 'styled-components';
import {
  Button as Btn,
  Card,
  Container,
  Form,
  Image,
  Item,
  Message,
  Popup,
} from 'semantic-ui-react';

import { colors } from '../../../theme';
import metrics from '../../../theme/metrics';
import { Pane } from '../Pane';

export const Label = styled.label<{ slashed?: boolean; primary?: boolean }>`
  &&&& {
    text-decoration: ${(props) => (props.slashed ? 'line-through' : 'none')};
    color: ${(props) =>
      props.primary ? colors.bittersweet : colors.nightRider};
    font-size: 17px;
  }
`;

export const Ahref = styled.a`
  color: ${colors.bittersweet};
  font-size: 14px;
  cursor: pointer;
`;

export const WizLink = styled.a`
  color: ${colors.black};
  font-size: 18px;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`;

export const WizContentContainer = styled(Container)`
  padding-left: 25px;
  padding-top: 20px;

  @media (min-width: ${metrics.device_size.tablet}) {
    padding-left: 75px;
  }
`;

export const WizCard = styled(Card)<any>`
  &&&&.coaches {
    margin-left: 0;
    padding-left: 50px;

    img {
      width: 80px;
      height: 80px;
      border-radius: 60px !important;
      position: absolute;
      top: -15px;
      left: -65px;
      object-fit: cover;
    }

    @media (min-width: ${metrics.device_size.tablet}) {
      margin-left: 0;
      padding-left: 80px;

      img {
        width: 120px;
        height: 120px;
      }
    }

    @media (max-width: 767px) {
      .content {
        margin: 0;
      }
      .content .ui.items .item {
        margin: 0;
      }
    }
  }

  &&&& {
    padding: 16px;
    border: 0.5px solid ${colors.geraldine};
    border-radius: 12px;
    box-shadow: none;
    background-color: ${({ selected }) =>
      selected ? colors.black : colors.white};

    @media (min-width: 320px) and (max-width: 961px) {
      ${(props) =>
        !props.coach &&
        props.className !== 'coaches' &&
        css`
          padding: 16px !important;
        `};
    }

    ${(props) =>
      props.single &&
      css`
        font-size: 16px;
        color: ${colors.black};
        height: 100%;

        @media (min-width: 1025px) {
          margin: 6px;
        }
      `}

    ${(props) =>
      props.coach &&
      css`
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 12%;
        font-size: 16px;
        color: ${colors.black};
        margin-top: 28px;
        margin-bottom: 28px;
        margin-left: 28px;
        width: 40%;

        @media (min-width: 961px) {
          ${props.first === 'true'
            ? css`
                margin-right: 32px;
              `
            : css`
                margin-left: 32px;
              `};
        }

        @media (min-width: 1281px) {
          ${props.first === 'true'
            ? css`
                margin-right: 48px;
              `
            : css`
                margin-left: 48px;
              `};
        }
      `}

    .description {
      margin-top: 20px;
      color: ${({ selected }) => (selected ? colors.white : colors.charcoal)};
    }

    .header {
      overflow-wrap: break-word;
      font-size: ${({ type }) =>
        type === 'single' ? '1em' : '1.2em'} !important;
      color: ${({ selected, $whiteHighlightedTitle }) =>
        // eslint-disable-next-line no-nested-ternary
        selected
          ? $whiteHighlightedTitle
            ? colors.white
            : colors.geraldine
          : colors.charcoal};
      @media (min-width: ${metrics.device_size.tablet}) {
        font-size: ${({ type }) =>
          type === 'single' ? '1em' : '1.8em'} !important;
      }
    }

    ${(props) =>
      props.type === 'single' &&
      css`
        .header {
          text-align: center;
          font-weight: 600;
        }
      `}

    .meta {
      color: ${({ selected, $whiteHighlightedTitle }) =>
        // eslint-disable-next-line no-nested-ternary
        selected
          ? $whiteHighlightedTitle
            ? colors.white
            : colors.geraldine
          : colors.charcoal};
    }

    .icon {
      background-color: white;
      border-radius: 50% !important;
      width: auto;
      height: auto;
      position: absolute;
      top: -8px;
      line-height: 1.5rem !important;
      right: -16px;
    }

    .content {
      border-top: none;

      @media (min-width: 320px) and (max-width: 961px) {
        ${(props) =>
          props.coach &&
          css`
            padding-left: 30%;
          `};
      }
    }

    &:hover {
      border: 0.5px solid ${colors.geraldine};
      box-shadow: 0 2px 8px ${colors.silver};
      background-color: ${({ selected }) =>
        selected ? colors.black : colors.white};
    }
  }
`;

export const WizContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const fadeInRight = keyframes`
  from {
    opacity: 0;
    transform: translate3d(20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const WizContent = styled(Container)`
  &&& {
    display: flex;
    flex-direction: column;
    animation: ${fadeInRight} 800ms;
    padding: 2rem 2rem 12rem 2rem;
    flex-shrink: 0;
    ${(props) =>
      props.paddingBottom &&
      css`
        padding-bottom: ${props.paddingBottom} !important;
      `};
    @media (min-width: ${metrics.device_size.tablet}) {
      padding: 4rem 6rem 8rem 6rem;
    }
  }
`;

export const WizLeftPane = styled.div<{ fullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  width: 100%;
  @media (min-width: 1025px) {
    width: ${(props) => (props.fullWidth ? '100%' : '60%')};
  }
`;

export const WizRightPane = styled.div<{ bgImage: any }>`
  display: none;
  min-height: 100%;
  position: fixed;
  right: 0;
  width: 40%;
  background-image: url('${(props) => props.bgImage ?? props.bgImage}');
  background-size: cover;
  z-index: 5;
  @media (min-width: 1025px) {
    display: flex;
  }
`;

export const WizLogo = styled(Image)`
  &&& {
    display: block;
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-top: 24px;
    margin-left: 36px;
  }
`;

export const WizTitle = styled.h1`
  font-size: 30px;
  font-weight: 600;

  span {
    color: ${colors.bittersweet};
  }

  @media (min-width: ${metrics.device_size.tablet}) {
    font-size: 42px;
  }
`;

export const WizSubtitle = styled(WizTitle)`
  font-size: 18px !important;
  font-weight: 500;
  margin-top: 0;

  @media (min-width: ${metrics.device_size.tablet}) {
    font-size: 20px !important;
  }
`;

export const WizText = styled.span`
  font-size: 18px;
`;

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const WizBtnGroup = styled.div<{ fullWidth?: boolean }>`
  animation: ${fadeIn} 500ms;
  padding: 2rem 2rem 1rem 2rem;
  background: ${colors.white};
  border-top: 0.5px solid ${colors.silver};
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  @media (min-width: 1025px) {
    animation: ${fadeInDown} 1000ms;
    width: ${(props) => (props.fullWidth ? '100%' : '60%')};
  }
  @media (min-width: ${metrics.device_size.tablet}) {
    padding: 2rem 6rem;
  }
  .button {
    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0 !important;
      margin-bottom: 1rem;
    }
  }
`;

export const WizForm = styled(Form)`
  &&& {
    width: 100%;
    margin: 24px auto;
    text-align: left;

    input,
    .StripeElement {
      border: none;
      border-bottom: 1px solid ${colors.charcoal};
      border-radius: 0;
      padding: 8px 0px;
      font-size: 14px;
      background: none;
      margin-top: 8px;
      margin-bottom: 12px;

      &:focus {
        border-radius: 0;
      }
    }

    label {
      color: ${colors.nightRider};
      font-size: 17px;
    }
  }
`;

export const BitterSweetSpan = styled.span`
  display: block;
  color: ${colors.bittersweet};
  font-size: 32px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const Button = styled(Btn)`
  &&&&.geraldine {
    color: ${colors.white} !important;
    background: ${colors.geraldine} !important;
  }
  &&&&.dark {
    color: ${colors.white} !important;
    background: ${colors.black} !important;
  }
  &&&&.standalone {
    padding: 2rem 2rem !important;
    font-size: 1.6em;
    text-align: center;
    font-weight: 500;
    justify-content: center;
  }
  &&&&.ui.labeled.icon.right.button {
    font-size: 1.4rem;
    padding-right: 3.57142857em !important;
  }
  &&&& {
    border-radius: 0;
    .icon {
      background-color: ${colors.bittersweet};
      color: ${colors.black};
    }
  }
  ${
    '' /* ${props =>
    props.width &&
    css`
      width: ${props.width};
    `} */
  }
`;

export const EmbedCard = styled(Card)`
  &&& {
    border-radius: 8px;

    .ui.embed {
      border-radius: 8px !important;
      height: 320px;
    }

    .embed > img.placeholder {
      object-fit: cover;
    }

    .content {
      position: absolute;
      bottom: 0;
      width: 100%;
      border: none;
      background: rgba(0, 0, 0, 0.3);
    }

    /*.content .header,
    .content .meta {
      color: ${colors.white};
    }*/
  }
`;

export const CardImage = styled(Image)`
  &&&& {
    border-radius: 100% !important;
    height: 104px;
    width: 104px;
    object-fit: cover;
    position: absolute;
    top: -14px;
    left: -50px;
  }
`;

/* export const Text = styled.span<{ amethystSmoke: string }>`
  color: ${colors.amethystSmoke};
  font-size: 14px;
`; */

export const WizPane = styled(Pane)`
  background: ${colors.white};
  max-width: 40rem;
  flex-grow: 1;

  @media (min-width: 1025px) {
  }
`;

export const WizMessage = styled(Message)`
  margin: auto !important;

  .icon {
    font-size: 2em !important;
  }
`;

export const WizItem = styled(Item)`
  &&&& {
    .header {
      font-size: 1.2em !important;
      margin-top: 1.4rem;

      @media (min-width: ${metrics.device_size.tablet}) {
        font-size: 1.6em !important;
        margin-top: 2.4rem;
      }
    }
    .header.h1 {
      margin-bottom: 2rem;
      font-size: 2em !important;
      font-weight: 600;
      color: ${colors.geraldine};

      @media (min-width: ${metrics.device_size.tablet}) {
        font-size: 3em !important;
      }
    }
    .description {
      font-size: 1.2em;
    }
    .image > img {
      border-radius: 50%;
    }
    .extra {
      display: flex;
      margin: 0;
      padding: 2rem 3rem;
      background: ${colors.lightWhiteSmoke};
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;

      .label {
        padding: 0.4rem 2rem;
        font-size: 1em;
        background: ${colors.white};

        @media (min-width: ${metrics.device_size.tablet}) {
          padding: 1.4rem 3.4rem;
          font-size: 1.2em;
        }
      }
    }
  }
`;

export const WizFormItemGroup = styled(Item.Group)`
  &&&& {
    display: flex;
    flex-flow: row wrap;

    .item {
      margin: 0rem 0.8rem 2rem 0;
      width: calc(100% - 4rem);

      @media (min-width: ${metrics.device_size.tablet}) {
        margin: 0rem 1.2rem 2rem 0;
        width: calc(50% - 4rem);
      }
    }

    .item.flex {
      width: 100%;

      .fields > .field:first-child {
        flex-grow: 3;
        input {
          font-size: 16px;
        }
      }
    }
  }
`;

export const WizGridContainer = styled(Pane)`
  &&&& {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
`;

export const WizGridItem = styled.div`
  &&&& {
    margin: 0.8rem;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100px;
    justify-content: flex-start;
    align-items: center;
    .avatar {
      width: 100px;
      height: 100px;
      span {
        width: 100px;
        height: 100px;
      }
    }
    .icon {
      background-color: white;
      border-radius: 50% !important;
      width: auto;
      height: auto;
      position: absolute;
      top: 10px;
      line-height: 1.5rem !important;
      right: 0;
    }
    .caption {
      margin-top: 1rem;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
    }
    @media (min-width: ${metrics.device_size.tablet}) {
      margin: 1rem;
      .avatar {
        width: 100px;
        height: 100px;
        span {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
`;

export const WizPopup = styled(Popup)`
  &&&& {
    padding: 0;
    border: 0;
    border-radius: 1rem;
    .header {
      color: ${colors.white};
      background-color: ${colors.black};
      padding: 1rem 2rem;
      border-radius: 0.5rem;
    }
    &:before {
      background-color: ${colors.geraldine};
      border: 0;
      z-index: -1;
    }
  }
`;
