import { createSlice } from '@reduxjs/toolkit';
import { InterestsState } from '../store/types';
import { logout } from '../auth/auth.saga';
import { getUser } from '../user/user.saga';

const initialState = {
  status: 'pending',
  interests: [],
} as InterestsState;

const interestsSlice = createSlice({
  name: 'interests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
      status: 'pending',
    }));
    builder.addCase(getUser.fulfilled, (state, action: any) => ({
      ...state,
      status: 'success',
      interests: [
        ...(action.payload.interests?.interests ||
          action.payload.interests ||
          []),
      ],
    }));
  },
});

export default interestsSlice.reducer;
