import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '../store';

export const subscriptionSelector = (state: ApplicationState) =>
  state.subscription;

export const selectedSubscriptionSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription.selectedSubscription || ''
);

export const selectedSubscriptionLevelSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription.selectedSubscriptionLevel || ''
);

export const stripeIdSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription.stripeId || ''
);

export const iosSubscriptionSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription.ios
);

export const activeSubscriptionSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription.activeSubscription
);

export const defaultCardSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription.defaultCard
);

export const pendingSubscriptionSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription.pendingSubscription
);
