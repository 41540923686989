import { FC } from 'react';
import useWindowSize from '../../../hooks/useWindowSize';

interface Props {
  children: JSX.Element;
}

const DesktopView: FC<Props> = ({ children }) => {
  const { width = 0 } = useWindowSize();
  const breakpoint = 768;
  return width >= breakpoint ? children : null;
};

export default DesktopView;
