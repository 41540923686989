/* eslint-disable @typescript-eslint/no-use-before-define */
import { push } from 'connected-react-router';
import { createAsyncSaga } from 'create-async-saga';
import moment from 'moment';
import { eventChannel } from 'redux-saga';
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import * as Firebase from '../../services/firebase';
import Swal from '../../utils/Swal';
import * as authTypes from '../auth/auth.actionTypes';
import { roleSelector } from '../auth/auth.selectors';
import * as types from './video.actionTypes';

function* observeSessions(): any {
  const role = yield select(roleSelector);
  const channel = eventChannel((emitter) => {
    const observer = Firebase.sessionsObserver(emitter, role);
    return () => observer();
  });

  while (true) {
    try {
      const response = yield take(channel);
      yield put({
        type: getSessions.fulfilled.type,
        payload: response,
      });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

export const getSessions = createAsyncSaga(types.GET_SESSIONS, function* () {
  const observer = yield fork(observeSessions);
  yield take(authTypes.LOGOUT);
  yield cancel(observer);
});

function* observeSession(sessionId: string): any {
  const channel = eventChannel((emitter) => {
    const observer = Firebase.sessionObserver(emitter, sessionId);
    return () => observer();
  });

  while (true) {
    try {
      const response = yield take(channel);
      yield put({
        type: getSession.fulfilled.type,
        payload: response,
      });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

export const getSession = createAsyncSaga(
  types.GET_SESSION,
  function* (sessionId: string) {
    const observer = yield fork(observeSession, sessionId);
    yield take(authTypes.LOGOUT);
    yield cancel(observer);
  }
);

function* observeCoachAvailabilityBlocks(data: {
  uid: string;
  duration: number;
}): any {
  const channel = eventChannel((emitter) => {
    const observer = Firebase.coachAvailabilityObserver(emitter, data);
    return () => observer();
  });

  while (true) {
    try {
      const response = yield take(channel);
      yield put({
        type: getCoachAvailabilityBlocks.fulfilled.type,
        payload: response,
      });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

export const getCoachAvailabilityBlocks = createAsyncSaga(
  types.GET_COACH_AVAILABILITY_BLOCKS,
  function* (data: { uid: string; duration: number }) {
    const observer = yield fork(observeCoachAvailabilityBlocks, data);
    yield take(authTypes.LOGOUT);
    yield cancel(observer);
  }
);

export const createSession = createAsyncSaga(
  types.CREATE_SESSION,
  function* (session: {
    date: string;
    time: string;
    coach: string;
    block: any;
    duration: SessionDuration;
  }) {
    try {
      const { date, time, coach, block, duration } = session;
      const response = yield call(Firebase.createSession, {
        coach,
        block,
        duration,
      });
      yield put(push('/user/dashboard'));
      yield Swal.fire({
        icon: 'success',
        title: `Your session has been scheduled for ${moment(date).format(
          'MMMM Do YYYY'
        )} at ${time}`,
      });
      return response;
    } catch (err: any) {
      yield Swal.fire({
        icon: 'error',
        title:
          'Unable to schedule this session. Please try again or contact support.',
      });
      throw err;
    }
  }
);

export const cancelSession = createAsyncSaga(
  types.CANCEL_SESSION,
  function* (sessionId: string) {
    try {
      const response = yield call(Firebase.cancelSession, sessionId);
      yield put(push('/user/dashboard'));
      return response;
    } catch (err: any) {
      yield Swal.fire({
        icon: 'error',
        title: err.message,
      });
      throw err;
    }
  }
);

export const updateSessionStatus = createAsyncSaga(
  types.UPDATE_SESSION_STATUS,
  function* (data: {
    sessionId: string;
    status: string;
    meta?: { successRoute: string };
  }) {
    try {
      const response = yield call(Firebase.updateSessionStatus, data);
      if (data.meta?.successRoute) {
        yield put(push(data.meta.successRoute));
      }
      return response;
    } catch (err: any) {
      yield Swal.fire({
        icon: 'error',
        title: err.message,
      });
      throw err;
    }
  }
);

export default function* sessionsSaga() {
  yield takeLatest(getSessions.actionType, getSessions.asyncSaga);
  yield takeLatest(getSession.actionType, getSession.asyncSaga);
  yield takeLatest(
    getCoachAvailabilityBlocks.actionType,
    getCoachAvailabilityBlocks.asyncSaga
  );
  yield takeLatest(createSession.actionType, createSession.asyncSaga);
  yield takeLatest(cancelSession.actionType, cancelSession.asyncSaga);
  yield takeLatest(
    updateSessionStatus.actionType,
    updateSessionStatus.asyncSaga
  );
}
