import { Message } from '../../types/message';
import ChatManager from './chatManager';

export const Chat = new ChatManager();

export const chatObserver = (
  emitter: (messages: Omit<Message, 'created_at' | 'read'>[] | Error) => void,
  payload: { recipientID: string; role: Role; lastReceived: string }
) => {
  const { recipientID, role, lastReceived } = payload;

  return Chat.observe(emitter, recipientID, role, lastReceived);
};

export const addChat = (payload: {
  recipientID: string;
  role: Role;
  message: Message & { resend?: boolean; isWelcomeMessage?: boolean };
}) => {
  const { recipientID, role, message } = payload;

  const {
    text = '',
    user,
    image = '',
    video = '',
    dateUTC,
    resend,
    isWelcomeMessage,
  } = message;

  return Chat.add(recipientID, role, {
    text,
    user,
    image,
    video,
    dateUTC,
    resend,
    isWelcomeMessage,
  });
};

export const uploadChatMedia = async (payload: {
  recipientID: string;
  role: Role;
  media: Blob | Uint8Array | ArrayBuffer;
}) => {
  const { recipientID, role, media } = payload;

  return Chat.upload(recipientID, role, media);
};

export const updateChatReadReceipt = async (payload: {
  recipientID: string;
  role: Role;
}) => {
  const { recipientID, role } = payload;

  return Chat.updateChatReadReceipt(recipientID, role);
};

export const getUserArchivedChat = async (payload: {
  recipientID: string;
  role: Role;
}) => {
  const { recipientID, role } = payload;

  return Chat.get(recipientID, role);
};
