import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Form, Header } from 'semantic-ui-react';
import * as yup from 'yup';
import { AppLayout, Pane, SettingsLayout } from '../../../../components/layout';
import { PrimaryButton } from '../../../../components/layout/SettingsLayout/styles';
import { SettingMenuItem } from '../../../../components/layout/SettingsLayout/SettingsMenu';
import { VALIDATION_MESSAGES } from '../../../../config/constants';
import { COACH_TABS } from '../../../../constants/tabs';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { updateEmail } from '../../../../redux/user/user.saga';
import { userSelector } from '../../../../redux/user/user.selectors';

type FormData = {
  password: string;
  newEmail: string;
};

const schema = yup.object().shape({
  newEmail: yup
    .string()
    .email(VALIDATION_MESSAGES.email)
    .required(VALIDATION_MESSAGES.required),
  password: yup.string().required(VALIDATION_MESSAGES.required),
});

const Email: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { handleSubmit, control, formState } = useForm<FormData>({
    defaultValues: {
      newEmail: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  const user = useAppSelector(userSelector);
  const { status } = user;

  const handleNavigationChange = (item: SettingMenuItem) => {
    history.push(item.name);
  };

  return (
    <AppLayout page="settings">
      <SettingsLayout
        items={COACH_TABS}
        selectedItem={{ name: 'email' }}
        onSelectItem={handleNavigationChange}>
        <Form
          onSubmit={handleSubmit((values) =>
            dispatch(updateEmail.action(values))
          )}>
          <Header>Update Email</Header>
          <Pane height={25} />
          <Controller
            control={control}
            name="password"
            render={({ field: { ref, ...field } }) => (
              <Form.Input
                label="Password"
                type="password"
                error={errors?.password?.message}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="newEmail"
            render={({ field: { ref, ...field } }) => (
              <Form.Input
                label="New Email"
                error={errors?.newEmail?.message}
                {...field}
              />
            )}
          />
          <Pane height={20} />
          <PrimaryButton
            loading={status === 'loading'}
            disabled={status === 'loading'}
            primary
            type="submit">
            Save
          </PrimaryButton>
        </Form>
      </SettingsLayout>
    </AppLayout>
  );
};

export default Email;
