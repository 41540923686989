import { all, fork } from 'redux-saga/effects';
import appSaga from '../app/app.saga';
import authSaga from '../auth/auth.saga';
import clientsSaga from '../clients/clients.saga';
import coachesSaga from '../coaches/coaches.saga';
import notificationsSaga from '../notifications/notifications.saga';
import sessionFeedbackSaga from '../sessionFeedback/sessionFeedback.saga';
import subscriptionSaga from '../subscription/subscription.saga';
import userSaga from '../user/user.saga';
import videoSaga from '../video/video.saga';
import chatSaga from '../chat/chat.saga';

export default function* rootSaga() {
  yield all([
    fork(appSaga),
    fork(authSaga),
    fork(userSaga),
    fork(clientsSaga),
    fork(coachesSaga),
    fork(notificationsSaga),
    fork(sessionFeedbackSaga),
    fork(subscriptionSaga),
    fork(videoSaga),
    fork(chatSaga),
  ]);
}

// spawn might be more appropriate here, research required
