import _last from 'lodash/last';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Dimmer, Header, Loader, Menu, Tab } from 'semantic-ui-react';

import { AppLayout } from '../../../components/layout';
import { ChatFeed } from '../../../components/ui';
import { ChatPane, UnreadIcon } from '../../../components/ui/ChatFeed/styles';
import useWindowSize from '../../../hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  clientsSelector,
  clientsStatusSelector,
} from '../../../redux/clients/clients.selectors';
import { roomsSelector } from '../../../redux/chat/chat.selectors';
import { getClients } from '../../../redux/clients/clients.saga';
import { getClientsChat } from '../../../redux/chat/coachChat.saga';
import { userSelector } from '../../../redux/user/user.selectors';

const Chat: FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const clientList = useAppSelector(clientsSelector);
  const clientStatus = useAppSelector(clientsStatusSelector);
  const rooms = useAppSelector(roomsSelector);

  const getClientsAction = useCallback(() => {
    dispatch(getClients.action());
  }, [dispatch]);

  const getClientsChatAction = useCallback(() => {
    dispatch(getClientsChat.action());
  }, [dispatch]);

  const [clientIndex, setClientIndex] = useState(-1);
  const { search } = useLocation();
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (clientStatus === 'pending') getClientsAction();
  }, [clientStatus, getClientsAction]);

  useEffect(() => {
    if (clientStatus === 'success' && clientList.length) {
      getClientsChatAction();
    }
  }, [clientList, clientStatus, getClientsChatAction]);

  const displayClients = useMemo(
    () =>
      [...clientList]?.sort(
        (a, b) => a?.profile?.name?.localeCompare(b?.profile?.name ?? '') ?? -1
      ),
    [clientList]
  );

  useEffect(() => {
    if (clientIndex === -1) {
      if (displayClients.length && search) {
        const searchParams = new URLSearchParams(search);
        const activeIndex = displayClients.findIndex(
          (client) => client.profile.uid === searchParams.get('uid')
        );
        if (activeIndex !== -1) setClientIndex(activeIndex);
      } else {
        setClientIndex(0);
      }
    }
  }, [clientIndex, displayClients, search]);

  const onTabChange = useCallback((e, { activeIndex }) => {
    setClientIndex(activeIndex);
  }, []);

  const panes = useMemo(
    () =>
      displayClients.map((client) => {
        const room = rooms[client.uid] || {};
        const lastMessage = _last(room.messages) || {
          received: false,
          recipientID: '',
        };
        const unread =
          lastMessage.received === false &&
          lastMessage.recipientID === user.profile.uid;

        return {
          menuItem: (
            <Menu.Item key={client.profile.uid}>
              {unread && <UnreadIcon name="circle" corner="left" />}
              {client.profile.name}
            </Menu.Item>
          ),
          render: () => (
            <ChatFeed
              showReadReceipt
              chats={room.messages}
              recipient={client.profile}
            />
          ),
        };
      }),
    [displayClients, rooms, user.profile.uid]
  );

  return (
    <AppLayout page="chat">
      <ChatPane>
        <Header as="h1">Chat</Header>
        <Dimmer.Dimmable blurring dimmed={clientStatus === 'loading'}>
          <Dimmer inverted active={clientStatus === 'loading'}>
            <Loader />
          </Dimmer>
          <div className="tab-container">
            <Tab
              menu={{
                ...(!isMobile && {
                  fluid: true,
                  vertical: true,
                  tabular: 'right',
                }),
              }}
              activeIndex={clientIndex}
              onTabChange={onTabChange}
              panes={panes}
            />
          </div>
        </Dimmer.Dimmable>
      </ChatPane>
    </AppLayout>
  );
};

export default Chat;
