import React, { useEffect, useState } from 'react';
import { Checkbox, CheckboxProps, Form, Header } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import { AppLayout, Pane, SettingsLayout } from '../../../../components/layout';
import {
  PrimaryButton,
  StyledNotificationForm,
} from '../../../../components/layout/SettingsLayout/styles';
import { NOTIFICATION_TYPES } from '../../../../config/constants';
import {
  notificationSettingsSelector,
  notificationsSelector,
} from '../../../../redux/notifications/notifications.selectors';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  getNotificationSettings,
  updateNotificationSettings,
} from '../../../../redux/notifications/notifications.saga';
import { USER_TABS } from '../../../../constants/tabs';
import { SettingMenuItem } from '../../../../components/layout/SettingsLayout/SettingsMenu';

const Notifications: React.FC = () => {
  const history = useHistory();
  const notifications = useAppSelector(notificationsSelector);
  const { status } = notifications;
  const defaultValues = useAppSelector(notificationSettingsSelector);
  const dispatch = useAppDispatch();
  const [settings, setSettings] = useState(defaultValues);
  const loading = status === 'loading';

  useEffect(() => {
    dispatch(getNotificationSettings.action());
  }, [dispatch]);

  useEffect(() => {
    setSettings(defaultValues);
  }, [defaultValues]);

  const onCheckboxChange = async (
    e: React.FormEvent<HTMLInputElement>,
    { name, checked }: CheckboxProps
  ) => {
    setSettings({
      ...settings,
      [name!]: !!checked,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(updateNotificationSettings.action({ settings }));
  };

  const handleNavigationChange = (item: SettingMenuItem) => {
    history.push(item.name);
  };

  return (
    <AppLayout page="settings">
      <SettingsLayout
        items={USER_TABS}
        selectedItem={{ name: 'notifications' }}
        onSelectItem={handleNavigationChange}>
        <Header>Push Notifications</Header>
        <Pane height={10} />
        <StyledNotificationForm size="big" onSubmit={handleSubmit}>
          <Form.Group grouped>
            {(
              Object.keys(NOTIFICATION_TYPES) as Array<
                keyof NotificationSettings
              >
            ).map((type) => (
              <Form.Field key={type}>
                <label>{NOTIFICATION_TYPES[type]}</label>
                <Checkbox
                  disabled={loading}
                  toggle
                  name={type}
                  checked={settings[type]}
                  onChange={onCheckboxChange}
                />
              </Form.Field>
            ))}
          </Form.Group>
          <PrimaryButton
            primary
            disabled={loading}
            loading={loading}
            type="submit">
            Save
          </PrimaryButton>
        </StyledNotificationForm>
      </SettingsLayout>
    </AppLayout>
  );
};

export default Notifications;
