import firebase from 'firebase';

const converter = {
  toFirestore: (data: Subscription) => data,
  fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) =>
    snap.data() as Subscription,
};

const subscriptionsCollection = firebase
  .firestore()
  .collection('subscriptions')
  .withConverter(converter);

// eslint-disable-next-line import/prefer-default-export
export const subscriptionObserver = (
  emitter: (
    subscription: Record<string, unknown> | Subscription | Error
  ) => void
) => {
  const { currentUser } = firebase.auth();

  return subscriptionsCollection.doc(currentUser?.uid).onSnapshot(
    (snapshot) => {
      if (!snapshot.exists) return emitter({});
      const data = snapshot.data();
      return emitter({ ...data });
    },
    (error) => {
      console.log('Clients Observer Error', error);
      return emitter(new Error(error.message));
    }
  );
};
