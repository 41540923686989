import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Grid, Icon, Image } from 'semantic-ui-react';
import { Pane, WizardLayout } from '../../../../components/layout';
import {
  Button,
  WizBtnGroup,
  WizCard,
  WizContent,
  WizContentContainer,
  WizTitle,
} from '../../../../components/layout/WizardLayout/styles';
import { matchCoach } from '../../../../redux/coaches/coaches.saga';
import { coachSelector } from '../../../../redux/coaches/coaches.selectors';
import { updateMatchedCoach } from '../../../../redux/coaches/coaches.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

const ChooseDifferentCoach: React.FC = () => {
  const history = useHistory();
  const coach = useAppSelector(coachSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!coach.uid) {
      // call match coach here
      console.log('matching coach...');
      dispatch(matchCoach.action());
    }
  }, [coach, dispatch]);

  const meetYourCoaches = () => (
    <>
      <WizTitle>Other coaches we know you&apos;d love</WizTitle>
      <Pane maxWidth="40rem">
        <Grid columns={1} stackable relaxed="very">
          {coach?.matchResults
            ?.filter((_i, index) => index < 4)
            .map((matched) => {
              const { profile, uid } = matched;
              return (
                <Grid.Column key={`${uid}`}>
                  <WizContentContainer>
                    <WizCard
                      fluid
                      onClick={() => {
                        dispatch(updateMatchedCoach({ ...coach, ...matched }));
                      }}
                      single="true"
                      className="coaches">
                      {coach.uid === uid && (
                        <Icon name="check circle" size="large" color="green" />
                      )}
                      <Image src={profile?.photo} size="small" circular />
                      <Card.Content>
                        <Card.Header>{profile.name}</Card.Header>
                      </Card.Content>
                    </WizCard>
                  </WizContentContainer>
                </Grid.Column>
              );
            })}
        </Grid>
      </Pane>
    </>
  );

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <WizardLayout sideBanner="onboarding_img_5">
      <WizContent textAlign="left">
        {coach?.matchResults?.length && meetYourCoaches()}
      </WizContent>
      <WizBtnGroup>
        <Button
          basic
          color="black"
          size="big"
          style={{
            marginRight: 12,
          }}
          onClick={goBack}>
          Back
        </Button>
        <Button
          icon
          labelPosition="right"
          size="big"
          color="black"
          onClick={() => history.push('/onboarding/choose-coach')}>
          Continue
          <Icon name="chevron right" />
        </Button>
      </WizBtnGroup>
    </WizardLayout>
  );
};

export default ChooseDifferentCoach;
