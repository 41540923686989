/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '../store';

export const notificationsSelector = (state: ApplicationState) =>
  state.notifications;

export const notificationSettingsSelector = createSelector(
  notificationsSelector,
  (notifications) => notifications.settings
);
