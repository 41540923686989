import React, { FC } from 'react';
import { Header, Icon } from 'semantic-ui-react';

import { QuoteSticky } from './styles';
import ErrorBoundaryContainer from '../ErrorBoundaryContainer';

interface Props {
  quote: string;
  author: string;
}

const QuoteOfTheDay: FC<Props> = ({ quote, author }) => (
  <ErrorBoundaryContainer>
    <QuoteSticky>
      <Icon name="quote right" />
      <Header>Quote of the day</Header>
      <span className="quote-text">{quote}</span>
      <span className="quote-author">-{author}</span>
    </QuoteSticky>
  </ErrorBoundaryContainer>
);

export default QuoteOfTheDay;
