import React, { FC, useCallback, useEffect } from 'react';
import { Header } from 'semantic-ui-react';

import { ChatFeed, UpgradeNow } from '../../../components/ui';
import { ChatPane } from '../../../components/ui/ChatFeed/styles';
import ArchiveModal from './ArchiveModal';
import { Pane } from '../../../components/layout/Pane';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { roleSelector } from '../../../redux/auth/auth.selectors';
import { userSelector } from '../../../redux/user/user.selectors';
import { chatSelector } from '../../../redux/chat/chat.selectors';
import {
  coachSelector,
  previousCoachesSelector,
} from '../../../redux/coaches/coaches.selectors';
import { subscriptionSelector } from '../../../redux/subscription/subscription.selectors';
import { getChat } from '../../../redux/chat/chat.saga';
import { getPreviousCoachProfile } from '../../../redux/coaches/coaches.saga';
import AppLayout from '../../../components/layout/AppLayout';

const Chat: FC = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(roleSelector);
  const user = useAppSelector(userSelector);
  const chat = useAppSelector(chatSelector);
  const coach = useAppSelector(coachSelector);
  const previousCoaches = useAppSelector(previousCoachesSelector);
  const subscription = useAppSelector(subscriptionSelector);

  const getChatAction = useCallback(
    (params: { recipientID: string; role: Role; lastReceived: string }) => {
      dispatch(getChat.action(params));
    },
    [dispatch]
  );

  const getPreviousCoachProfileAction = useCallback(() => {
    dispatch(getPreviousCoachProfile.action());
  }, [dispatch]);

  const { messages, lastReceived } = chat;
  const { chat: isChatActive } = subscription;

  useEffect(() => {
    if (user && coach)
      getChatAction({
        recipientID: coach.uid,
        role,
        lastReceived: lastReceived ?? '',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coach, getChatAction, role, user]);

  useEffect(() => {
    if (typeof previousCoaches?.[0] === 'string')
      getPreviousCoachProfileAction();
  }, [getPreviousCoachProfileAction, previousCoaches]);

  return (
    <AppLayout page="chat">
      <ChatPane>
        {!isChatActive && (
          <UpgradeNow
            message="Your subscription doesn't include Unlimited Chat. Please upgrade
          your plan."
          />
        )}
        <Pane
          paddingBottom={10}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start">
          <Header as="h1">Chat</Header>
          {previousCoaches.length > 0 && <ArchiveModal />}
        </Pane>
        <ChatFeed
          showReadReceipt={coach && coach.showReadReceipt}
          disabled={!isChatActive}
          chats={messages}
          recipient={(coach && coach.profile) || {}}
        />
      </ChatPane>
    </AppLayout>
  );
};

export default Chat;
