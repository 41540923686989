import styled from 'styled-components';

import { colors, metrics } from '../../../theme';

export const MenuItemIcon = styled.div`
  display: flex;
  height: 48px;
  width: 48px;
  margin: 0 auto;
  position: relative;
  justify-content: center;
  align-items: center;
  background: ${colors.solitude};
  border-radius: 4px;
  margin-bottom: 8px;
  .icon {
    margin: 0;
  }
  @media (min-width: ${metrics.device_size.mobile_s}) and (max-width: ${metrics
      .device_size.tablet}) {
    height: 40px;
    width: 40px;
  }
`;

export const StyledSidebar = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 13%;
    padding: 1%;
    position: fixed;
    height: 100vh;
    box-shadow: 1px 0 4px 0px ${colors.whisper};
    .ui.menu a.item:hover,
    .ui.menu a.item {
      min-width: 100%;
      background: none;
    }
    .ui.menu .active.item ${MenuItemIcon} {
      background: ${colors.nightRider};
    }
    .ui.menu .item:not(.active) ${MenuItemIcon}:hover {
      background: ${colors.aliceBlue};
    }
    .ui.menu .active.item .icon {
      color: ${colors.bittersweet};
    }
    .ui.labeled.icon.menu > .item > ${MenuItemIcon} .icon.unread {
      color: ${colors.bittersweet};
      position: absolute;
      top: -4px;
      right: -6px;
      margin: 0 !important;
      font-size: 16px !important;
    }
    @media (min-width: ${metrics.device_size
        .mobile_s}) and (max-width: ${metrics.device_size.tablet}) {
      .ui.menu a.item {
        padding: 4px;
      }
      .ui.menu a.item span {
        display: none;
      }
      .ui.labeled.icon.menu > .item > ${MenuItemIcon} .icon.unread {
        font-size: 14px !important;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  background: ${colors.solitude};
  width: 87%;
  margin-left: 13%;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 12px;
  padding-right: 12px;
  @media (min-width: ${metrics.device_size.tablet}) {
    padding-left: 32px;
    padding-right: 28px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid ${colors.lavender};
  padding: 20px 12px;
  .ui.menu {
    margin: 0;
    width: 100%;
  }
  .ui.menu .menu-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex: 1;
    padding-top: 20px;
  }
  .ui.menu .item.dropdown.profile {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 12px;
    background: ${colors.white};
    border: 1px solid ${colors.lavender};
  }
  .ui.menu .item.dropdown.notification {
    padding: 0;
    margin-right: 32px;
    background: transparent;
  }
  @media (min-width: ${metrics.device_size.tablet}) {
    justify-content: space-between;
    flex-direction: row;
    padding: 20px 36px;
    .ui.menu {
      margin: 0;
      width: inherit;
    }
    .ui.menu .menu-container {
      justify-content: flex-end;
      padding-top: 0;
    }
  }
`;

export const HeaderTitle = styled.h2`
  margin: 0;
  font-weight: 500;
  span {
    color: ${colors.oldLavender};
  }
`;

export const DropdownAvatar = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  .image {
    width: 48px;
    height: 48px;
    object-fit: cover;
    margin-right: 12px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
`;

export const UnreadIcon = styled.div`
  position: relative;
  padding: 4px;
  border-radius: 4px;
  border: 2px solid ${colors.gunPowder};
  font-size: 11px;
  min-width: 20px;
  .icon {
    color: ${colors.bittersweet};
    font-size: 10px;
    position: absolute;
    margin: 0;
    top: -10px;
    right: -6px;
  }
  @media (min-width: ${metrics.device_size.tablet}) {
    .icon {
      top: -5px;
    }
  }
`;

export const Logo = styled.div`
  && {
    margin-top: 24px;
    margin-bottom: 24px;
    .image {
      width 72%;
      height: 72%;
      object-fit: contain;
      margin: 0 auto;
    }
    @media (min-width: ${metrics.device_size.mobile_s}) and (max-width: ${metrics.device_size.tablet}) {
      .image {
        width 80%;
        height: 80%;
      }
    }
  }
`;
