import { push } from 'connected-react-router';
import { createAsyncSaga } from 'create-async-saga';
import {
  call,
  put,
  SagaReturnType,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import * as Firebase from '../../services/firebase';
import Swal from '../../utils/Swal';
import { getUser } from '../user/user.saga';
import * as types from './auth.actionTypes';

export const verifyRole = createAsyncSaga<
  SagaReturnType<typeof Firebase.verifyRole>
>(types.VERIFY_ROLE, function* () {
  const response = yield call(Firebase.verifyRole);
  return response;
});

export const resetPassword = createAsyncSaga(
  types.RESET_PASSWORD,
  function* (payload: { email: string }) {
    try {
      const response = yield call(Firebase.forgotPassword, payload);
      yield Swal.fire({
        icon: 'success',
        title: 'Email sent',
      });
      yield put(push('/login'));
      return response;
    } catch (err: any) {
      yield Swal.fire({
        icon: 'error',
        title: err.message,
      });
      throw err;
    }
  }
);

export const logout = createAsyncSaga(types.LOGOUT, function* () {
  yield call(Firebase.logout);
});

export const loginWithEmail = createAsyncSaga<
  {
    token: string;
    role: any;
    uid: any;
  },
  Firebase.LoginData
>(types.LOGIN_WITH_EMAIL, function* (payload) {
  try {
    const user = yield call(Firebase.loginWithEmailAndPassword, payload);
    return user;
  } catch (err: any) {
    yield Swal.fire({
      icon: 'error',
      title: err.message,
    });
    throw err;
  }
});

export const signUp = createAsyncSaga(
  types.SIGNUP_WITH_EMAIL,
  function* (payload: Firebase.SignUpData) {
    try {
      const response = yield call(Firebase.signUpWithEmailAndPassword, payload);
      yield put(push('/onboarding/session-plan'));
      return response;
    } catch (err: any) {
      yield Swal.fire({
        icon: 'error',
        title: err.message,
      });
      throw err;
    }
  }
);

export default function* authSaga() {
  yield takeLatest(verifyRole.actionType, verifyRole.asyncSaga);
  yield takeLatest(verifyRole.fulfilled.type, getUser.asyncSaga);
  yield takeLatest(logout.actionType, logout.asyncSaga);
  yield takeLatest(loginWithEmail.actionType, loginWithEmail.asyncSaga);
  yield takeLatest(resetPassword.actionType, resetPassword.asyncSaga);
  yield throttle(1000, signUp.actionType, signUp.asyncSaga);
}
