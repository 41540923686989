import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import OnboardingAvailability from '../pages/auth/Onboarding/Availability';
import OnboardingChooseCoach from '../pages/auth/Onboarding/ChooseCoach';
import OnboardingChooseDifferentCoach from '../pages/auth/Onboarding/ChooseDifferentCoach';
import OnboardingChooseFromAllCoaches from '../pages/auth/Onboarding/ChooseFromAllCoaches';
import OnboardingInterests from '../pages/auth/Onboarding/Interests';
import OnboardingOptionChooseCoach from '../pages/auth/Onboarding/OptionChooseCoach';
import OnboardingPayment from '../pages/auth/Onboarding/Payment';
import OnboardingSessionPlan from '../pages/auth/Onboarding/SessionPlan';
import OnboardingSessionPlanLevel from '../pages/auth/Onboarding/SessionPlanLevel';
import OnboardingSummary from '../pages/auth/Onboarding/Summary';
import OnboardingSuccess from '../pages/auth/Onboarding/Success';
import OnboardingRoute from './OnboardingRoute';

export default function OnboardingRoutes(): JSX.Element {
  return (
    <Switch>
      <OnboardingRoute
        exact
        path="/onboarding/session-plan"
        component={OnboardingSessionPlan}
      />
      <OnboardingRoute
        exact
        path="/onboarding/session-plan-level"
        component={OnboardingSessionPlanLevel}
      />
      <OnboardingRoute
        exact
        path="/onboarding/option-choose-coach"
        component={OnboardingOptionChooseCoach}
      />
      <OnboardingRoute
        exact
        path="/onboarding/choose=from-all-coaches"
        component={OnboardingChooseFromAllCoaches}
      />
      <OnboardingRoute
        exact
        path="/onboarding/interests"
        component={OnboardingInterests}
      />
      <OnboardingRoute
        exact
        path="/onboarding/availability"
        component={OnboardingAvailability}
      />
      <OnboardingRoute
        exact
        path="/onboarding/choose-coach"
        component={OnboardingChooseCoach}
      />
      <OnboardingRoute
        exact
        path="/onboarding/choose-different-coach"
        component={OnboardingChooseDifferentCoach}
      />
      <OnboardingRoute
        exact
        path="/onboarding/payment"
        component={OnboardingPayment}
      />
      <OnboardingRoute
        exact
        path="/onboarding/summary"
        component={OnboardingSummary}
      />
      <Route exact path="/onboarding/success" component={OnboardingSuccess} />
      <Redirect path="/" to="/onboarding/session-plan" />
    </Switch>
  );
}
