import firebase from '../../config/firebase';

const getConverter = <T>() => ({
  toFirestore: (data: T) => data,
  fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) =>
    snap.data() as T,
});

const usersCollection = firebase
  .firestore()
  .collection('users')
  .withConverter(getConverter<User>());

const coachesCollection = firebase
  .firestore()
  .collection('coaches')
  .withConverter(getConverter<Coach>());

export const getDocRef = (role: Role, uid: string) => {
  console.log('Role', role, 'UID', uid);
  if (role === 'user') {
    return usersCollection.doc(uid);
  }
  if (role === 'coach') {
    return coachesCollection.doc(uid);
  }

  return null;
};

/**
 * User observer
 */
export const userObserver = (
  emitter: (data: Record<string, unknown> | Coach | User | Error) => void,
  role: Role
) => {
  const { currentUser } = firebase.auth();
  const docRef = getDocRef(role, currentUser!.uid);

  if (docRef) {
    return (docRef as firebase.firestore.DocumentReference).onSnapshot(
      (snapshot) => {
        if (!snapshot.exists) return emitter({});

        return emitter({
          id: currentUser?.uid,
          ...currentUser?.toJSON(),
          ...snapshot.data(),
        });
      },
      (err) => {
        console.log(err);
        return emitter(new Error(err.message));
      }
    );
  }

  return null;
};

/**
 * Update user
 */
export const updateUser = (data: Record<string, any>) => {
  const { uid } = firebase.auth().currentUser!;
  const { role, ...rest } = data;

  if (uid && role) {
    const docRef = getDocRef(role, uid);

    if (docRef) {
      return docRef.update({
        ...rest,
        updated_at: firebase.firestore.Timestamp.now(),
      });
    }
  }

  throw new Error('No user found');
};

/**
 * Match coach
 */
export const matchCoach = () => {
  console.log('calling match coach firebase function');

  return firebase
    .functions()
    .httpsCallable('matchCoachNew')()
    .then((coaches) => {
      console.log('match coach response', coaches);
      return coaches;
    })
    .catch((err) => {
      console.log('match coach err', err);
      throw err;
    });
};

/**
 * Update Profile Photo
 */
export const updateProfilePhoto = async (file: File) => {
  const { currentUser } = firebase.auth();

  if (!file) throw new Error('Missing image file');

  const ref = `${currentUser!.uid}/${file.name}`;
  const childRef = firebase.storage().ref(ref);

  await childRef.put(file, {
    contentType: file.type,
  });

  return childRef.getDownloadURL();
};
