import React, { FC, ReactNode, useMemo } from 'react';
import { WizContainer, WizLeftPane, WizLogo, WizRightPane } from './styles';
import useWindowSize from '../../../hooks/useWindowSize';
import ErrorBoundaryContainer from '../../ui/ErrorBoundaryContainer';

const canUseWebP = () => {
  const elem = document.createElement('canvas');
  if (elem.getContext && elem.getContext('2d')) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
};

interface Props {
  children: ReactNode;
  sideBanner?: ReactNode;
  imageBanner?: ReactNode;
}

const WizardLayout: FC<Props> = ({ children, sideBanner, imageBanner }) => {
  const { isMobile } = useWindowSize();

  const rightSidePanel = useMemo(() => {
    if (!sideBanner && !imageBanner) return <></>;
    return (
      <WizRightPane
        bgImage={
          imageBanner ??
          `https://firebasestorage.googleapis.com/v0/b/blush-dev-b8ca2.appspot.com/o/public%2F${sideBanner}${
            canUseWebP() ? '.webp' : '.png'
          }?alt=media`
        }>
        {!isMobile && <WizLogo src="/sml-transparent-logo.png" />}
      </WizRightPane>
    );
  }, [sideBanner, imageBanner, isMobile]);

  const leftSidePanel = useMemo(
    () => (
      <WizLeftPane fullWidth={!sideBanner && !imageBanner}>
        {children}
      </WizLeftPane>
    ),
    [children, sideBanner, imageBanner]
  );

  return (
    <ErrorBoundaryContainer>
      <WizContainer>
        {leftSidePanel}
        {rightSidePanel}
      </WizContainer>
    </ErrorBoundaryContainer>
  );
};

export default WizardLayout;
