export default {
  white: '#ffffff',
  black: '#000000',
  bittersweet: '#fa7268',
  nightRider: '#333232',
  snow: '#fcfcfc',
  silver: '#c4c4c4',
  roseWhite: '#ffece6',
  curiousBlue: '#2185d0',
  whisper: '#eeeeee',
  solitude: '#f4f5f7',
  aliceBlue: '#edeff0',
  lavender: '#ececee',
  oldLavender: '#746374',
  gunPowder: '#585859',
  whiteSmoke: '#f2f2f2',
  lightWhiteSmoke: '#f7f7f7',
  geraldine: '#ea7a6e',
  veryLightGrey: '#cccccc',
  beautyBush: '#f1c1b9',
  charcoal: '#424242',
  stormGrey: '#797C81',
  grey: '#7c7c7c',
  remy: '#f9dcd8',
  sunsetOrange: '#ef5348',
  mistyRose: '#fcebe8',
  vanillaIce: '#ecd3d0',
  purpleBrown: '#2e282a',
};
