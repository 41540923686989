import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, Image, Loader } from 'semantic-ui-react';
import { Pane, WizardLayout } from '../../../../components/layout';
import {
  Button,
  WizBtnGroup,
  WizContent,
  WizGridContainer,
  WizGridItem,
  WizTitle,
} from '../../../../components/layout/WizardLayout/styles';
import { getCoaches } from '../../../../redux/coaches/coaches.saga';
import {
  allCoachesSelector,
  coachSelector,
} from '../../../../redux/coaches/coaches.selectors';
import { updateMatchedCoach } from '../../../../redux/coaches/coaches.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

const ChooseFromAllCoaches: React.FC = () => {
  const history = useHistory();
  const coaches = useAppSelector(allCoachesSelector);
  const coach = useAppSelector(coachSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!coaches || coaches?.length <= 0) {
      // call match coach here
      console.log('getting all coaches...');
      dispatch(getCoaches.action());
    }
  }, [coaches, dispatch]);

  const onHandleCoachSelect = useCallback(
    (event, data) => {
      dispatch(updateMatchedCoach({ ...data }));
    },
    [dispatch]
  );

  const goBack = useCallback(() => {
    dispatch(updateMatchedCoach({ uid: null, profile: null }));
    history.goBack();
  }, [dispatch, history]);

  const coachesGrid = useCallback(
    () => (
      <WizGridContainer>
        {coaches
          ?.filter(({ takingNewClients = false }) => takingNewClients)
          ?.map((i) => {
            const { profile, uid } = i;
            return (
              <WizGridItem
                key={uid}
                onClick={(event) => {
                  onHandleCoachSelect(event, i);
                }}>
                <Image
                  src={profile.photo}
                  className="avatar"
                  circular
                  size="medium"
                />
                {coach.uid === uid && (
                  <Icon name="check circle" size="large" color="green" />
                )}
                <span className="caption">{profile.name}</span>
              </WizGridItem>
            );
          })}
      </WizGridContainer>
    ),
    [coaches, coach, onHandleCoachSelect]
  );

  const showCoaches = useMemo(() => {
    if (!coaches || coaches?.length <= 0)
      return (
        <WizardLayout>
          <WizContent textAlign="center">
            <Pane height="48px" />
            <WizTitle>
              Getting the list of our coaches <br /> Please wait...
            </WizTitle>
            <Pane height="56px" />
            <Loader active size="big" inline="centered" />
          </WizContent>
        </WizardLayout>
      );
    return (
      <WizardLayout sideBanner="onboarding_img">
        <WizContent textAlign="left">
          <WizTitle>
            Select coach you <br />
            want to work with.
          </WizTitle>
          <Pane height="18px" />
          {coachesGrid()}
        </WizContent>
        <WizBtnGroup>
          <Button
            basic
            color="black"
            size="big"
            style={{
              marginRight: 12,
            }}
            // onClick={() => updateStep(WIZARD.AVAILABILITY)}>
            onClick={goBack}>
            Back
          </Button>
          <Button
            icon
            labelPosition="right"
            size="big"
            color="black"
            disabled={!coach.uid}
            // onClick={() => updateStep(WIZARD.PAYMENT)}>
            // onClick={() => history.push('/onboarding/summary')}>
            onClick={() => history.push('/onboarding/payment')}>
            Continue
            <Icon name="chevron right" />
          </Button>
        </WizBtnGroup>
      </WizardLayout>
    );
  }, [history, coach, coaches, coachesGrid, goBack]);

  return <>{showCoaches}</>;
};

export default ChooseFromAllCoaches;
