/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAsyncSaga } from 'create-async-saga';
import firebaseLib from 'firebase';
import { eventChannel } from 'redux-saga';
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  retry,
  take,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as Firebase from '../../services/firebase';
import { Message, Messages, MessageUser } from '../../types/message';
import * as authTypes from '../auth/auth.actionTypes';
import * as types from './chat.actionTypes';
import coachChatSaga from './coachChat.saga';

function* observeChat(data: {
  recipientID: string;
  role: Role;
  lastReceived: string;
}): any {
  const channel = eventChannel((emitter) => {
    const observer = Firebase.chatObserver(emitter, data);
    return () => observer();
  });

  while (true) {
    try {
      const response = yield take(channel);
      yield put({
        type: getChat.fulfilled.type,
        payload: response,
      });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

export const getChat = createAsyncSaga(
  types.GET_CHAT,
  function* (data: { recipientID: string; role: Role; lastReceived: string }) {
    const observer = yield fork(observeChat, data);
    yield take(authTypes.LOGOUT);
    yield cancel(observer);
  }
);

export const addChat = createAsyncSaga<
  firebaseLib.firestore.DocumentReference<Message>,
  { recipientID: string; role: Role; message: Message }
>(types.ADD_CHAT, function* (message) {
  const INTERVAL = 3000;
  const response = yield retry(3, INTERVAL, Firebase.addChat, message);
  return response;
});

export const uploadMedia = createAsyncSaga<
  string,
  {
    text: string;
    recipientID: string;
    role: Role;
    user: MessageUser;
    media: Blob | Uint8Array | ArrayBuffer;
  }
>(types.UPLOAD_MEDIA, function* (payload) {
  const { text, recipientID, role, user } = payload;
  const response = yield call(Firebase.uploadChatMedia, payload);

  yield put(
    addChat.action({
      recipientID,
      role,
      message: {
        user,
        text,
        image: response,
      },
    })
  );
  return response;
});

export const updateChatReadReceipts = createAsyncSaga(
  types.UPDATE_CHAT_READ_RECEIPT,
  function* (payload: { recipientID: string; role: Role }) {
    const response = yield call(Firebase.updateChatReadReceipt, payload);
    return response;
  }
);

export const getUserArchivedChat = createAsyncSaga<
  { messages: Messages; recipientID: string },
  { recipientID: string; role: Role }
>(types.GET_USER_ARCHIVED_CHAT, function* (payload) {
  const response = yield call(Firebase.getUserArchivedChat, payload);
  return {
    messages: response,
    recipientID: payload.recipientID,
  };
});

export default function* chatSaga() {
  yield takeLatest(getChat.actionType, getChat.asyncSaga);
  yield takeLeading(addChat.actionType, addChat.asyncSaga);
  yield takeLatest(uploadMedia.actionType, uploadMedia.asyncSaga);
  yield takeLatest(
    updateChatReadReceipts.actionType,
    updateChatReadReceipts.asyncSaga
  );
  yield takeLatest(
    getUserArchivedChat.actionType,
    getUserArchivedChat.asyncSaga
  );
  yield fork(coachChatSaga);
}
