import styled from 'styled-components';
import { colors } from '../../../theme';

export const List = styled.div`
  && {
    background: ${colors.white};
    margin-top: 24px;
    .header {
      display: flex;
      justify-content: space-between;
      background: ${colors.silver};
      margin: 0;
      padding: 6px 20px;
      font-size: 16px;
      font-weight: 500;
    }
    .header .pagination {
      min-height: 24px;
    }
    .header .pagination .item {
      padding: 4px;
      min-width: 24px;
      justify-content: center;
    }
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.whiteSmoke};
  padding: 12px;
  .image {
    flex: 1 0 16%;
    margin: 0 8px;
  }
  .description {
    margin-left: 12px;
    margin-right: 12px;
    flex: 1 0 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
  .date {
    color: ${colors.oldLavender};
    font-size: 12px;
    flex: 1 0 16%;
  }
`;
