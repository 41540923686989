import styled from 'styled-components';
import { Grid, Image } from 'semantic-ui-react';

import { colors, metrics } from '../../../theme';

export const AuthContainer = styled(Grid)`
  && {
    height: 100vh;
    margin: 0;
  }
`;

export const Title = styled.span`
  position: absolute;
  width: 400px;
  font-size: 93px;
  font-family: LibreBaskerville;
  color: ${colors.white};
  bottom: 40px;
  left: 10%;
  line-height: initial;
  text-transform: capitalize;
`;

export const LeftSide = styled(Grid.Column)`
  &&&& {
    padding: 0;
    margin-right: -16px;

    @media (min-width: ${metrics.device_size
        .mobile_s}) and (max-width: ${metrics.device_size.tablet}) {
      display: none !important;
    }
  }
`;

export const RightSide = styled(Grid.Column)`
  &&&& {
    padding-left: 8%;
    padding-right: 8%;
    background: ${colors.white};
    border-radius: 16px;

    @media (min-width: ${metrics.device_size
        .mobile_s}) and (max-width: ${metrics.device_size.tablet}) {
      width: 80% !important;
      margin: 0 auto;
    }
  }
`;

export const Label = styled.label`
  &&&& {
    color: ${colors.nightRider};
    font-size: 14px;
  }
`;

export const Ahref = styled.a`
  color: ${colors.bittersweet};
  font-size: 14px;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 auto;
`;

export const Picture = styled(Image)`
  width: 128px;
  object-fit: contain;
  margin-bottom: 20px;
`;
