import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '../store';

export const authSelector = (state: ApplicationState) => state.auth;

export const roleSelector = createSelector(
  authSelector,
  (auth) => auth?.role || auth?.tempRole || 'user'
);

export const userIdSelector = createSelector(authSelector, (auth) => auth.uid);
