export const INTERESTS: Interest[] = [
  'Breakup/Divorce',
  'Relationships',
  'Friendship',
  'Productivity',
  'Parenting',
  'LGTB-Q',
  'Health/Fitness',
  'Self-Confidence',
  'Finances',
  'Dating',
  'Career',
  'Quarter Life Crisis',
  'Stress Management',
  'Spirituality',
];

export const AVAILABILITY_PREFERENCE: {
  sessionDurations: SessionLength[];
  daysOfWeek: Days[];
} = {
  sessionDurations: ['Mornings', 'Afternoons', 'Evenings'],
  daysOfWeek: ['Weekdays', 'Weekend'],
};

export const VALIDATION_MESSAGES: Record<string, string> = {
  email: 'Must be an email',
  required: 'This is a required field',
  pattern: 'This is not a valid value',
  max: 'Field value must be more',
  min: 'Field value must be less',
};

export const GENDERS = ['Male', 'Female', 'Non-binary', 'Prefer not to say'];

export const PLACEHOLDER_IMAGE =
  'https://react.semantic-ui.com/images/wireframe/square-image.png';

export const STATES: Record<string, string> = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const PATTERN_REGEX = {
  DAY: /^(?:[12]?[1-9]|3[01])$/,
  MONTH: /^(0?[1-9]|1[012])$/,
  YEAR: /^(19|20)\d{2}$/,
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
};

export const DASHBOARD_CONTENT = {
  QUOTE_OF_THE_DAY: {
    text: '"Don’t surrender all your joy for an idea you used to have about yourself that isn’t true anymore"',
    author: 'Cheryl Strayed',
  },
  TIP_OF_THE_WEEK:
    'Anytime you feel anxious, follow these steps to feel grounded to the present moment: Name five things you can see, four you can touch, three you can hear, two you can smell, and one you can taste.',
  ANNOUNCEMENTS: [
    {
      id: '1',
      src: '/kali.jpg',
      desc: 'Refer a friend to Blush with code “BLUSHFAM” for 25% off their first month, and you get 15% off your next month of membership!',
      date: 'Jun 23',
    },
  ],
};

export interface LevelDetails {
  type: string;
  name: string;
  sku: string;
  uid: string;
  price: number;
  displayPrice: string;
  numberOfSessions: number;
  duration: number;
  description: string;
}

export interface SubscriptionPlan {
  type: string;
  description: string;
  uid?: string;
  price?: number;
  levels: LevelDetails[];
}

export const PLANS: SubscriptionPlan[] = [
  // {
  //   type: 'Video',
  //   description:
  //     'Talk with your coach in real time by scheduling private 30 minute video sessions. ',
  //   levels: [
  //     {
  //       type: '2 video sessions',
  //       name: 'Video 2 30',
  //       sku: 'V_2_30',
  //       uid: 'plan_GJLjkMeYkRw2PG',
  //       price: 99,
  //       displayPrice: '$99/mo',
  //       numberOfSessions: 2,
  //       duration: 30,
  //       description:
  //         'Check in with your coach twice a month to maintain positive results.',
  //     },
  //     {
  //       type: '4 video sessions',
  //       name: 'Video 4 30',
  //       sku: 'V_4_30',
  //       uid: 'plan_GJLjzOkZBciwdB',
  //       price: 179,
  //       displayPrice: '$179/mo',
  //       numberOfSessions: 4,
  //       duration: 30,
  //       description:
  //         'Speak with your coach weekly to stay on track with goals and create positive change.',
  //     },
  //     {
  //       type: '4 video sessions',
  //       name: 'Video 4 60',
  //       sku: 'V_4_60',
  //       uid: 'plan_GJLoozFqiiF4rk',
  //       price: 329,
  //       displayPrice: '$329/mo',
  //       numberOfSessions: 4,
  //       duration: 60,
  //       description:
  //         'Meet with your coach weekly to start your self discovery journey and see real results.',
  //     },
  //   ],
  // },
  {
    type: 'Video + Chat',
    description:
      'Private video sessions and unlimited chatting five days a week.  (Coach responds minimum once daily.)',
    levels: [
      {
        type: '2 video sessions',
        name: 'Video 2 30 + Chat',
        sku: 'VC_2_30',
        uid: 'price_1Mm0tj2tjBa8SBT24OQCGLSR',
        price: 199,
        displayPrice: '$199/mo',
        numberOfSessions: 2,
        duration: 30,
        description:
          'Unlimited chat on weekdays, plus two face-to-face sessions with your coach.',
      },
      {
        type: '4 video sessions',
        name: 'Video 4 30 + Chat',
        sku: 'VC_4_30',
        uid: 'price_1Mm0wi2tjBa8SBT21NHgdtO9',
        price: 279,
        displayPrice: '$279/mo',
        numberOfSessions: 4,
        duration: 30,
        description:
          'Unlimited chat on weekdays, plus four face-to-face sessions with your coach.',
      },
      {
        type: '4 video sessions',
        name: 'Video 4 60 + Chat',
        sku: 'VC_4_60',
        uid: 'price_1Mm0yx2tjBa8SBT22JGlYeee',
        price: 449,
        displayPrice: '$449/mo',
        numberOfSessions: 4,
        duration: 60,
        description:
          'Unlimited chat on weekdays, plus four hour-long sessions with your coach.',
      },
    ],
  },
];

export const WIZARD = {
  SIGNUP: 0,
  SESSION_PLAN: 1,
  SESSION_PLAN_LEVEL: 2,
  INTERESTS: 3,
  AVAILABILITY: 4,
  CHOOSE_COACH: 5,
  CHOOSE_DIFFERENT_COACH: 6,
  PAYMENT: 7,
  SUMMARY: 8,
  SUCCESS: 9,
};

export const NOTIFICATION_TYPES: {
  [K in keyof NotificationSettings]: string;
} = {
  SESSION_SCHEDULED: 'Session has been scheduled.',
  SESSION_STARTED: 'Session has started.',
  SESSION_CANCELLED: 'Session has been cancelled.',
  SESSION_REMINDER: '30-minute session reminder.',
  CHAT_RECEIVED: 'Chat is received.',
};

export const RECENT_BLOGS = [
  {
    id: '1',
    src: 'https://joinblush.com/wp-content/uploads/2019/10/katy-anne-nTP8aZjedqI-unsplash-1-e1571337497121.jpg',
    title: '9 Benefits of Setting Boundaries',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, set do eiusmod tempor indicidunt ut labore',
    author: 'Kali Rogers',
  },
  {
    id: '2',
    src: 'https://joinblush.com/wp-content/uploads/2016/12/kamtemjnxy-atikh-bana-min.jpg',
    title: 'How to feel Confident as a Single Woman in Any Situation',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, set do eiusmod ssionPanetempor indicidunt ut labore',
    author: 'Kali Rogers',
  },
  {
    id: '3',
    src: 'https://joinblush.com/wp-content/uploads/2019/08/becca-tapert-A_Sx8GrRWg-unsplash-e1566340620991.jpg',
    title: '101 Positive Affirmations To Help you Slay The Day',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, set do eiusmod tempor indicidunt ut labore',
    author: 'Kali Rogers',
  },
  {
    id: '4',
    src: 'https://joinblush.com/wp-content/uploads/2016/08/HNCK2447.jpg',
    title: 'Advice on How To Console an Upset Significant Other',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, set do eiusmod tempor indicidunt ut labore',
    author: 'Kali Rogers',
  },
];
