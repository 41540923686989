import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const FooterContainer = styled.div`
  position: fixed !important;
  width: 100%;
  justify-content: center;
  display: flex;
  bottom: 40px;
  transition: 0.5s;
  opacity: 0;
  z-index: 9999;
`;
