import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from 'semantic-ui-react';
import { AppLayout, Pane, SettingsLayout } from '../../../../components/layout';
import {
  ExtendedCard,
  PrimaryButton,
  StyledPreference,
} from '../../../../components/layout/SettingsLayout/styles';
import { SettingMenuItem } from '../../../../components/layout/SettingsLayout/SettingsMenu';
import { INTERESTS } from '../../../../config/constants';
import { USER_TABS } from '../../../../constants/tabs';
import useWindowSize from '../../../../hooks/useWindowSize';
import { userInterestsSelector } from '../../../../redux/interests/interests.selectors';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { updateUser } from '../../../../redux/user/user.saga';
import { userSelector } from '../../../../redux/user/user.selectors';

const Interests: React.FC = () => {
  const history = useHistory();
  const { isMobile } = useWindowSize();
  const user = useAppSelector(userSelector);
  const { status } = user;
  const interestsArray = useAppSelector(userInterestsSelector);
  const dispatch = useAppDispatch();

  const [selectedInterests, setSelectedInterests] = useState(interestsArray);

  const onSelectInterests = useCallback(
    (interest) => {
      const newInterests = selectedInterests.includes(interest)
        ? selectedInterests.filter((i) => i !== interest)
        : selectedInterests.concat(interest);
      setSelectedInterests(newInterests);
    },
    [selectedInterests]
  );

  const handleNavigationChange = (item: SettingMenuItem) => {
    history.push(item.name);
  };

  return (
    <AppLayout page="settings">
      <SettingsLayout
        items={USER_TABS}
        selectedItem={{ name: 'interests' }}
        onSelectItem={handleNavigationChange}>
        <StyledPreference>
          <h3>Select the top three topics you would like to discuss</h3>
          <Card.Group itemsPerRow={isMobile ? 1 : 3}>
            {INTERESTS.map((interest) => (
              <ExtendedCard
                key={interest}
                selected={selectedInterests.includes(interest)}
                onClick={() => onSelectInterests(interest)}>
                {interest}
              </ExtendedCard>
            ))}
          </Card.Group>
          <Pane height={50} />
          <PrimaryButton
            primary
            loading={status === 'loading'}
            disabled={status === 'loading'}
            onClick={() =>
              dispatch(
                updateUser.action({
                  data: {
                    interests: selectedInterests,
                  },
                })
              )
            }>
            Save
          </PrimaryButton>
        </StyledPreference>
      </SettingsLayout>
    </AppLayout>
  );
};

export default Interests;
