import React from 'react';
import AuthLayout from '../../../components/layout/AuthLayout';
import { resetPassword } from '../../../redux/auth/auth.saga';
import { useAppDispatch } from '../../../redux/store';
import ResetPasswordForm from './ResetPasswordForm';
import { Title } from './styles';

const Forgot = () => {
  const dispatch = useAppDispatch();

  return (
    <AuthLayout>
      <Title size="huge">Reset Password</Title>
      <ResetPasswordForm
        onSubmit={(values) => dispatch(resetPassword.action(values))}
      />
    </AuthLayout>
  );
};

export default Forgot;
