import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from 'semantic-ui-react';
import { AppLayout, Pane, SettingsLayout } from '../../../../components/layout';
import { SettingMenuItem } from '../../../../components/layout/SettingsLayout/SettingsMenu';
import {
  ExtendedCard,
  PrimaryButton,
  StyledPreference,
} from '../../../../components/layout/SettingsLayout/styles';
import { AVAILABILITY_PREFERENCE } from '../../../../config/constants';
import { USER_TABS } from '../../../../constants/tabs';
import useWindowSize from '../../../../hooks/useWindowSize';
import { availabilitySelector } from '../../../../redux/availability/availability.selectors';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { updateUser } from '../../../../redux/user/user.saga';
import { userSelector } from '../../../../redux/user/user.selectors';

const Availability: React.FC = () => {
  const history = useHistory();
  const { isMobile } = useWindowSize();
  const dispatch = useAppDispatch();
  const defaultValues = useAppSelector(availabilitySelector);
  const user = useAppSelector(userSelector);
  const [sessionLength, setSessionLength] = useState(
    defaultValues.sessionLength || []
  );
  const [days, setDays] = useState(defaultValues.days || []);

  const onSelectLength = useCallback(
    (currentSessionLength) => {
      const newSessionLength = sessionLength.includes(currentSessionLength)
        ? sessionLength.filter((s) => s !== currentSessionLength)
        : sessionLength.concat(currentSessionLength);
      setSessionLength(newSessionLength);
    },
    [sessionLength]
  );

  const onSelectDayOfWeek = useCallback(
    (day) => {
      const newDays = days.includes(day)
        ? days.filter((s) => s !== day)
        : days.concat(day);
      setDays(newDays);
    },
    [days]
  );

  const handleNavigationChange = (item: SettingMenuItem) => {
    history.push(item.name);
  };

  return (
    <AppLayout page="settings">
      <SettingsLayout
        items={USER_TABS}
        selectedItem={{ name: 'availability' }}
        onSelectItem={handleNavigationChange}>
        <StyledPreference>
          <h3>What times are you usally available?</h3>
          <Card.Group itemsPerRow={isMobile ? 1 : 3}>
            {AVAILABILITY_PREFERENCE.sessionDurations.map((duration) => (
              <ExtendedCard
                key={duration}
                selected={sessionLength.includes(duration)}
                onClick={() => onSelectLength(duration)}>
                {duration}
              </ExtendedCard>
            ))}
          </Card.Group>

          <Pane height={32} />
          <h3>Weekend or weekdays?</h3>
          <Card.Group itemsPerRow={isMobile ? 1 : 3}>
            {AVAILABILITY_PREFERENCE.daysOfWeek.map((dayOfWeek) => (
              <ExtendedCard
                key={dayOfWeek}
                selected={days.includes(dayOfWeek)}
                onClick={() => onSelectDayOfWeek(dayOfWeek)}>
                {dayOfWeek}
              </ExtendedCard>
            ))}
          </Card.Group>
          <Pane height={50} />
          <PrimaryButton
            primary
            loading={user.status === 'loading'}
            disabled={user.status === 'loading'}
            onClick={() =>
              dispatch(
                updateUser.action({
                  data: {
                    'availability.sessionLength': sessionLength,
                    'availability.days': days,
                  },
                })
              )
            }>
            Save
          </PrimaryButton>
        </StyledPreference>
      </SettingsLayout>
    </AppLayout>
  );
};

export default Availability;
