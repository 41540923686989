import React from 'react';
import { Dropdown, Icon, Image, Menu, Popup } from 'semantic-ui-react';

import {
  Container,
  Content,
  DropdownAvatar,
  Header,
  HeaderTitle,
} from './styles';
import { PLACEHOLDER_IMAGE } from '../../../config/constants';
import NotificationsDropdown from './NotificationsDropdown';
import NotificationsDropdownMobile from './NotificationsDropdownMobile';
import Sidebar from './Sidebar';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSelector } from '../../../redux/user/user.selectors';
import { logout } from '../../../redux/auth/auth.saga';
import { DesktopView, MobileView } from '../../ui';
import { Pane } from '..';

const AppLayout: React.FC<{
  page: string;
}> = ({ page, children }) => {
  const user = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  return (
    <Pane
      display="flex"
      height="100vh"
      flexDirection="row"
      flexWrap="wrap"
      width="100%">
      <Sidebar page={page} />
      <Content>
        <Pane display="flex" flexDirection="column" width="100%">
          <Header>
            <HeaderTitle>
              Welcome, <span>{user?.profile?.name || ''}</span>
            </HeaderTitle>
            <Pane
              display="flex"
              flexDirection="row"
              className="ui secondary menu">
              <Pane
                display="flex"
                flexDirection="row"
                className="menu menu-container">
                <DesktopView>
                  <Popup
                    content="Download mobile app"
                    trigger={
                      <Menu.Item
                        onClick={() => {
                          window.open(
                            'https://blush.coach/download_mobile',
                            '_blank'
                          );
                        }}>
                        <Icon name="mobile alternate" size="large" />
                      </Menu.Item>
                    }
                  />
                </DesktopView>
                <DesktopView>
                  <NotificationsDropdown />
                </DesktopView>
                <MobileView>
                  <NotificationsDropdownMobile />
                </MobileView>
                <Dropdown
                  item
                  className="profile"
                  trigger={
                    <DropdownAvatar>
                      <Image
                        src={
                          (user.profile && user.profile.photo) ||
                          PLACEHOLDER_IMAGE
                        }
                      />
                      {user.profile && user.profile.name}
                    </DropdownAvatar>
                  }>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        window.open('http://help.joinblush.com/en', '_blank');
                      }}>
                      Help
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        window.open('/info/terms-of-service', '_blank')
                      }>
                      Terms of service
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        window.open('/info/privacy-policy', '_blank')
                      }>
                      Privacy policy
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(logout.action())}>
                      Sign out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Pane>
            </Pane>
          </Header>
          <Container>{children}</Container>
        </Pane>
      </Content>
    </Pane>
  );
};

export default AppLayout;
