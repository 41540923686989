import styled, { keyframes } from 'styled-components';
import { Card } from 'semantic-ui-react';
import { colors } from '../../../theme';

export const ExtendedCard = styled(Card)`
  &&& {
    border-color: ${colors.whiteSmoke};
    box-shadow: 0 1px 3px 0 ${colors.silver};
    .session {
      display: flex;
      align-items: center;
      padding: 32px 40px;
    }
    .extra.content .button {
      display: block;
      margin: 0 auto;
      padding: 4px 0;
      background: transparent;
    }
  }
`;

const elastic = keyframes`
  0% { top: 0px; }
  5% { top: -4px; }
  10% { top: -8px; }
  15% { top: 8px; }
  25% { top: -4px; }
  35% { top: 4px; }
  50% { top: 0px; }
`;

export const PlayButton = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 68px;
  width: 68px;
  border-radius: 4px;
  background: ${colors.geraldine};
  color: ${colors.white};
  margin-right: 20px;
  cursor: pointer;
  animation-name: ${elastic};
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  .icon {
    margin-right: 0;
    margin-bottom: 4px;
    font-size: 18px;
  }
`;

export const DayView = styled(PlayButton)`
  background: ${colors.whiteSmoke};
  color: ${colors.black};
  animation-name: none;
  &:hover {
    background: ${colors.whisper};
    box-shadow: 0 1px 3px 0 ${colors.silver};
  }
  .title {
    font-size: 20px;
    font-weight: 500;
  }
  .subtext {
    font-size: 14px;
  }
`;

export const SessionInfo = styled.div`
  display: flex;
  flex-direction: column;
  .session-time {
    color: ${colors.oldLavender};
  }
  .session-time span {
    color: ${colors.black};
    margin-left: 12px;
  }
  .username {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
  }
`;
