import styled from 'styled-components';

import { ListItem } from '../Announcements/styles';
import { colors } from '../../../theme';

export const SessionItem = styled(ListItem)`
  padding: 0;
  cursor: pointer;
  &:hover {
    background: ${colors.whisper};
  }
  .session-date {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 4px 12px;
    flex: 1 0 12%;
    margin-left: 8px;
    border-right: 1px solid ${colors.whiteSmoke};
  }
  .session-date .day {
    font-size: 18px;
    font-weight: 500;
  }
  span:nth-child(2) {
    flex: 1 0 36%;
    padding-left: 20px;
    padding-right: 8px;
  }
  span:nth-child(3) {
    flex: 1 0 20%;
  }
  span:nth-child(4) {
    flex: 1 0 16%;
  }
  span:last-child {
    flex: 1 0 8%;
  }
  span:last-child .icon {
    color: ${colors.veryLightGrey};
  }
`;
