import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Header, Loader } from 'semantic-ui-react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { LeftSideHeader, PrimaryButton } from './styles';
import { AppLayout, DashboardLayout } from '../../../components/layout';
import {
  NoSubscription,
  SessionCard,
  SessionDetailsModal,
  UpgradeNow,
} from '../../../components/ui';
import SessionFeedbackModal from '../../../components/ui/SessionFeedbackModal';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { roleSelector } from '../../../redux/auth/auth.selectors';
import { coachSelector } from '../../../redux/coaches/coaches.selectors';
import { subscriptionSelector } from '../../../redux/subscription/subscription.selectors';
import {
  allUpcomingSessionsSelector,
  sessionsSelector,
  videoStatusSelector,
} from '../../../redux/video/video.selectors';
import { cancelSession, getSessions } from '../../../redux/video/video.saga';
import { sessionFeedbackSelector } from '../../../redux/sessionFeedback/sessionFeedback.selectors';
import { setFeedbackSession } from '../../../redux/sessionFeedback/sessionFeedback.slice';

interface Props {
  role: string;
  user: User;
  coach: Coach;
  sessions: Sessions;
  allUpcomingSessions: Sessions;
  subscription: Subscription;
  videoStatus: string;
  getSessions: () => void;
  cancelSession: () => void;
  sessionFeedback: any;
  setFeedbackSession: () => void;
}

const Dashboard: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const role = useAppSelector(roleSelector);
  const coach = useAppSelector(coachSelector);
  const subscription = useAppSelector(subscriptionSelector);
  const sessions = useAppSelector(sessionsSelector);
  const allUpcomingSessions = useAppSelector(allUpcomingSessionsSelector);
  const sessionFeedback = useAppSelector(sessionFeedbackSelector);
  const videoStatus = useAppSelector(videoStatusSelector);

  const cancelSessionAction = useCallback(
    (sessionUid?: string | null) => {
      dispatch(cancelSession.action(sessionUid ?? ''));
    },
    [dispatch]
  );

  const getSessionsAction = useCallback(() => {
    dispatch(getSessions.action());
  }, [dispatch]);

  const setFeedbackSessionAction = useCallback(() => {
    dispatch(setFeedbackSession(null));
  }, [dispatch]);

  const [isFeedbackDialogVisible, setIsFeedbackDialogVisible] = useState(false);

  const {
    // stripeId,
    video: isVideoActive,
    status: subscriptionStatus,
    activeSubscription,
    credits,
  } = subscription;

  const cycleEndDate =
    activeSubscription && activeSubscription.current_period_end;
  const formattedNextSession = moment(cycleEndDate || 0, 'X').format('M/D');

  useEffect(() => {
    if (activeSubscription && videoStatus === 'pending') {
      getSessionsAction();
    }
  }, [activeSubscription, getSessionsAction, role, videoStatus]);

  useEffect(() => {
    if (sessionFeedback?.session) {
      setIsFeedbackDialogVisible(true);
    } else {
      setIsFeedbackDialogVisible(false);
    }
  }, [sessionFeedback]);

  // useEffect(() => {
  //   if (!stripeId && email) {
  //     createStripeCustomer(email);
  //   }
  // }, [createStripeCustomer, stripeId, email]);

  // useEffect(() => {
  //   if (stripeId && subscriptionStatus === 'pending') {
  //     getStripeCustomer(stripeId);
  //   }
  // }, [getStripeCustomer, stripeId, subscriptionStatus]);

  const [sessionUid, setSessionUid] = useState<string | null | undefined>(null);

  const displaySessions = allUpcomingSessions.slice(0, 4);

  const onJoinSession = (session: Session) => {
    window.open(
      `/user/session/${session.uid}`,
      'Session',
      'width=600,height=800'
    );
  };

  const renderSessionCard = useMemo(
    () =>
      displaySessions.map((session) => (
        <SessionCard
          key={session.uid}
          profile={session.profile}
          session={session}
          onOpenModal={() => setSessionUid(session.uid)}
          onJoinSession={() => onJoinSession(session)}
        />
      )),
    [displaySessions]
  );

  const selectedSession = useMemo(
    () => allUpcomingSessions.find((session) => session.uid === sessionUid),
    [allUpcomingSessions, sessionUid]
  );

  return (
    <AppLayout page="dashboard">
      <DashboardLayout
        role={role}
        coach={coach}
        upcomingSessions={allUpcomingSessions}
        sessions={sessions}
        onSetSessionId={(id) => {
          setSessionUid(id);
        }}>
        {['loading', 'pending'].includes(subscriptionStatus) ||
        (activeSubscription && ['loading', 'pending'].includes(videoStatus)) ? (
          <Loader active inline />
        ) : (
          <>
            {!activeSubscription && (
              <NoSubscription message="You currently have no subscription. Please update your billing settings." />
            )}
            {!isVideoActive && activeSubscription && (
              <UpgradeNow
                message="Interested in meeting with your coach face-to-face? Upgrade your
          subscription to include video sessions."
              />
            )}
            {activeSubscription && isVideoActive && (
              <LeftSideHeader>
                <div>
                  <Header as="h1">Upcoming sessions</Header>
                  <span>{`${
                    credits || 0
                  } session(s) remaining. Next cycle begins ${formattedNextSession}.`}</span>
                </div>
                <PrimaryButton
                  disabled={!credits}
                  onClick={() =>
                    history.push({ pathname: '/user/schedule-session' })
                  }>
                  Schedule Session
                </PrimaryButton>
              </LeftSideHeader>
            )}

            <Card.Group itemsPerRow={2} stackable>
              {renderSessionCard}
            </Card.Group>
          </>
        )}

        {/* <LeftSideHeader>
          <Header as="h1">Recent Blogs</Header>
        </LeftSideHeader>
        <Blogs /> */}
      </DashboardLayout>

      {selectedSession && (
        <SessionDetailsModal
          userRole={role}
          session={selectedSession}
          name={selectedSession?.profile?.name}
          photo={selectedSession?.profile?.photo}
          showModal={!!sessionUid}
          confirmModalLoader={videoStatus === 'loading'}
          onCloseModal={() => setSessionUid(null)}
          onJoinSession={() => onJoinSession(selectedSession)}
          onCancelSession={() => cancelSessionAction(sessionUid)}
        />
      )}
      <SessionFeedbackModal
        showModal={isFeedbackDialogVisible}
        onCloseModal={() => {
          setIsFeedbackDialogVisible(false);
          setFeedbackSessionAction();
        }}
      />
    </AppLayout>
  );
};

export default Dashboard;
