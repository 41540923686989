import React, { FC } from 'react';
import { Icon, Image, Menu } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import { Logo, MenuItemIcon, StyledSidebar } from '../styles';
import { useAppSelector } from '../../../../redux/store';
import { roleSelector } from '../../../../redux/auth/auth.selectors';
import { getUnread } from '../../../../redux/chat/chat.selectors';

interface Props {
  page: string;
}

const Sidebar: FC<Props> = ({ page }) => {
  const role = useAppSelector(roleSelector);
  const unread = useAppSelector(getUnread);

  const history = useHistory();

  return (
    <StyledSidebar>
      <Logo>
        <Image src="/sml-logo.png" />
      </Logo>
      <Menu secondary icon="labeled" vertical>
        <Menu.Item
          name="dashboard"
          active={page === 'dashboard'}
          onClick={() => history.push({ pathname: `/${role}/dashboard` })}>
          <MenuItemIcon>
            <Icon name="dashboard" className="left" size="large" />
          </MenuItemIcon>
          <span>Dashboard</span>
        </Menu.Item>
        {role === 'coach' && (
          <Menu.Item
            name="clients"
            active={page === 'clients'}
            onClick={() => history.push({ pathname: '/coach/clients' })}>
            <MenuItemIcon>
              <Icon name="users" className="left" size="large" />
            </MenuItemIcon>
            <span>Clients</span>
          </Menu.Item>
        )}
        <Menu.Item
          name="chat"
          active={page === 'chat'}
          onClick={() => history.push({ pathname: `/${role}/chat` })}>
          <MenuItemIcon>
            <Icon name="comments outline" className="left" size="large" />
            {unread && <Icon className="unread" name="circle" />}
          </MenuItemIcon>
          <span>Chat</span>
        </Menu.Item>
        <Menu.Item
          name="settings"
          active={page === 'settings'}
          onClick={() =>
            history.push({ pathname: `/${role}/settings/profile` })
          }>
          <MenuItemIcon>
            <Icon name="settings" className="left" size="large" />
          </MenuItemIcon>
          <span>Settings</span>
        </Menu.Item>
      </Menu>
    </StyledSidebar>
  );
};

export default Sidebar;
