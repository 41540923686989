/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAsyncSaga } from 'create-async-saga';
import { eventChannel } from 'redux-saga';
import {
  all,
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import firebaseLib from 'firebase';
import * as Firebase from '../../services/firebase';
import * as authTypes from '../auth/auth.actionTypes';
import * as types from './coaches.actionTypes';
import { previousCoachesSelector } from './coaches.selectors';
import Swal from '../../utils/Swal';

function* observeUserCoach(coachId: string): any {
  const channel = eventChannel((emitter) => {
    const observer = Firebase.userCoachObserver(emitter, coachId);
    return () => observer();
  });

  while (true) {
    try {
      const response = yield take(channel);
      yield put({
        type: getUserCoach.fulfilled.type,
        payload: response,
      });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

export const getUserCoach = createAsyncSaga(
  types.GET_USER_COACH,
  function* (coachId: string) {
    const observer = yield fork(observeUserCoach, coachId);
    yield take(authTypes.LOGOUT);
    yield cancel(observer);
  }
);

export const getCoaches = createAsyncSaga<CoachPublic[]>(
  types.GET_COACHES,
  function* () {
    const response = yield call(Firebase.getPublicCoaches);
    return response;
  }
);

export const getPreviousCoachProfile = createAsyncSaga<CoachPublic[]>(
  types.GET_PREVIOUS_COACH_PROFILE,
  function* () {
    const previousCoaches = yield select(previousCoachesSelector);
    const docs: firebaseLib.firestore.QueryDocumentSnapshot<CoachPublic>[] =
      yield all(
        previousCoaches.map((prevCoachUid: string) =>
          call(Firebase.getCoachPublic, prevCoachUid)
        )
      );

    const responses = docs.map((doc) => ({
      ...doc.data(),
      uid: doc.id,
    }));

    return responses;
  }
);

export const matchCoach = createAsyncSaga(types.MATCH_COACH, function* () {
  try {
    const response = yield call(Firebase.matchCoach);
    return response.data;
  } catch (err: any) {
    yield Swal.fire({
      icon: 'error',
      title: err.message,
    });
    throw err;
  }
});

export default function* coachesSaga() {
  yield takeLatest(getUserCoach.actionType, getUserCoach.asyncSaga);
  yield takeLatest(getCoaches.actionType, getCoaches.asyncSaga);
  yield takeLatest(
    getPreviousCoachProfile.actionType,
    getPreviousCoachProfile.asyncSaga
  );
  yield takeLatest(matchCoach.actionType, matchCoach.asyncSaga);
}
