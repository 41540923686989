import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dimmer, Header, Loader, Table } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import { AppLayout } from '../../../components/layout';
import ClientDetails from '../../../components/ui/ClientDetails';
import { Pane } from '../../../components/layout/Pane';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  clientsSelector,
  clientsStatusSelector,
} from '../../../redux/clients/clients.selectors';
import { roleSelector } from '../../../redux/auth/auth.selectors';
import {
  sessionsSelector,
  videoStatusSelector,
} from '../../../redux/video/video.selectors';
import { getClients as getClientsAction } from '../../../redux/clients/clients.saga';
import { getSessions as getSessionsAction } from '../../../redux/video/video.saga';

function getDOB(client: any) {
  return [
    client.profile.monthOfBirth,
    client.profile.dayOfBirth,
    client.profile.yearOfBirth,
  ].join('/');
}

function getPlan(client: any) {
  const packages: string[] = [];
  if (client.subscription && client.subscription.chat) {
    packages.push('Chat');
  }
  if (client.subscription && client.subscription.video) {
    packages.push(`Video (${client.subscription.duration})`);
  }
  return packages.join(' & ');
}

const Clients = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(roleSelector);
  const clients = useAppSelector(clientsSelector);
  const sessions = useAppSelector(sessionsSelector);
  const videoStatus = useAppSelector(videoStatusSelector);
  const clientStatus = useAppSelector(clientsStatusSelector);

  const getClients = useCallback(() => {
    dispatch(getClientsAction.action());
  }, [dispatch]);

  const getSessions = useCallback(() => {
    dispatch(getSessionsAction.action());
  }, [dispatch]);

  const [selected, select] = useState<UserPublic | null | undefined>(null);
  const { id } = useParams<any>();

  useEffect(() => {
    if (clientStatus === 'pending') getClients();
  }, [clientStatus, getClients]);

  useEffect(() => {
    if (videoStatus === 'pending') getSessions();
  }, [role, getSessions, videoStatus]);

  useEffect(() => {
    const selectedClient = id
      ? clients.find((client: any) => client.profile.uid === id)
      : null;
    select(selectedClient);
  }, [clients, id]);

  const displayClients = useMemo(
    () =>
      [...clients]?.sort(
        (a, b) => a?.profile?.name?.localeCompare(b?.profile?.name ?? '') ?? -1
      ),
    [clients]
  );

  return (
    <AppLayout page="clients">
      <Pane
        display="flex"
        flexDirection="row"
        width="100%"
        marginTop={24}
        marginBottom={20}>
        <Pane
          display="flex"
          flexDirection="column"
          width="100%"
          paddingRight={25}>
          <Dimmer.Dimmable blurring dimmed={clientStatus === 'loading'}>
            <Dimmer inverted active={clientStatus === 'loading'}>
              <Loader />
            </Dimmer>
            <Header>Clients</Header>
            <Table singleLine selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Client</Table.HeaderCell>
                  <Table.HeaderCell>Birthday</Table.HeaderCell>
                  <Table.HeaderCell>Plan</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {displayClients.map((client) => (
                  <Table.Row
                    key={client.profile.uid}
                    onClick={() => select(client)}
                    active={
                      (selected &&
                        selected.profile.uid === client.profile.uid) ??
                      false
                    }>
                    <Table.Cell>{client.profile.name}</Table.Cell>
                    {client.profile.monthOfBirth &&
                    client.profile.dayOfBirth &&
                    client.profile.yearOfBirth ? (
                      <Table.Cell>{getDOB(client)}</Table.Cell>
                    ) : (
                      <Table.Cell />
                    )}
                    <Table.Cell>{getPlan(client)}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Dimmer.Dimmable>
        </Pane>
        {selected && (
          <ClientDetails
            client={{
              clientUid: selected?.uid,
              updated_at: selected?.updated_at,
              profile: selected?.profile,
              created_at: selected?.created_at,
              interests: selected?.interests.interests,
            }}
            sessions={sessions}
          />
        )}
      </Pane>
    </AppLayout>
  );
};

export default Clients;
