import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import appReducer from '../app/app.slice';
import authReducer from '../auth/auth.slice';
import availabilityReducer from '../availability/availability.slice';
import clientsReducer from '../clients/clients.slice';
import coachesReducer from '../coaches/coaches.slice';
import interestsReducer from '../interests/interests.slice';
import notificationsReducer from '../notifications/notifications.slice';
import promoReducer from '../promo/promo.slice';
import sessionFeedbackReducer from '../sessionFeedback/sessionFeedback.slice';
import subscriptionReducer from '../subscription/subscription.slice';
import userReducer from '../user/user.slice';
import videoReducer from '../video/video.slice';
import chatReducer from '../chat/chat.slice';

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    auth: authReducer,
    availability: availabilityReducer,
    user: userReducer,
    clients: clientsReducer,
    coaches: coachesReducer,
    interests: interestsReducer,
    notifications: notificationsReducer,
    promo: promoReducer,
    sessionFeedback: sessionFeedbackReducer,
    subscription: subscriptionReducer,
    video: videoReducer,
    chat: chatReducer,
  });

export default createRootReducer;
