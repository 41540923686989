import * as Sentry from '@sentry/browser';
import { createAsyncSaga } from 'create-async-saga';
import TagManager from 'react-gtm-module';
import {
  all,
  call,
  retry,
  SagaReturnType,
  takeLatest,
} from 'redux-saga/effects';
import { gtmConfig, sentryConfig } from '../../config';
import * as Firebase from '../../services/firebase';
import * as authSaga from '../auth/auth.saga';
import * as types from './app.actionTypes';

export const setupSentry = createAsyncSaga(types.SETUP_SENTRY, function* () {
  const requestSentrySetup = () =>
    Sentry.init({
      dsn: sentryConfig.dsn,
    });

  const SECOND = 1000;
  const response = yield retry(3, 3 * SECOND, requestSentrySetup);

  return response;
});

export const setupAnalytics = createAsyncSaga(
  types.SETUP_ANALYTICS,
  function* () {
    const response = yield TagManager.initialize({
      gtmId: gtmConfig.gtmId!,
    });

    return response;
  }
);

export const getContent = createAsyncSaga(
  types.FETCH_DASHBOARD_CONTENT,
  function* () {
    const response = yield call(Firebase.fetchContent);
    return response;
  }
);

export const setTrackingData = createAsyncSaga(
  types.SET_TRACKING_DATA,
  function* ({ role, email, uid }: SagaReturnType<typeof Firebase.verifyRole>) {
    const user = {
      role,
      email,
      id: uid,
    };

    const response: void = yield all([
      TagManager.dataLayer({ dataLayer: user }),
      Sentry.setUser(user),
    ]);

    return response;
  }
);

export default function* appSaga() {
  yield takeLatest(setupSentry.actionType, setupSentry.asyncSaga);
  yield takeLatest(setupAnalytics.actionType, setupAnalytics.asyncSaga);
  yield takeLatest(getContent.actionType, getContent.asyncSaga);
  yield takeLatest(
    authSaga.verifyRole.fulfilled.type,
    setTrackingData.asyncSaga
  );
}
