import firebase from 'firebase';

const converter = {
  toFirestore: (data: CoachPublic) => data,
  fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) =>
    snap.data() as CoachPublic,
};

const coachesPublicCollection = firebase
  .firestore()
  .collection('coachesPublic')
  .withConverter(converter);

export const getCoachPublic = (uid: string) =>
  coachesPublicCollection.doc(uid).get();

/**
 * User coach observer
 */
export const userCoachObserver = (
  emitter: (coachPublic: Record<string, unknown> | CoachPublic | Error) => void,
  coachUid: string
) =>
  coachesPublicCollection.doc(coachUid).onSnapshot(
    (snapshot) => {
      if (!snapshot.exists) return emitter({});

      return emitter({
        ...snapshot.data(),
      });
    },
    (err) => {
      console.log(err);
      return emitter(new Error(err.message));
    }
  );

/**
 * Get all coaches from public profile
 */
export const getPublicCoaches = async () => {
  const coaches = await coachesPublicCollection.get();
  return coaches.docs.map((coach) => coach.data());
};
