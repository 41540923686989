import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Text = styled.span<{ bold: boolean }>`
  font-size: 14px;

  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}
`;
