import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { logout } from '../auth/auth.saga';
import { VideoState } from '../store/types';
import {
  cancelSession,
  createSession,
  getCoachAvailabilityBlocks,
  getSession,
  getSessions,
  updateSessionStatus,
} from './video.saga';

const initialState = {
  status: 'pending',
  bookingStatus: 'pending',
  sessions: [],
} as VideoState;

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
    }));
    builder.addCase(createSession.pending, (state) => {
      state.bookingStatus = 'loading';
    });
    builder.addCase(createSession.fulfilled, (state) => {
      state.bookingStatus = 'success';
    });
    builder.addCase(getSessions.fulfilled, (state, action: any) => {
      state.status = 'success';
      state.sessions = action.payload;
    });
    builder.addCase(getSession.fulfilled, (state, action: any) => {
      state.status = 'success';
      state.activeSession = action.payload;
    });
    builder.addCase(
      getCoachAvailabilityBlocks.fulfilled,
      (state, action: any) => {
        state.status = 'success';
        state.blocks = action.payload.blocks;
      }
    );
    builder.addCase(createSession.rejected, (state) => {
      state.bookingStatus = 'failed';
    });
    builder.addMatcher(
      isAnyOf(
        getSessions.pending,
        getSession.pending,
        updateSessionStatus.pending,
        getCoachAvailabilityBlocks.pending,
        cancelSession.pending
      ),
      (state) => {
        state.status = 'loading';
      }
    );
    builder.addMatcher(
      isAnyOf(updateSessionStatus.fulfilled, cancelSession.fulfilled),
      (state) => {
        state.status = 'success';
      }
    );
    builder.addMatcher(
      isAnyOf(
        getSessions.rejected,
        getSession.rejected,
        updateSessionStatus.rejected,
        getCoachAvailabilityBlocks.rejected,
        cancelSession.rejected
      ),
      (state) => {
        state.status = 'failed';
      }
    );
  },
});

export default videoSlice.reducer;
