import React from 'react';
import { StyledBasicInfo } from '../../../../../components/layout/SettingsLayout/styles';

const BasicInfo: React.FC<{ profile: CoachProfile }> = ({ profile }) => {
  const profileLocation = [profile.homeCity, profile.homeState]
    .filter(Boolean)
    .join(', ');

  return (
    <StyledBasicInfo>
      <p>
        <b>Location</b>
        <span>{profileLocation}</span>
      </p>
      <p>
        <b>College</b>
        <span>{profile.college}</span>
      </p>
      <p>
        <b>Major</b>
        <span>{profile.major}</span>
      </p>
      <p>
        <b>Graduate School</b>
        <span>{profile.graduateSchool}</span>
      </p>
      <p>
        <b>Degree</b>
        <span>{profile.degree}</span>
      </p>
      <p>
        <b>MBTI</b>
        <span>{profile.mbti}</span>
      </p>
    </StyledBasicInfo>
  );
};

export default BasicInfo;
