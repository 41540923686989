import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Dashboard from '../pages/user/Dashboard';
import SettingsAvailability from '../pages/user/Settings/Availability';
import SettingsBilling from '../pages/user/Settings/Billing';
import SettingsInterests from '../pages/user/Settings/Interests';
import SettingsEmail from '../pages/user/Settings/Email';
import SettingsNotifications from '../pages/user/Settings/Notifications';
import SettingsSecurity from '../pages/user/Settings/Security';
import SettingsMyCoach from '../pages/user/Settings/MyCoach';
import SettingsProfile from '../pages/user/Settings/Profile';
import SettingsSubscription from '../pages/user/Settings/Subscription';
import AppRoute from './AppRoute';
import Chat from '../pages/user/Chat';
import Session from '../pages/shared/Session';
import ScheduleSession from '../pages/user/ScheduleSession';

export default function UserRoutes(): JSX.Element {
  return (
    <Switch>
      <AppRoute path="/user/dashboard" component={Dashboard} />
      <AppRoute exact path="/user/chat" component={Chat} />
      <AppRoute path="/user/settings/profile" component={SettingsProfile} />
      <AppRoute
        path="/user/settings/availability"
        component={SettingsAvailability}
      />
      <AppRoute path="/user/settings/billing" component={SettingsBilling} />
      <AppRoute path="/user/settings/interests" component={SettingsInterests} />
      <AppRoute path="/user/settings/my-coach" component={SettingsMyCoach} />
      <AppRoute
        path="/user/settings/notifications"
        component={SettingsNotifications}
      />
      <AppRoute path="/user/settings/email" component={SettingsEmail} />
      <AppRoute path="/user/settings/security" component={SettingsSecurity} />
      <AppRoute
        path="/user/settings/subscription"
        component={SettingsSubscription}
      />
      <AppRoute path="/user/session/:sessionId" component={Session} />
      <AppRoute path="/user/schedule-session" component={ScheduleSession} />
      <Redirect path="/" to="/user/dashboard" />
    </Switch>
  );
}
