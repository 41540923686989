import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import * as yup from 'yup';
import { Button, Link } from '../../../../components/ui';
import { VALIDATION_MESSAGES } from '../../../../config/constants';
import { authSelector } from '../../../../redux/auth/auth.selectors';
import { useAppSelector } from '../../../../redux/store';
import { userSelector } from '../../../../redux/user/user.selectors';

type FormData = {
  email: string;
  password: string;
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email(VALIDATION_MESSAGES.email)
    .required(VALIDATION_MESSAGES.required),
  password: yup.string().required(VALIDATION_MESSAGES.required),
});

const LoginForm: React.FC<{ onSubmit: (data: FormData) => void }> = ({
  onSubmit,
}) => {
  const auth = useAppSelector(authSelector);
  const user = useAppSelector(userSelector);

  const { handleSubmit, control, formState } = useForm<FormData>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  return (
    <Form size="big" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="email"
        render={({ field: { ref, ...field } }) => (
          <Form.Input
            label="Email Address"
            error={errors?.email?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="password"
        render={({ field: { ref, ...field } }) => (
          <Form.Input
            label="Password"
            type="password"
            error={errors?.password?.message}
            {...field}
          />
        )}
      />
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Link to="/forgot">Forgot Password?</Link>
      </Form.Field>
      <Button
        loading={auth.status === 'loading' || user.status === 'loading'}
        primary
        type="submit"
        fluid
        size="huge">
        Sign in
      </Button>
    </Form>
  );
};

export default LoginForm;
