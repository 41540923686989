import styled, { css, keyframes } from 'styled-components';
import { Button, Card, Form, Image } from 'semantic-ui-react';

import { colors } from '../../../theme';

export const StyledSettingsTab = styled.div`
  width: 100%;
  max-width: 1069px;

  .tabs-container {
    overflow-x: auto;
    // scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  .tabs-container::-webkit-scrollbar {
    /* WebKit */
    // width: 0;
    // height: 0;
  }

  .tab-content {
    background: ${colors.white};
    padding: 4% 5%;
  }

  .ui.menu .item {
    font-size: 17px;

    &.active {
      color: ${colors.bittersweet};
      border: unset;
    }
  }

  .ui.form {
    input,
    textarea {
      font-size: 16px;
      padding: 16px 12px;
    }

    .dropdown {
      font-size: 16px;
      padding: 17px 12px;
    }
    .multiple {
      font-size: 1rem;
      padding: 12px 12px;
    }
    input[type='date'] {
      padding: 13.5px 12px;
    }
  }

  .ui.form textarea {
    // resize: none;
  }
`;

export const PrimaryButton = styled(Button)`
  &&&& {
    color: ${colors.white};
    padding: 14px 48px;
    border-radius: 0;
    font-size: 16px;
  }
`;

export const StyledMyCoach = styled.div`
  && {
    .ui.tabular.menu .item {
      width: 50%;
      border-bottom: none;
      padding: 20px 0;
      color: ${colors.veryLightGrey};

      &.active {
        color: ${colors.bittersweet};
        border-bottom: 1px solid ${colors.bittersweet};
      }
    }

    .ui.bottom.attached.segment.tab {
      border: none;
      font-size: 15px;
      padding: 20px 0;
    }
  }
`;

export const CoachAvatar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const CoachTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 28px;
`;

export const HomeStateText = styled.h4`
  margin-top: 0;
`;

export const StyledPreference = styled.div`
  width: 80%;

  h3 {
    font-weight: 500;
    margin-top: 2%;
    margin-bottom: 6%;
  }
`;

export const ExtendedCard = styled(Card)`
  &&&& {
    color: ${colors.black};
    border-radius: 0;
    padding: 12px 16px;
    font-size: 16px;
    text-align: center;

    ${(props) =>
      props.selected &&
      css`
        background: ${colors.bittersweet};
        color: ${colors.white};
        box-shadow: unset;

        &:hover {
          background: ${colors.sunsetOrange};
        }
      `}
  }
`;

export const StyledNotificationForm = styled(Form)`
  &&&& {
    width: 94%;

    h3 {
      margin-top: 2%;
      margin-bottom: 6%;
    }

    .fields > .field {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid ${colors.lavender};
      padding-bottom: 16px;
      padding-top: 16px;
    }

    .fields > .field > label {
      font-weight: 500;
    }

    .fields > .field > .checkbox > input:checked ~ label:before {
      background-color: ${colors.bittersweet} !important;
    }

    ${PrimaryButton} {
      margin-top: 32px;
    }
  }
`;

const zoomIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const StyledSubscription = styled.div`
  && {
    h3 {
      font-weight: 500;
      margin-top: 2%;
      margin-bottom: 4%;
    }

    .ui.card {
      border: 0.5px solid ${colors.vanillaIce};
      box-shadow: none;
      background: ${colors.mistyRose};
      animation: ${zoomIn} 1200ms;

      &:hover {
        border: 0.5px solid ${colors.vanillaIce};
        box-shadow: 0 2px 8px ${colors.silver};
        background: ${colors.mistyRose};
      }
    }

    .ui.card .icon {
      background-color: white;
      border-radius: 50% !important;
      width: auto;
      height: auto;
      position: absolute;
      top: -8px;
      line-height: 1.5rem !important;
      right: -16px;
    }
  }
`;

export const BoldSpan = styled.span`
  display: block;
  color: ${colors.bittersweet};
  font-size: 18px;
  margin-bottom: 12px;
`;

export const StyledBillingInfo = styled.div`
  .ui.header {
    margin-top: 2%;
    margin-bottom: 4%;
  }

  .payment-info {
    display: flex;
  }

  .payment-info .icon.credit.card {
    color: ${colors.bittersweet};
  }

  .payment-info .card-info {
    margin-left: 20px;
  }

  .payment-info .card-info span {
    font-size: 16px;
  }
`;

export const SwitchButton = styled(Button)`
  &&&& {
    width: 100%;
    font-size: 16px;
    background: transparent;
    border-bottom: 1px solid ${colors.lavender};
    border-radius: 0;
    text-align: left;
    padding-left: 0 !important;
    padding-top: 24px;
    padding-bottom: 24px;

    .icon {
      background: transparent;
      color: ${colors.bittersweet};
    }
  }
`;

export const StyledBasicInfo = styled.div`
  font-size: 15px;

  b {
    display: block;
    color: ${colors.bittersweet};
    margin-bottom: 4px;
  }
`;

export const Avatar = styled(Image)`
  &&& {
    height: 150px;
    object-fit: cover;
  }
`;
