import React from 'react';
import { Loader } from 'semantic-ui-react';
import SettingsMenu, { SettingMenuItem } from './SettingsMenu';
import { StyledSettingsTab } from './styles';

const SettingsLayout: React.FC<{
  items: SettingMenuItem[];
  selectedItem: SettingMenuItem;
  onSelectItem: (item: SettingMenuItem) => void;
  loading?: boolean;
}> = ({ items, selectedItem, onSelectItem, loading, children }) => (
  <StyledSettingsTab>
    <div className="tabs-container">
      <SettingsMenu
        items={items}
        selectedItem={selectedItem}
        onSelectItem={onSelectItem}
      />
    </div>
    <div className="tab-content">{children}</div>
    <Loader active={loading} />
  </StyledSettingsTab>
);

export default SettingsLayout;
