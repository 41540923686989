/* eslint-disable @typescript-eslint/no-use-before-define */
import { push } from 'connected-react-router';
import { createAsyncSaga } from 'create-async-saga';
import { eventChannel } from 'redux-saga';
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import * as Firebase from '../../services/firebase';
import Swal from '../../utils/Swal';
import * as authTypes from '../auth/auth.actionTypes';
import { logout } from '../auth/auth.saga';
import { roleSelector } from '../auth/auth.selectors';
import * as types from './user.actionTypes';

function* observeUser(): any {
  const role = yield select(roleSelector);
  const channel = eventChannel((emitter) => {
    const observer = Firebase.userObserver(emitter, role)!;
    return () => observer();
  });

  while (true) {
    try {
      const response = yield take(channel);
      yield put({
        type: getUser.fulfilled.type,
        payload: response,
      });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

export const getUser = createAsyncSaga(types.GET_USER, function* () {
  const observer = yield fork(observeUser);
  yield take(authTypes.LOGOUT);
  yield cancel(observer);
});

export const updateUser = createAsyncSaga(
  types.UPDATE_USER,
  function* (payload: {
    data: Record<string, any>;
    meta?: { onboarding: boolean; successPath?: string };
  }) {
    try {
      const { meta, data } = payload;
      const role = yield select(roleSelector);
      const response = yield call(Firebase.updateUser, { ...data, role });

      if (!meta || (meta && !meta.onboarding)) {
        yield Swal.fire({
          icon: 'success',
          title: 'Changes are updated.',
          timer: 1500,
        });
      }

      if (meta) {
        if (meta.successPath) {
          yield put(push(meta.successPath));
        }
      }

      return response;
    } catch (err: any) {
      yield Swal.fire({
        icon: 'error',
        title: err.message,
      });
      throw err;
    }
  }
);

export const updateEmail = createAsyncSaga(
  types.UPDATE_EMAIL,
  function* (payload: { newEmail: string; password: string }) {
    try {
      const response = yield call(Firebase.updateEmail, payload);
      yield Swal.fire({
        icon: 'success',
        title: 'Email updated',
        text: 'Please re-login using your new email.',
      });
      yield put(logout.action());
      return response;
    } catch (err: any) {
      yield Swal.fire({
        icon: 'error',
        title: err.message,
      });
      throw err;
    }
  }
);

export const updatePassword = createAsyncSaga(
  types.UPDATE_PASSWORD,
  function* (payload: { password: string; newPassword: string }) {
    try {
      const response = yield call(Firebase.updatePassword, payload);
      yield Swal.fire({
        icon: 'success',
        title: 'Changes are updated.',
      });
      return response;
    } catch (err: any) {
      yield Swal.fire({
        icon: 'error',
        title: err.message,
      });
      throw err;
    }
  }
);

export const updateProfilePhoto = createAsyncSaga(
  types.UPLOAD_PROFILE_PHOTO,
  function* (payload: File) {
    try {
      const response = yield call(Firebase.updateProfilePhoto, payload);
      yield put(
        updateUser.action({
          data: {
            'profile.photo': response,
          },
        })
      );
      return response;
    } catch (err: any) {
      yield Swal.fire({
        icon: 'error',
        title: err.message,
      });
      throw err;
    }
  }
);

export default function* userSaga() {
  yield takeLatest(getUser.actionType, getUser.asyncSaga);
  yield takeLatest(updateUser.actionType, updateUser.asyncSaga);
  yield takeLatest(updateEmail.actionType, updateEmail.asyncSaga);
  yield takeLatest(updatePassword.actionType, updatePassword.asyncSaga);
  yield takeLatest(updateProfilePhoto.actionType, updateProfilePhoto.asyncSaga);
}
