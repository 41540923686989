import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { Pane, WizardLayout } from '../../../../components/layout';
import {
  Button,
  WizContent,
  WizSubtitle,
  WizTitle,
} from '../../../../components/layout/WizardLayout/styles';
import { useAppSelector } from '../../../../redux/store';
import { userProfileSelector } from '../../../../redux/user/user.selectors';
import { colors } from '../../../../theme';

const Success: React.FC = () => {
  const history = useHistory();
  const androidUrl =
    'https://play.google.com/store/apps/details?id=com.blush_rn';
  const iosUrl =
    'https://apps.apple.com/us/app/blush-life-coaching/id1488142486';

  const profile = useAppSelector(userProfileSelector);

  return (
    <WizardLayout>
      <WizContent textAlign="center">
        <WizTitle>Success!</WizTitle>
        <WizSubtitle>
          Welcome to Blush, <span>{profile?.name}</span>!
        </WizSubtitle>
        <Pane height="24px" />
        <Pane
          background={colors.white}
          marginLeft="8%"
          marginRight="8%"
          flexGrow={1}>
          <Pane height="32px" />
          <Pane marginLeft="10%" marginRight="10%">
            <WizSubtitle>
              Blush is best used on desktop or using our mobile app (not a
              mobile browser!). If you’d like to download the app, click on the
              appropriate link below.
            </WizSubtitle>
          </Pane>

          {/* <Pane
            width="64%"
            textAlign="left"
            marginLeft="auto"
            marginRight="auto">
            <Embed placeholder="https://react.semantic-ui.com/images/image-16by9.png" />
          </Pane> */}

          <Pane height="60px" />
          <Pane margin="auto" maxWidth="360px" textAlign="left">
            <Pane>
              <Button
                fluid
                icon
                labelPosition="right"
                size="big"
                color="black"
                textAlign="left"
                onClick={() => history.replace('/user/dashboard')}>
                <Pane textAlign="left">Take me to the dashboard!</Pane>
                <Icon name="chevron right" />
              </Button>
              <Pane height="40px" />
              <Button
                fluid
                icon
                labelPosition="right"
                size="big"
                color="black"
                onClick={() => window.open(iosUrl, '_blank')}>
                Download iOS App
                <Icon name="chevron right" />
              </Button>
              <Pane height="40px" />
              <Button
                fluid
                icon
                labelPosition="right"
                size="big"
                color="black"
                onClick={() => window.open(androidUrl, '_blank')}>
                Download Android App
                <Icon name="chevron right" />
              </Button>
            </Pane>
          </Pane>
        </Pane>
      </WizContent>
    </WizardLayout>
  );
};

export default Success;
