import styled from 'styled-components';

import { Button, Comment, Icon } from 'semantic-ui-react';

import { colors } from '../../../theme';

export const ChatPane = styled.div`
  width: 100%;
  margin-top: 24px;

  .ui.grid {
    margin-bottom: 0;
  }

  .tab-container .ui.menu {
    overflow-x: scroll;
    overflow: auto;
    max-height: 70vh;
    margin-bottom: 12px;
  }
`;

export const Feed = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  box-shadow: 0 1px 3px 0 ${colors.lavender};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  margin-bottom: 12px;

  .comments {
    flex: 1 0 auto;
    overflow-y: auto;
    max-height: 70vh;
    max-width: 100%;
    padding: 16px 20px;
    margin: 0;
  }

  .comments .divider {
    text-transform: capitalize;
    font-size: 12px;
    margin-top: 32px;
    margin-bottom: 28px;
  }

  .comments .comment {
    width: 50%;
    overflow-anchor: none;

    &.system {
      text-align: center;
      margin: 0 auto;
    }

    &.host {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .comments > .comment > .content > .text {
    background: ${colors.remy};
    padding: 16px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;

    &.system {
      border-radius: 8px;
      background: ${colors.solitude};
    }
  }

  .comments > .comment.host > .content > .text {
    border: 1px solid ${colors.whisper};
    background: ${colors.white};
  }
`;

export const CommentAvatar = styled(Comment.Avatar)`
  &&&& {
    img {
      height: 35px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`;

export const ChatSender = styled.div`
  .reply.form {
    display: flex;
    background: ${colors.lightWhiteSmoke};
  }

  .reply.form .field {
    flex: 1 0 auto;
    margin: 0;
  }

  .reply.form .field textarea {
    border: none;
    background: transparent;
    padding: 20px 12px;
    resize: none;
  }

  .upload-attachment .button {
    width: 64px;
    height: 100%;
    color: ${colors.grey};
    margin: 0;
    background: transparent;
    font-size: 18px;
  }
`;

export const SendButton = styled(Button)`
  && {
    width: 72px;
    color: ${colors.white};
    background: ${colors.bittersweet};
    border-radius: 0;
    margin: 0;

    .send.icon {
      font-size: 20px;
    }
  }
`;

export const UnreadIcon = styled(Icon)`
  &&&& {
    font-size: 11px;
    color: ${colors.bittersweet};
  }
`;

export const ChatDate = styled.span`
  float: right;
  line-height: 17px;
  font-size: 12px;
`;

export const StyledMessageIndicator = styled.div`
  position: absolute;
  margin: 0;
  bottom: 2px;
  right: 4px;

  .icon {
    font-size: 11px;
    color: ${colors.bittersweet};
    margin: 0;
  }
`;
