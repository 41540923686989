import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export default styled(Modal)`
  && {
    width: 100%;
    max-width: 460px;
    padding: 20px 28px;
    .button {
      border-radius: 0;
      padding: 14px;
    }
    .session-basic {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .session-basic .ui.image {
      width: 92px;
      border-radius: 4px;
      margin-right: 16px;
    }
    .session-info .date {
      display: block;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 12px;
    }
    .session-info .name {
      font-size: 18px;
      font-weight: 600;
    }
  }
`;
